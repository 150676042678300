import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { printChecklist, printTodaySummary } from '../../../../redux/actions'
import '../../../../styles/_index.scss'
import { ChecklistAnomaly, ChecklistSummaryInstance } from '../../../../types'
import { Filters, PageHeader, PrintButton, Table, TableRowButtonGroup } from '@mv-submodules/inplant-components-fe'
import MainPageContent from '@mv-submodules/inplant-components-fe/ui/components/Layout/MainPageContent'
import { FilterComponent } from '@mv-submodules/inplant-components-fe/ui/components/Filters/Filters'
import { fetchTodaySummaryNoRedux } from '@mv-submodules/inplant-checklist-fe-iblu/redux/actions/checklistSummary'
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import { Flex } from '../../../../../mvlabs-components-fe/ui/components'
import { AlignItems } from '../../../../../mvlabs-components-fe/ui/components/Flex/Flex'
import { generateSpace, SpaceType } from '../../../../../mvlabs-components-fe/functions/bootstrapUtility'
import { generateGroupedChecklist } from '../../../../functions/generateGrouppedChecklist'

export interface DispatchProps {
  fetchTodaySummary: () => Promise<any>
  printChecklist: (checklistId: string) => Promise<Blob | null>
}

export interface OwnProps extends RouteComponentProps<any> {
  // t: TranslationFunction;
}

export interface OwnState {
  printing: { [checklistId: string]: boolean }
  openedChecklist: string[]
  titleFilter: string
  printingGeneral: boolean
  checklistsTodaySummary: ChecklistSummaryInstance[]
  isFetching: boolean
  fetchErrors: boolean
}

export type Props = DispatchProps & OwnProps & WithTranslation

const mapStateToProps = (state: any) => ({
  checklistsTodaySummary: state.checklist.summary.checklistsTodaySummary,
  fetchingTodaySummary: state.checklist.summary.fetchingTodaySummary,
  errorFetchTodaySummary: state.checklist.summary.errorFetchTodaySummary,
})

const mapDispatchToProps = (
  dispatch: Function
): {
  printChecklist: (checklistId: string) => Promise<Blob | null>
  fetchTodaySummary: () => Promise<any>
} => ({
  fetchTodaySummary: () => dispatch(fetchTodaySummaryNoRedux()),
  printChecklist: checklistId => dispatch(printChecklist(checklistId)),
})

export class ChecklistTodaySummary extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      printing: {},
      openedChecklist: [],
      titleFilter: '',
      printingGeneral: false,
      checklistsTodaySummary: [],
      isFetching: false,
      fetchErrors: false,
    }
    this.fetchData = this.fetchData.bind(this)
    this.handleChecklistClick = this.handleChecklistClick.bind(this)
    this.handlePrintChecklist = this.handlePrintChecklist.bind(this)
    this.printTodaySummary = this.printTodaySummary.bind(this)
  }

  public componentDidMount(): void {
    this.fetchData()
  }

  public fetchData() {
    if (!this.state.isFetching) {
      this.setState({ isFetching: true, fetchErrors: false })
      this.props
        .fetchTodaySummary()
        .then(checklistsTodaySummary => this.setState({ checklistsTodaySummary }))
        .catch(error => this.setState({ fetchErrors: true }))
        .finally(() => this.setState({ isFetching: false }))
    }
  }

  public handleChecklistClick(checklist: ChecklistSummaryInstance) {
    return this.props.history.push(`/checklist/summary/${checklist.checklistId}`)
  }

  private async handlePrintChecklist(checklist: ChecklistSummaryInstance) {
    this.setState(prevState => {
      const printing = prevState.printing
      printing[checklist.checklistId!] = true
      return {
        ...prevState,
        printing,
      }
    })
    try {
      const blob = await this.props.printChecklist(checklist.checklistId!)
      const objectURL = URL.createObjectURL(blob)
      const link = document.createElement('a')
      const date = checklist.date ? mvDate.getDateFromStringWithFormatting(checklist.date!, 'yyyy-MM-dd HH:mm:ss') : ''
      const title = checklist.title
      const asset = checklist.asset ? `[${checklist.asset!.code}] ${checklist.asset!.description}` : null
      link.href = objectURL
      link.download = `${date} - ${title}${asset ? ` - ${asset}` : ''}.pdf`
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      link.remove()
      this.setState(prevState => {
        const printing = prevState.printing
        printing[checklist.checklistId!] = false
        return {
          ...prevState,
          printing,
        }
      })
    } catch (error) {
      this.setState(prevState => ({ ...prevState, error }))
      this.setState(prevState => {
        const printing = prevState.printing
        printing[checklist.checklistId!] = false
        return {
          ...prevState,
          printing,
        }
      })
    }
  }

  private async printTodaySummary() {
    this.setState({ printingGeneral: true })
    try {
      const blob = await printTodaySummary(this.state.openedChecklist)
      const objectURL = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = objectURL
      link.download = `${this.props.t('checklist.todaySummary.title')} ${mvDate.format(mvDate.now(), 'yyyy-MM-dd')}.pdf`
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (error) {
      this.setState(prevState => ({ ...prevState, error }))
    } finally {
      this.setState({ printingGeneral: false })
    }
  }

  public render() {
    const { isFetching, checklistsTodaySummary } = this.state
    const filters: FilterComponent[] = [
      {
        name: 'title',
        disabled: isFetching || checklistsTodaySummary.length === 0,
        onChange: (name, value) => this.setState({ titleFilter: value ? (value as string) : '' }),
        value: this.state.titleFilter,
        id: 'checklist-title-filter',
        type: 'SearchInput',
        label: this.props.t('checklist.todaySummary.table.title'),
      },
    ]
    const checklistTodaySummary = checklistsTodaySummary.filter(value =>
      value.title
        .concat(value.asset ? value.asset.code + value.asset.description : '')
        .toLowerCase()
        .replace(' ', '')
        .includes(this.state.titleFilter)
    )

    const aggregatedData = generateGroupedChecklist(checklistTodaySummary, this.state.openedChecklist)
    
    return (
      <React.Fragment>
        <PageHeader
          title={this.props.t('checklist.todaySummary.title')}
          rightButtons={
            <PrintButton
              onClick={this.printTodaySummary}
              disabled={isFetching}
              isPrinting={this.state.printingGeneral}
              label={this.props.t('checklist.edit.print')}
            />
          }
        />
        <MainPageContent>
          <Filters fields={filters} />
          <Table
            data={aggregatedData.filter(v => v.openStatus)}
            isFetching={isFetching}
            manualIsFetching={true}
            // onFetchData={this.fetchData}
            minRows={checklistTodaySummary.length === 0 ? 3 : checklistTodaySummary.length}
            //  pageSize={checklistTodaySummary.length}
            showPaginationTop={true}
            showPaginationBottom={true}
            pageSize={100}
            noDataText={this.props.t('checklist.archive.table.noData')}
            pageText={this.props.t('checklist.archive.table.page')}
            ofText={this.props.t('checklist.archive.table.of')}
            rowsText={this.props.t('checklist.archive.table.rows')}
            getTrProps={(state: any, rowInfo: any, column: any) => {
              return {
                style: {
                  background: rowInfo && rowInfo.original && rowInfo.original.color ? rowInfo.original.color : '',
                  border: '2px solid white',
                  
                },
              }
            }}
            columns={[
              {
                Header: this.props.t('checklist.todaySummary.table.title'),
                accessor: 'title',
                sortable: true,
                minWidth: 170,
                Cell: data => {
                  return (
                    <div>
                      <Flex alignItems={AlignItems.center} className={this.state.openedChecklist.includes(data.original.title) && data.original.type === 'data' ? generateSpace(SpaceType.margin, {left: 5}) : ''}>
                        {data.original.type === 'grouped' &&
                            <TableRowButtonGroup
                              buttons={[
                                {
                                  icon: this.state.openedChecklist.includes(data.original.title)
                                    ? 'chevron-up'
                                    : 'chevron-down',
                                  onClick: () => {
                                    if (this.state.openedChecklist.includes(data.original.title)) {
                                      this.setState(prev => ({
                                        openedChecklist: prev.openedChecklist.filter(x => x !== data.original.title),
                                      }))
                                      return
                                    }
                                    this.setState(prev => ({
                                      openedChecklist: [...prev.openedChecklist, data.original.title],
                                    }))
                                  },
                                  variant: 'secondary-alternate',
                                },
                              ]}
                            />
                          }
                        {/* {this.state.openedChecklist.includes(data.original.title) && data.original.type === 'data' && (
                          <IconComponent icon={'long-arrow-alt-right'} semantic={'secondary'} />
                        )} */}
                        <p>
                          {this.state.openedChecklist.includes(data.original.title) && data.original.type === 'data' ? (
                            data.original.title
                          ) : (
                            <>
                              <b>{data.original.title}</b> 
                              {data.original.type === 'grouped' &&
                                this.props.t(`checklist.todaySummary.table.asset`, {
                                  assetNumber: data.original.numberOfAsset
                                })
                              }
                            </>
                          )}
                          {`${
                            data.original.asset
                              ? `[${data.original.asset.code}] ${
                                  data.original.asset.positionCode &&
                                  data.original.asset.positionCode !== data.original.asset.code
                                    ? ` [${data.original.asset.positionCode}]`
                                    : ``
                                } ${data.original.asset.description}`
                              : ''
                          }`}
                        </p>
                      </Flex>
                      {data.original.anomalies && data.original.anomalies.length > 0 ? (
                        <div>
                          <span>Anomalie:</span>
                          {data.original.anomalies.map((value: ChecklistAnomaly, index: number) => (
                            <span key={`anomalie_${index}_${value.description}`}>
                              <br />
                              {value.description} <b>{value.note ? `[${value.note}]` : ''}</b>
                            </span>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  )
                },
              },
              {
                Header: this.props.t('checklist.todaySummary.table.status'),
                width: 130,
                accessor: 'status',
                sortable: true,
                Cell: data => {
                  return (
                    <div className="text-left">
                      {data.original.status && 
                        <p>
                          {this.props.t(`checklist.todaySummary.status.${data.original.status}`)}
                        </p>
                      }
                    </div>
                  )
                },
              },
              {
                Header: this.props.t('checklist.todaySummary.table.user'),
                accessor: 'user',
                sortable: true,
                width: 130,
                Cell: data => (
                  <div className="text-left">
                    <p>{data.original.user ? data.original.user.displayName : ''}</p>
                  </div>
                ),
              },
              {
                Header: this.props.t('checklist.todaySummary.table.date'),
                accessor: 'date',
                sortable: true,
                width: 150,
                Cell: data => (
                  <div className="text-left">
                    <p>
                      {data.original.date
                        ? mvDate.getDateFromStringWithFormatting(data.original.date, 'dd/MM/yyyy HH:mm')
                        : ''}
                    </p>
                  </div>
                ),
              },
              {
                width: 130,
                sortable: false,
                Cell: data =>
                  data && data.original && data.original.checklistId ? (
                    <TableRowButtonGroup
                      buttons={[
                        {
                          icon: 'eye',
                          onClick: () => this.handleChecklistClick(data.original),
                          variant: 'secondary-alternate',
                        },
                        {
                          printButton: true,
                          onClick: () => this.handlePrintChecklist(data.original),
                          isLoading: this.state.printing[data.original.checklistId],
                        },
                      ]}
                    />
                  ) : /*  data.original.type === 'grouped' ? (
                    <TableRowButtonGroup
                      buttons={[
                        {
                          icon: this.state.openedChecklist.includes(data.original.title)
                            ? 'chevron-up'
                            : 'chevron-down',
                          onClick: () => {
                            if (this.state.openedChecklist.includes(data.original.title)) {
                              consoleLog('prev.openedChecklist', )
                              this.setState(prev => ({
                                openedChecklist: prev.openedChecklist.filter(x => x !== data.original.title),
                              }))
                              return
                            }
                            this.setState(prev => ({
                              openedChecklist: [...prev.openedChecklist, data.original.title],
                            }))
                          },
                          variant: 'secondary-alternate',
                        },
                      ]}
                    />
                  ) : */ null,
              },
            ]}
          />
        </MainPageContent>
      </React.Fragment>
    )
  }
}

export default withRouter<any, any>(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChecklistTodaySummary))
)
