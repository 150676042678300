const it = {
  maintenance: {
    plan: {
      tableCols: {
        type: 'Tipo anomalia',
        dueDate: 'Pianificato',
        plannedDate: 'Scadenza',
      },
    },
    actionScheduledJob: {
      type: 'Tipo anomalia',
      dueDate: 'Pianificato',
      plannedDate: 'Scadenza',
    },
    addScheduledJob: {
      dueDate: 'Pianificato',
    },
    scheduledJobDetail: {
      recap: {
        type: 'Tipo anomalia',
        dueDate: 'Pianificato',
        plannedDate: 'Scadenza',
      },
    },
  },
  recipeControl: {
    module: {
      plant: "L1-2-5 Lavorazioni",
      press223: 'Lavorazioni Pressa M223',
      'u2-phase2': 'L3-4 Lavorazioni',
    },
  },
}

export default it
