import * as React from 'react'
import * as ReactDOM from 'react-dom'
import {connect} from 'react-redux'
import {WithTranslation, withTranslation} from 'react-i18next'
import {ChecklistInstance} from '../../../../types'
import {addCheck} from '../../../../redux/actions'
import {Modal} from "@mv-submodules/inplant-components-fe";

export interface CheckPrototype {
  id: string | undefined
  description?: string
  components: any
  fillingTime: string
  visibilityStep: any
}

export interface DispatchProps {
  addCheck: (sectionId: string, checkPrototype: CheckPrototype) => Promise<ChecklistInstance>
}

export interface OwnProps {
  show: boolean
  onClose: (reload?: boolean) => void
  sectionId: string
  checkPrototype: CheckPrototype
}

export interface OwnState {
  loading: boolean
  checkPrototype: CheckPrototype
}

export type Props = DispatchProps & OwnProps & WithTranslation

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    addCheck: (sectionId, checkPrototype) => dispatch(addCheck(sectionId, checkPrototype)),
  }
}

export class NewCheckModal extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
      checkPrototype: this.props.checkPrototype,
    }
    this.handleTitleChange = this.handleTitleChange.bind(this)
    this.handleAddCheck = this.handleAddCheck.bind(this)
  }

  private handleTitleChange(event: any) {
    const value = event.target.value
    this.setState(prevState => {
      const newState = {...prevState}
      newState.checkPrototype.description = value
      return newState
    })
  }

  private async handleAddCheck() {
    this.setState(prevState => ({
      ...prevState,
      loading: true,
    }))
    await this.props.addCheck(this.props.sectionId, this.state.checkPrototype)
    this.setState(prevState => ({
      ...prevState,
      loading: false,
    }))
    this.props.onClose(true)
  }

  public render() {
    return this.props.show
      ? ReactDOM.createPortal(
        <Modal visible={true} onClose={this.props.onClose} title={this.props.t('checklist.checks.addCheckModalTitle')}
               closeLabel={this.props.t('checklist.checks.back')} additionalFooterButtons={[{
          label: this.props.t('checklist.edit.addCheck'),
          icon: "check",
          isLoading: this.state.loading,
          disabled: this.state.loading || !this.state.checkPrototype.description,
          onClick: this.handleAddCheck,
          variant: "primary"
        }]}>
          <p>{this.props.t('checklist.checks.addCheckInstructions')}</p>
          <input
            type="text"
            className="form-control"
            placeholder={this.props.t('checklist.checks.description')}
            onChange={this.handleTitleChange}
            value={this.state.checkPrototype.description}
            disabled={this.state.loading}
          />
        </Modal>
        ,
        document.getElementById('modal-container') as Element
      )
      : null
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(NewCheckModal))
