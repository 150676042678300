// * -------------------------------- NPM --------------------------------------
import React, { CSSProperties } from 'react'

// * -------------------------------- MODULE --------------------------------------
import { BackgroundSemantics } from '../../../types/base'
import { Clickable } from '../../../types/onFunction'
import { IFlexItem, renderFlexItem } from './FlexItem'

export enum Direction {
  row = '', // row
  column = 'mv-flex--column',
}

export enum Fit {
  oneLine = 'mv-flex--nowrap',
  multiLine = 'mv-flex--wrap', // default
}

export enum JustifyContent {
  start = '', // start
  center = 'mv-flex--justify-center',
  end = 'mv-flex--justify-end',
  around = 'mv-flex--justify-around',
  between = 'mv-flex--justify-between',
  evenly = 'mv-flex--justify-evenly',
}

export enum AlignContent {
  start = '', // start
  end = 'mv-flex--align-content-end',
  center = 'mv-flex--align-content-center',
  stretch = 'mv-flex--align-content-stretch',
  between = 'mv-flex--align-content-between',
  around = 'mv-flex--align-content-around',
}

export enum AlignItems {
  start = 'mv-flex--align-items-start',
  end = 'mv-flex--align-items-end',
  center = '', // center
  stretch = 'mv-flex--align-items-stretch',
  baseline = 'mv-flex--align-items-baseline',
}

interface Default {
  className?: string
  style?: CSSProperties
  id?: string
  children?: React.ReactNode
  backgroundColor?: BackgroundSemantics
  borderRadius?: FlexBorderRadius
  borderWidth?: FlexBorderWidth
  inline?: boolean
}

export type FlexSpace = 'none' | 'tiny' | 'sm' | 'md' | 'lg' | 'xlg'
export type FlexBorderRadius = 'none' | 'tiny' | 'sm' | 'md' | 'lg' | 'xlg'
export type FlexBorderWidth = 'none' | 'tiny' | 'sm'

export interface FlexProps extends IFlexItem, Clickable<[React.MouseEvent<HTMLDivElement>]>, Default {
  // default: row
  direction?: Direction

  // default: one-line (nowrap)
  fit?: Fit

  // default: flex-start
  justifyContent?: JustifyContent

  // default: center
  alignItems?: AlignItems

  // default: start
  alignContent?: AlignContent

  spaceSize?: FlexSpace
}

const Flex = React.forwardRef((props: FlexProps , ref: React.Ref<HTMLDivElement>) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const v = 'mv-flex'
  const {
    spaceSize,
    direction,
    fit,
    justifyContent,
    alignItems,
    alignContent,
    borderRadius,
    inline,
    borderWidth,

  } = props
  const spaceSizeString = spaceSize !== undefined ? `${v}--${spaceSize}` : `${v}--sm`
  const borderRadiusString =
    borderRadius !== undefined ? `${v}--rounded-borders-${borderRadius}` : ''
  const borderWidthString = borderWidth !== undefined ? `${v}--border-width-${borderWidth}` : ''

  const newStyle: CSSProperties = (props.onClick && { cursor: 'pointer' }) || {}

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  return (
    <div
      ref={ref}
      id={props.id}
      className={`${v} ${direction} ${
        inline ? `${v}--inline` : ''
      } ${fit} ${justifyContent} ${alignContent} ${alignItems} ${spaceSizeString} ${borderRadiusString} ${borderWidthString} ${props.className ||
      ''} ${props.backgroundColor ? `bg-${props.backgroundColor}` : ''} ${renderFlexItem({
        ...(props as IFlexItem),
      })}`}
      onClick={props.onClick}
      style={{ ...props.style, ...newStyle }}
    >
      {props.children}
    </div>
  )
})

Flex.defaultProps = {
  direction: Direction.row,
  fit: Fit.oneLine,
  justifyContent: JustifyContent.start,
  alignItems: AlignItems.center,
  alignContent: AlignContent.start,
}

export default React.memo(Flex)

// * ------------------------------------------
// * Helpers Component that pre-fill some props
// * ------------------------------------------

// * ------------------ Columns

interface ColumnProps extends IFlexItem, Clickable<[React.MouseEvent<HTMLDivElement>]>, Default {
  // default: one-line (nowrap)
  fit?: Fit

  // default: flex-start
  justifyContent?: JustifyContent

  spaceSize?: 'none' | 'tiny' | 'sm' | 'md' | 'lg' | 'xlg'
}

/**
 * direction={Direction.column}
 *
 * alignItems={AlignItems.stretch}
 *
 * alignContent={AlignContent.stretch}
 */
export const StretchColumn = (props: ColumnProps) => (
  <Flex {...props} direction={Direction.column} alignItems={AlignItems.stretch} alignContent={AlignContent.stretch} />
)

/**
 * direction={Direction.column}
 *
 * alignItems={AlignItems.start}
 *
 * alignContent={AlignContent.start}
 */
export const Column = (props: ColumnProps) => (
  <Flex {...props} direction={Direction.column} alignItems={AlignItems.start} alignContent={AlignContent.start} />
)
