import { RouteComponentProps, match } from 'react-router'
import { LastCompilationData } from './checklists'
// import { WithTranslation } from 'react-i18next'

export interface AssetParams {
  id: string
  description: string
  code: string
  checklistInstanceId?: string
  differentUserOpenChecklist: {
    createdDate: string
    id: string
    user: {
      id: string
      displayName: string
    }
  } | null
  hours: number | null
  lastCompilationData?: LastCompilationData
}

export class Asset {
  public id: string
  public description: string
  public code: string
  public checklistInstanceId?: string
  public differentUserOpenChecklist: {
    createdDate: string
    id: string
    user: {
      id: string
      displayName: string
    }
  } | null
  public hours: number | null
  public lastCompilationData?: LastCompilationData

  constructor(params: AssetParams) {
    this.id = params.id
    this.description = params.description
    this.code = params.code
    this.checklistInstanceId = params.checklistInstanceId
    this.differentUserOpenChecklist = params.differentUserOpenChecklist
    this.hours = params.hours
    this.lastCompilationData = params.lastCompilationData
  }
}

export interface ChecklistAssetStateProps {
  fetching: boolean
  error: Error | null
  assets: Asset[]
}

export interface ChecklistAssetDispatchProps {
  fetchIndex: (checklistModelId: string) => Promise<Asset[]>
  startChecklist: (checklistModelId: string, assetId?: string) => Promise<any>
}

export interface ChecklistAssetOwnProps extends RouteComponentProps<{ checklistModelId: string }> {
  match: match<{ checklistModelId: string }>
}

export type ChecklistAssetProps = ChecklistAssetStateProps & ChecklistAssetDispatchProps & ChecklistAssetOwnProps

export interface ChecklistAssetResetHoursStateProps {
  fetching: boolean
  error: Error | null
  assets: Asset[]
}
