import React, {Component} from 'react'
import {Button, Input, Modal, Select} from '@mv-submodules/inplant-components-fe'
import {withTranslation, WithTranslation} from 'react-i18next'
import {API} from '../../../../redux/actions'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'
import {logoutUser} from '@mv-submodules/inplant-coreadapter-fe/auth'
import {
    ChecklistSetupItem,
    ChecklistSetupItemChecklist,
    ChecklistSetupItemParams,
    ChecklistSetupModelItem,
    ChecklistSetupModelItemParams,
} from '@mv-submodules/inplant-checklist-fe-iblu/types'
import {popupNotification} from '@mv-submodules/inplant-coreadapter-fe/functions/notification'
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";

interface OwnState {
    fields: ChecklistSetupItem | null
    modelList: ChecklistSetupItemChecklist[]
    currentModelListItem: string | undefined
    isSubmitting: boolean
    errorSubmitting: boolean
}

interface OwnProps {
    checklistId: string | null
    onClose: () => void
}

type Props = OwnProps & WithTranslation;

const defaultStatus = {
    fields: null,
    modelList: [],
    currentModelListItem: undefined,
    isSubmitting: false,
    errorSubmitting: false,
}

class SetupModalComponent extends Component<Props, OwnState> {
    constructor(props: Props) {
        super(props)

        this.state = defaultStatus

        this.handleSave = this.handleSave.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.fetchModelList = this.fetchModelList.bind(this)
        this.addModelToLIst = this.addModelToLIst.bind(this)
        this.removeModelFromList = this.removeModelFromList.bind(this)
    }

    public componentDidMount() {
        this.fetchModelList()
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any) {
        const {checklistId} = this.props
        if (checklistId && checklistId !== prevProps.checklistId) {
            API().request(`/asset-configuration/${checklistId}`)
                .then((fields: ChecklistSetupItemParams) => {
                    this.setState({
                        fields: new ChecklistSetupItem(fields),
                    })
                })
                .catch((error: FetchError) => {
                    if (error.name === 'FetchError' && error.statusCode === 401) {
                        logoutUser()
                    }
                    throw error
                })
        }
    }

    public render() {
        const {fields, modelList} = this.state
        const {checklistId, t} = this.props

        const activeModels = fields && fields.checklistModels && fields.checklistModels.map(m => m.id) || []
        const filteredModelList = modelList.filter(m => !activeModels.includes(m.id))

        return (
            <Modal
                visible={checklistId ? true : false}
                onClose={this.handleClose}
                title={t('checklist.setup.modal.title')}
                width={50}
                closeLabel={t('checklist.setup.modal.cancel')}
                additionalFooterButtons={[
                    {
                        label: t('checklist.setup.modal.save'),
                        type: 'submit',
                        variant: 'primary',
                        onClick: this.handleSave,
                        isLoading: this.state.isSubmitting
                    },
                ]}
            >
                <form>
                    <Row formRow={true}>
                        <Column md={4} formGroup={true}>
                            <Input
                                type="text"
                                id="inputCode"
                                value={this.state.fields && ('code' in this.state.fields && this.state.fields.code) ? this.state.fields.code : undefined}
                                readOnly={true}
                                label={t('checklist.setup.table.code')}
                            />
                        </Column>

                        <Column formGroup={true} md={4}>
                            <Input
                                type="text"
                                id="inputPositionCode"
                                value={this.state.fields && ('positionCode' in this.state.fields && this.state.fields.positionCode) ? this.state.fields.positionCode : undefined}
                                readOnly={true}
                                label={t('checklist.setup.table.positionCode')}
                            />
                        </Column>

                        <Column formGroup={true} md={4}>
                            <Input
                                type="text"
                                id="inputModel"
                                value={this.state.fields && ('model' in this.state.fields && this.state.fields.model) ? this.state.fields.model : undefined}
                                readOnly={true}
                                label={t('checklist.setup.table.model')}
                            />
                        </Column>
                    </Row>

                    <Row formRow={true}>
                        <Column md={12}>
                            <Input
                                type="text"
                                id="inputName"
                                value={this.state.fields && ('description' in this.state.fields && this.state.fields.description) ? this.state.fields.description : undefined}
                                readOnly={true}
                                label={t('checklist.setup.table.description')}
                            />
                        </Column>
                    </Row>

                    <Row formRow={true}>
                        <Column md={12}>
                            {
                                this.state.fields &&
                                this.state.fields.checklistModels &&
                                this.state.fields.checklistModels.map((checklistModel) => (
                                    <Row formRow={true} key={checklistModel.id} spacing={{vertical:false,horizontal:false}} verticalAlignment={"center"}>
                                        <Column formGroup={true} md={8}>
                                            <Select
                                                label={t('checklist.setup.table.checklist')}
                                                onChange={() => {
                                                    return
                                                }}
                                                options={{
                                                    defaultOption: checklistModel.title,
                                                    items: [{
                                                        label: checklistModel.title,
                                                        value: checklistModel.id,
                                                    }],
                                                }}
                                                value={checklistModel.id}
                                                readOnly={true}
                                            />
                                        </Column>
                                        <Column formGroup={true} md={2}>
                                            <div className={"checklist-model-actions mt-2"}>
                                                <Button
                                                icon={"minus"}
                                                variant="danger"
                                                size={"sm"}
                                                onClick={() => this.removeModelFromList(checklistModel.id)}
                                            />
                                            </div>

                                        </Column>
                                    </Row>
                                ))
                            }
                        </Column>
                    </Row>

                    <Row formRow={true}>
                        <Column md={8} formGroup={true}>
                            {
                                this.state.modelList &&
                                <Row formRow={true} spacing={{vertical:false,horizontal:false}} verticalAlignment={"center"}>
                                    <Column md={10} formGroup={true}>
                                        <Select
                                            label={t('checklist.setup.table.checklist')}
                                            onChange={currentModelListItem => this.setState({currentModelListItem})}
                                            options={{
                                                defaultOption: 'Select',
                                                disableDefaultOption: true,
                                                items: filteredModelList &&
                                                    filteredModelList.map(m => ({
                                                        label: m.title || '',
                                                        value: m.id,
                                                    })) || [],
                                            }}
                                            value={this.state.currentModelListItem}
                                        />
                                    </Column>
                                    <Column md={2} formGroup={true}>
                                        <div className={" checklist-model-actions mt-2"}>
                                            <Button
                                            icon={"plus"}
                                            variant="primary"
                                            size={"sm"}
                                            onClick={this.addModelToLIst}
                                        />
                                        </div>

                                    </Column>
                                </Row>
                            }
                        </Column>
                    </Row>
                </form>
            </Modal>
        )
    }

    private handleSave() {
        if (this.state.fields) {
            this.setState({isSubmitting: true, errorSubmitting: false})
            const data = {
                ...this.state.fields,
                checklistModels: this.state.fields.checklistModels && this.state.fields.checklistModels.map(m => m.id) || [],
            }

            API().request(
                `/asset-configuration/${this.props.checklistId}`,
                {
                    headers: {
                        'content-type': 'application/json',
                    },
                    method: 'POST',
                    body: JSON.stringify(data),
                },
            )
                .then((response: any) => {
                    this.setState({
                            isSubmitting: false,
                            errorSubmitting: false,
                        }, () => this.props.onClose(),
                    )

                    popupNotification({
                        type: 'success',
                        content: this.props.t('checklist.setup.notifications.saved')
                    })
                })
                .catch((error: FetchError) => {
                    if (error.name === 'FetchError' && error.statusCode === 401) {
                        logoutUser()
                    }
                    this.setState({isSubmitting: false, errorSubmitting: true})

                    popupNotification({
                        type: 'error',
                        content: this.props.t('checklist.setup.notifications.notSaved')
                    })
                })
        }
    }

    private handleInputChange(value: string | number, name: string) {
        const fields = Object.assign({}, this.state.fields)
        fields[name] = value

        this.setState({
            fields,
        })
    }

    private addModelToLIst() {
        const newFields = Object.assign({}, this.state.fields)
        const newItem = this.state.modelList.find(m => this.state.currentModelListItem === m.id)
        if (newItem) {
            if (!newFields.checklistModels) {
                newFields.checklistModels = []
            }

            newFields.checklistModels.push(new ChecklistSetupItemChecklist(newItem))

            this.setState({
                fields: newFields,
            })
        }
    }

    private removeModelFromList(modelId: string) {
        if (this.state.fields && this.state.fields.checklistModels) {
            const newFields = Object.assign({}, this.state.fields)
            newFields.checklistModels = newFields.checklistModels!.filter(m => m.id !== modelId)

            this.setState({
                fields: newFields,
            })
        }
    }

    private fetchModelList() {
        API().request(`/models-list`)
            .then((models: ChecklistSetupModelItemParams[]) => {
                this.setState({
                    modelList: models.map(m => new ChecklistSetupModelItem(m)),
                })
            })
            .catch((error: FetchError) => {
                if (error.name === 'FetchError' && error.statusCode === 401) {
                    logoutUser()
                }
                throw error
            })
    }

    private handleClose() {
        this.setState({
                fields: null,
            }, () => this.props.onClose(),
        )
    }
}

export default withTranslation()(SetupModalComponent)
