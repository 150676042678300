import { API } from './index'
import { AnyAction, Dispatch } from 'redux'
import { ChecklistInstanceParams, ChecklistInstance, Asset, ChecklistModelParams, ChecklistModel } from '../../types'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { fetchPendingValidations } from './checklistValidation'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'
import { stringify } from '../../../inplant-components-fe/mvfunctions/apiFormatter'

export const checklistArchiveIsFetching = (
  pageSize: number,
  sorted: Array<{ id: string; desc: boolean }>,
  filtered: Array<{ id: string; value: string }>,
  page: number,
): AnyAction => {
  return {
    payload: { pageSize, sorted, filtered, page },
    type: 'CHECKLIST_ARCHIVE_IS_FETCHING',
  }
}

export const checklistArchiveFetchSuccess = (response: any): AnyAction => {
  return {
    payload: response,
    type: 'CHECKLIST_ARCHIVE_FETCH_SUCCESS',
  }
}

export const checklistArchiveFetchAssetsSuccess = (response: any): AnyAction => {
  return {
    payload: response,
    type: 'CHECKLIST_ARCHIVE_FETCH_ASSETS_SUCCESS',
  }
}

export const fetchModelsSuccess = (models: ChecklistModel[]): AnyAction => {
  return {
    payload: models,
    type: 'CHECKLIST_ARCHIVE_FETCH_MODELS_SUCCESS',
  }
}

export const checklistArchiveFetchError = (error: Error): AnyAction => {
  return {
    payload: error,
    type: 'CHECKLIST_ARCHIVE_FETCH_ERROR',
  }
}

export const checklistArchiveFetchAssetsError = (error: Error): AnyAction => {
  return {
    payload: error,
    type: 'CHECKLIST_ARCHIVE_FETCH_ASSETS_ERROR',
  }
}

export const fetchModelsError = (error: Error): AnyAction => {
  return {
    payload: error,
    type: 'CHECKLIST_ARCHIVE_FETCH_MODELS_ERROR',
  }
}

export const createdDateChanged = (date: any): AnyAction => {
  return {
    payload: date,
    type: 'CHECKLIST_ARCHIVE_START_DATE_CHANGED',
  }
}

export const concludedDateChanged = (date: any): AnyAction => {
  return {
    payload: date,
    type: 'CHECKLIST_ARCHIVE_CONCLUDED_DATE_CHANGED',
  }
}

// TODO
export const fetchArchiveNoRedux = (
  pageSize: number,
  sorted: Array<{ id: string; desc: boolean }>,
  filtered: Array<{ id: string; value: string }>,
  page: number,
  personal?: boolean,
) => (dispatch: Dispatch) => {
  dispatch(checklistArchiveIsFetching(pageSize, sorted, filtered, page))
  fetchPendingValidations()(dispatch)
  filtered = filtered.filter(e => e.id !== 'assetId')
  filtered = filtered.filter(e => e.id !== 'checklistModelId')
  // manage createdDate change
  const createdDateIndex = filtered.findIndex((e: any) => e.id === 'createdDate')
  if (createdDateIndex > -1) {
    const createdDateValue = filtered[createdDateIndex].value
    dispatch(createdDateChanged(createdDateValue))
    if (createdDateValue === null) {
      filtered = filtered.filter(e => e.id !== 'createdDate')
    }
  }
  // manage concludedDate change
  const concludedDateIndex = filtered.findIndex((e: any) => e.id === 'concludedDate')
  if (concludedDateIndex > -1) {
    const concludedDateValue = filtered[concludedDateIndex].value
    dispatch(concludedDateChanged(concludedDateValue))
    if (concludedDateValue === null) {
      filtered = filtered.filter(e => e.id !== 'concludedDate')
    }
  }
  // manage title --> checklistModelId tranformation
  const modelIndex = filtered.findIndex((e: any) => e.id === 'title' && e.value !== '')
  if (modelIndex > -1) {
    filtered.push({
      id: 'checklistModelId',
      value: filtered[modelIndex].value,
    })
  }
  // manage asset.description --> assetId tranformation
  const assetIndex = filtered.findIndex((e: any) => e.id === 'asset.description' && e.value !== '')
  if (assetIndex > -1) {
    filtered.push({ id: 'assetId', value: filtered[assetIndex].value })
  }

  const query: any = { pageSize, sorted, filtered, page }
  return API().request(`/${personal ? 'personal-' : ''}concluded-checklists?${stringify(query)}`)
    .then((response: any) => ({
      checklists: response.data.map(
        (cl: { title: string; id: string } & Partial<ChecklistInstanceParams>) => new ChecklistInstance(cl),
      ),
      pages: response.pages,
    }))
    .catch((error: FetchError) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    })
}

// TODO
export const fetchAssets = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    const response = await API().request('/assets')
    const list = response.map((asset: Asset) => new Asset(asset))
    dispatch(checklistArchiveFetchAssetsSuccess(list))
  } catch (error: any) {
    dispatch(checklistArchiveFetchAssetsError(new Error(error)))
    throw error
  }

}

// TODO
export const fetchModels = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    const response = await API().request('/checklist-models')
    const list = response.map((cl: ChecklistModelParams) => new ChecklistModel(cl))
    dispatch(fetchModelsSuccess(list))
  } catch (error: any) {
    dispatch(fetchModelsError(new Error(error)))
    throw error
  }
}

export const printArchive = (
  pageSize: number,
  sorted: Array<{ id: string; desc: boolean }>,
  filtered: Array<{ id: string; value: string }>,
  page: number,
): ((dispatch: Dispatch<AnyAction>) => Promise<Blob | null>) => {
  return async dispatch => {
    try {
      // remove previously set custom fields
      filtered = filtered.filter(e => e.id !== 'assetId')
      filtered = filtered.filter(e => e.id !== 'checklistModelId')
      // manage createdDate change
      const createdDateIndex = filtered.findIndex((e: any) => e.id === 'createdDate')
      if (createdDateIndex > -1) {
        const createdDateValue = filtered[createdDateIndex].value
        dispatch(createdDateChanged(createdDateValue))
        if (createdDateValue === null) {
          filtered = filtered.filter(e => e.id !== 'createdDate')
        }
      }
      // manage concludedDate change
      const concludedDateIndex = filtered.findIndex((e: any) => e.id === 'concludedDate')
      if (concludedDateIndex > -1) {
        const concludedDateValue = filtered[concludedDateIndex].value
        dispatch(concludedDateChanged(concludedDateValue))
        if (concludedDateValue === null) {
          filtered = filtered.filter(e => e.id !== 'concludedDate')
        }
      }
      // manage title --> checklistModelId tranformation
      const modelIndex = filtered.findIndex((e: any) => e.id === 'title' && e.value !== '')
      if (modelIndex > -1) {
        filtered.push({
          id: 'checklistModelId',
          value: filtered[modelIndex].value,
        })
      }
      // manage asset.description --> assetId tranformation
      const assetIndex = filtered.findIndex((e: any) => e.id === 'asset.description' && e.value !== '')
      if (assetIndex > -1) {
        filtered.push({ id: 'assetId', value: filtered[assetIndex].value })
      }

      const query: any = { pageSize, sorted, filtered, page }
      const blobData = await API().request(`/concluded-checklists-print?${stringify(query)}`)
      return blobData
    } catch (error: any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      return null
    }
  }
}
