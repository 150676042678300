const it = {
  general: {
    mv4iot: 'MV4IoT',
    userLogout: 'Logout',
  },
  additionalRoutes: {
    emergency: 'Squadra Emergenza',
    maintenance: 'Manutenzioni',
  },
  ieDetected: {
    title: 'Browser non supportato',
    subTitle: 'Il portale al momento non supporta questa versione del browser.',
    description: "Per usufruire di tutte le potenzialità del portale si consiglia di utilizzare l'ultima versione di",
    or: 'o di',
  }
}

export default it
