import { AnyAction } from 'redux'
import * as moment from 'moment'

const indexInitialState = {
  checklistsTodaySummary: [],
  errorFetchTodaySummary: null,
  fetchingTodaySummary: false,
  checklistsDaySummary: [],
  errorFetchDaySummary: null,
  fetchingDaySummary: false,
  dateSummary: moment()
    .subtract(1, 'days')
    .format('YYYY-MM-DD'),
}

export const checklistSummary = (
  state: {
    errorFetchTodaySummary: null
    fetchingDaySummary: boolean
    checklistsDaySummary: any[]
    checklistsTodaySummary: any[]
    errorFetchDaySummary: null
    fetchingTodaySummary: boolean
    dateSummary: string
  } = indexInitialState,
  action: AnyAction
) => {
  switch (action.type) {
    case 'CHECKLIST_TODAY_SUMMARY_FETCH_SUCCESS':
      return Object.assign(
        {},
        {
          ...state,
          checklistsTodaySummary: action.payload,
          fetchingTodaySummary: false,
          errorFetchTodaySummary: null,
        }
      )
    case 'CHECKLIST_TODAY_SUMMARY_IS_FETCHING':
      return Object.assign(
        {},
        {
          ...state,
          fetchingTodaySummary: action.payload,
        }
      )
    case 'CHECKLIST_TODAY_SUMMARY_FETCH_ERROR':
      return Object.assign(
        {},
        {
          ...state,
          fetchingTodaySummary: false,
          errorFetchTodaySummary: action.payload,
          checklistsTodaySummary: [],
        }
      )
    case 'CHECKLIST_DAY_SUMMARY_FETCH_SUCCESS':
      return Object.assign(
        {},
        {
          ...state,
          checklistsDaySummary: action.payload,
          fetchingDaySummary: false,
          errorFetchDaySummary: null,
        }
      )
    case 'CHECKLIST_DAY_SUMMARY_IS_FETCHING':
      return Object.assign(
        {},
        {
          ...state,
          fetchingDaySummary: action.payload,
        }
      )
    case 'CHECKLIST_DAY_SUMMARY_FETCH_ERROR':
      return Object.assign(
        {},
        {
          ...state,
          fetchingDaySummary: false,
          errorFetchDaySummary: action.payload,
          checklistsDaySummary: [],
        }
      )
    case 'CHECKLIST_DAY_SUMMARY_CHANGE_DATE':
      return Object.assign(
        {},
        {
          ...state,
          dateSummary: action.payload,
        }
      )
    default:
      return state
  }
}
