import * as React from 'react'
import * as ReactDOM from 'react-dom'
import {connect} from 'react-redux'
import {WithTranslation, withTranslation} from 'react-i18next'
import {withRouter, RouteComponentProps} from 'react-router'
import {deleteChecklist} from '../../../../redux/actions'
import {Modal} from "@mv-submodules/inplant-components-fe";

export interface DispatchProps {
  deleteChecklist: (checklistId: string) => Promise<void>
}

export interface OwnProps extends RouteComponentProps<any> {
  show: boolean
  onClose: () => void
  checklistId: string
}

export interface OwnState {
  loading: boolean
}

export type Props = DispatchProps & OwnProps & WithTranslation

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    deleteChecklist: checklistId => dispatch(deleteChecklist(checklistId)),
  }
}

export class DeleteChecklistModal extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
    }
    this.handleDeleteChecklist = this.handleDeleteChecklist.bind(this)
  }

  private async handleDeleteChecklist() {
    this.setState(prevState => ({
      ...prevState,
      loading: true,
    }))
    await this.props.deleteChecklist(this.props.checklistId)
    this.setState(prevState => ({
      ...prevState,
      loading: false,
    }))
    this.props.history.goBack()
    this.props.onClose()
  }

  public render() {
    return this.props.show
      ? ReactDOM.createPortal(
        <Modal visible={true} onClose={this.props.onClose} closeLabel={this.props.t('checklist.edit.back')}
               title={this.props.t('checklist.edit.deleteChecklistModalTitle')} additionalFooterButtons={[{
          label: this.props.t('checklist.edit.deleteChecklist'),
          isLoading: this.state.loading,
          disabled: this.state.loading,
          onClick: this.handleDeleteChecklist,
          variant: "danger",
          icon: "exclamation-triangle"
        }]}>
          <p>{this.props.t('checklist.edit.deleteChecklistInstructions')}</p>
        </Modal>
        ,
        document.getElementById('modal-container') as Element
      )
      : null
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(DeleteChecklistModal))
)
