// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import useGenericFetchHook, {
  FetchPromise,
  GenericFetchHookReturnType, IsError,
  IsSuccess,
} from '../../../../../mvlabs-components-fe/functions/hooks/useGenericFetchHook'
import {
  ITranslationService,
  useComponentsTranslation,
} from '../../../../../mvlabs-components-fe/services/translation'
import { AlertErrorData, CenterLoader } from '../../../../../mvlabs-components-fe/ui/components'
import FetchError from '@mv-submodules/inplant-core-fe/functions/fetchWrapper/FetchError'

interface WithFetchHookHoCProps<Return, Parameters extends any[] = []> {
  promise: FetchPromise<Return, Parameters>
  parameters?: Parameters
  renderError?: (e: FetchError) => JSX.Element
  renderLoading?: () => JSX.Element
}

export interface WithFetchHookProps<Return = void, Param extends any[] = []>
  extends WithFetchHookHoCProps<Return, Param>,
    Pick<GenericFetchHookReturnType<Return, Param>, 'retryCall' | 'updateData'>,
    ITranslationService {
  state: IsSuccess<Return>
}

const WithFetchHook = <P extends object, Return, Param extends any[] = []>(
  Component: React.ComponentType<Omit<P, 'state' | 't'> & WithFetchHookProps<Return, Param>>,
) => {
  const WithFetchHookHoC: React.FC<Omit<P, 'state' | 't'> &
    WithFetchHookHoCProps<Return, Param>> = function withConfigurationHoC(props) {
    const { t } = useComponentsTranslation()

    const configHook = useGenericFetchHook(props.promise, true, ...((props.parameters || []) as Param))

    const renderSuccess = (state: IsSuccess<Return>) => {
      return (
        <Component {...props} state={state} t={t} retryCall={configHook.retryCall} updateData={configHook.updateData} />
      )
    }

    const renderError = (state: IsError<any>) => {
      return props.renderError?.(state.error as FetchError) ?? <AlertErrorData />
    }

    const renderLoading = () => {
      return props.renderLoading?.() ?? <CenterLoader />
    }

    const renderPossibleCase = (): JSX.Element => {
      switch (configHook.state.kind) {
        case 'success':
          return renderSuccess(configHook.state)
        case 'fetching':
          return renderLoading()
        case 'error':
          return renderError(configHook.state)
        default:
          return renderLoading()
      }
    }

    return renderPossibleCase()
  }

  return WithFetchHookHoC
}

export default WithFetchHook
