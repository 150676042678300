import { AnyAction, Dispatch } from 'redux'
import { API } from './index'
import { ChecklistSummaryInstance, ChecklistSummaryParams } from '../../types/checklists'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'
import { stringify } from '../../../mvlabs-components-fe/functions/apiFormatter'

export const checklistTodaySummaryIsFetching = (response: boolean): AnyAction => {
  return {
    payload: response,
    type: 'CHECKLIST_TODAY_SUMMARY_IS_FETCHING',
  }
}

export const checklistTodaySummaryFetchError = (error: Error): AnyAction => {
  return {
    payload: error,
    type: 'CHECKLIST_TODAY_SUMMARY_FETCH_ERROR',
  }
}

export const checklistTodaySummaryFetchSuccess = (response: any): AnyAction => {
  return {
    payload: response,
    type: 'CHECKLIST_TODAY_SUMMARY_FETCH_SUCCESS',
  }
}

export const checklistDaySummaryIsFetching = (response: boolean): AnyAction => {
  return {
    payload: response,
    type: 'CHECKLIST_DAY_SUMMARY_IS_FETCHING',
  }
}

export const checklistDaySummaryFetchError = (error: Error): AnyAction => {
  return {
    payload: error,
    type: 'CHECKLIST_DAY_SUMMARY_FETCH_ERROR',
  }
}

export const checklistDaySummaryFetchSuccess = (response: any): AnyAction => {
  return {
    payload: response,
    type: 'CHECKLIST_DAY_SUMMARY_FETCH_SUCCESS',
  }
}

export const checklistDaySummaryChangeDate = (response: string): AnyAction => {
  return {
    payload: response,
    type: 'CHECKLIST_DAY_SUMMARY_CHANGE_DATE',
  }
}

// TODO
export const fetchTodaySummaryNoRedux = () => (dispatch: Dispatch) => {
  return API()
    .request('/today-summary')
    .then((response: any) => response.map((cl: ChecklistSummaryParams) => new ChecklistSummaryInstance(cl)))
    .catch((error: FetchError) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    })
}

// TODO
export const fetchDaySummaryNoRedux = (date: string) => (dispatch: Dispatch) => {
  return API()
    .request('/day-summary/' + date)
    .then((response: any) => {
      dispatch(checklistDaySummaryChangeDate(date))
      return response.map((cl: ChecklistSummaryParams) => new ChecklistSummaryInstance(cl))
    })
    .catch((error: FetchError) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    })
}

export const printTodaySummary = async (openedChecklist: string[]): Promise<Blob> => {
  try {
    const queryParams = `?${stringify({
      openChecklist: openedChecklist
    })}`
    const blob = await API().request(`/reports/today-summary${queryParams}`)
    return blob
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      logoutUser()
    }
    throw error
  }
}

export const printDaySummary = async (date: string, openedChecklist: string[]): Promise<Blob> => {
  try {
    const queryParams = `?${stringify({
      openChecklist: openedChecklist
    })}`
    const blob = await API().request(`/reports/day-summary/${date}${queryParams}`)
    return blob
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      logoutUser()
    }
    throw error
  }
}
