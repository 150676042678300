export type Unit = 'U1' | 'U2'

export interface Value {
  id: Unit
  name: string
}

export interface ConfigurationBE {
  units: {
    enable: boolean
    values: Value[]
  }
}

export interface Configuration {
  units: Value[]
}

export const configurationBeToConfiguration = (c: Partial<ConfigurationBE>): Configuration => {
  return {
    units: c.units && c.units.enable ? c.units.values || [] : [],
  }
}

export interface ConfigurationStore {
  data: Configuration
  fetching: boolean
  error?: Error
}
