import { Input, Modal } from '@mv-submodules/inplant-components-fe'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Asset } from '../../../../types'
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";

export interface OwnProps {
  show: boolean
  resetHours: (assetId: string, hours: number) => Promise<any>
  onClose: (reload?: boolean) => void
  asset: Asset | null
}

export interface OwnState {
  loading: boolean
  hours: string
}

export type Props = OwnProps & WithTranslation

export class ResetHoursBody extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
      hours: '0',
    }
    this.handleHoursChange = this.handleHoursChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  private async handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault()
    if (!this.props.asset) {
      return
    }

    const assetId = this.props.asset.id
    const hours = parseInt(this.state.hours, 10)

    try {
      await this.props.resetHours(assetId, hours)
      this.setState({ hours: '0' })
      this.props.onClose()
    } catch (error) {
      console.error(error) //tslint:disable-line
    }
  }

  private handleHoursChange(value: string) {
    this.setState({
      hours: value,
    })
  }

  public componentWillReceiveProps(props: Props, state: OwnState) {
    const hours = `${(props.asset && props.asset.hours) || 0}`
    if (state.hours !== hours) {
      this.setState({ hours })
    }
  }

  public render() {
    const onModalClose = this.props.onClose
    return (
          <form onSubmit={this.handleSubmit}>
             {this.props.asset && (
               <Modal
                  visible={this.props.show}
                  onClose={onModalClose}
                  title={this.props.t('checklist.resetHours.modal.resetAssetHours')}
                  additionalFooterButtons={[{
                    label: this.props.t('checklist.resetHours.modal.back'),
                    variant: 'secondary',
                    onClick: () => onModalClose()
                  },
                  {
                    label: this.props.t('checklist.resetHours.modal.reset'),
                    variant: 'primary',
                    type: 'submit',
                  }]}
                  hideFooterCloseButton={true}

            >
              <div className="modal-body modal-body-asset-hours">
                    <Row>
                      <Column>
                        <Input
                          label={this.props.t('checklist.resetHours.modal.code')}
                          type="text"
                          readOnly={true}
                          id="asset-code"
                          value={this.props.asset.code}
                          onChange={() => {return}}

                        />
                      </Column>
                    </Row>
                    <Row>
                      <Column>
                        <Input
                          label={this.props.t('checklist.resetHours.modal.asset')}
                          type="text"
                          readOnly={true}
                          id="asset"
                          value={this.props.asset.description}
                          onChange={() => {return}}
                        />
                      </Column>
                    </Row>
                    <Row >
                      <Column sm={6}>
                        <Input
                          label={this.props.t('checklist.resetHours.modal.assetHours')}
                          type="number"
                          readOnly={true}
                          id="hours"
                          value={`${this.props.asset.hours || 0}`}
                          onChange={() => {return}}
                        />
                      </Column>
                      <Column sm={6}>
                      <Input
                        label={this.props.t('checklist.resetHours.modal.changeAssetHours')}
                        type="number"
                        min={0}
                        id="changeHours"
                        value={this.state.hours}
                        onChange={(value) => this.handleHoursChange(value as string)}
                        required={true}
                      />
                        
                      </Column>
                    
                    
                    </Row>
                  </div>

            </Modal> ) }

          </form>
        )
  }
}

export default withTranslation()(ResetHoursBody)
