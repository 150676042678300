import { API, API_IA } from './index'
import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { ChecklistPhotoModel, ChecklistPhoto } from '../../types/checklistPhotos'
import { Results } from '@mv-submodules/inplant-checklist-fe-iblu/ui/components/widgets/Image/Image'

const checklistPhotoModelsFetching = (): AnyAction => ({
  type: 'CHECKLIST_PHOTO_MODELS_FETCHING',
})

const checklistPhotoModelsSuccess = (data: ChecklistPhotoModel[]): AnyAction => ({
  type: 'CHECKLIST_PHOTO_MODELS_SUCCESS',
  data,
})

const checklistPhotoModelsFailure = (error: Error): AnyAction => ({
  type: 'CHECKLIST_PHOTO_MODELS_FAILURE',
  error,
})

// TODO
export const checklistPhotoModels = () => async (dispatch: Dispatch<AnyAction>): Promise<ChecklistPhotoModel[]> => {
  dispatch(checklistPhotoModelsFetching())
  try {
    const data: ChecklistPhotoModel[] = await API().request(`/photo-models`)
    dispatch(checklistPhotoModelsSuccess(data))
    return data
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    } else {
      dispatch(checklistPhotoModelsFailure(error))
    }
    throw error
  }
}

export const checklistPrediction = (imagePath: string) => async (dispatch: Dispatch<AnyAction>): Promise<Results> => {
  try {
    return API()
      .request(imagePath)
      .then((blob: Blob) => {
        const formData = new FormData()
        formData.append('file', blob, imagePath)

        const requestOptions: RequestInit = {
          method: 'POST',
          headers: { Accept: 'multipart/form-data' },
          body: formData,
          redirect: 'follow',
        }

        return API_IA()
          .request('/checklist-ia/predict', requestOptions,false,true)
          .then((responseText: string) => responseText as Results)
      })
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    }
    throw error
  }
}

const checklistPhotosFetching = (): AnyAction => ({
  type: 'CHECKLIST_PHOTO_MODELS_FETCHING',
})

const checklistPhotosSuccess = (data: ChecklistPhotoModel[]): AnyAction => ({
  type: 'CHECKLIST_PHOTO_MODELS_SUCCESS',
  data,
})

const checklistPhotosFailure = (error: Error): AnyAction => ({
  type: 'CHECKLIST_PHOTO_MODELS_FAILURE',
  error,
})

export const checklistPhotos = (modelId: string, date: string) => async (
  dispatch: Dispatch<AnyAction>
): Promise<ChecklistPhoto[]> => {
  dispatch(checklistPhotosFetching())
  try {
    const data: ChecklistPhoto[] = await API().request(`/photo-details/${modelId}/${date}`)
    dispatch(checklistPhotosSuccess(data))
    return data
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    } else {
      dispatch(checklistPhotosFailure(error))
    }
    throw error
  }
}
