import * as React from 'react'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { checklistUpdateComponent } from '../../../../redux/actions'
import NumericInput from 'react-calculator-input'
import { Button } from '@mv-submodules/inplant-components-fe'

export interface ComponentData {
  id: string
  optional: boolean
  type: {
    type: string
    fields: NumCalcComponentField[]
  }
  defaultValue: NumCalcComponentDefaultValue
  lastValue: NumCalcComponentLastValue
}

export interface StateProps {
  componentData: ComponentData
  checklistInstanceId: string
  checkId: string
}

export interface DispatchProps {
  updateComponent: (
    checklistInstanceId: string,
    componentId: string,
    value: Array<{ subComponentName: string; value: number[] }>,
    conclude: boolean
  ) => Promise<any>
}

export interface OwnProps {
  disabled: boolean
  collapseHandler?: Function
}

export interface OwnState {
  fields: {
    producedBales: {
      history: number[]
      last: number
    }
    pressedAgainBales: {
      history: number[]
      last: number
    }
    toReselectBales: {
      history: number[]
      last: number
    }
  }
  total: number
  loading: boolean
}

export interface NumCalcComponentField {
  name: string
  description: string
  operation: string
}

export interface NumCalcComponentDefaultValue {
  total: number
  details: {
    producedBales: number[]
    pressedAgainBales: number[]
    toReselectBales: number[]
  }
}

export interface NumCalcComponentLastValue {
  total: number
  details: {
    producedBales: number[]
    pressedAgainBales: number[]
    toReselectBales: number[]
  }
}

export type Props = StateProps & DispatchProps & OwnProps & WithTranslation

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  updateComponent: (checklistInstanceId, componentId, value, conclude) =>
    dispatch(checklistUpdateComponent(checklistInstanceId, componentId, value, conclude)),
})

export class NumberCalculatorComponent extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    const lastValue = props.componentData.lastValue
    // const defaultValue = props.componentData.defaultValue
    this.state = {
      loading: false,
      total: lastValue.total,
      fields: {
        producedBales: {
          history: lastValue.details.producedBales,
          last: 0,
        },
        pressedAgainBales: {
          history: lastValue.details.pressedAgainBales,
          last: 0,
        },
        toReselectBales: {
          history: lastValue.details.toReselectBales,
          last: 0,
        },
      },
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleLastItemChange = this.handleLastItemChange.bind(this)
    this.handleUpdateComponent = this.handleUpdateComponent.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.manageCollapsable()
  }

  private manageCollapsable() {
    if (
      this.props.componentData.lastValue.total !== 0 &&
      this.props.componentData.optional &&
      this.props.collapseHandler
    ) {
      this.props.collapseHandler()
    }
  }

  private handleChange(event: any,name:string,index:string) {
    event.persist()
    this.setState(prevState => {
      const newState = { ...prevState }

      const value = parseInt(event.target.value, 10)
      newState.fields[name][index] = value
      return newState
    })
  }

  private handleLastItemChange(event: any) {
    // we need to check if event is a SyntheticEvent and needs to be persisted
    // because NumericInput returns simple Events
    if (event.persist) {
      event.persist()
    }
    this.setState(prevState => {
      const newState = { ...prevState }
      const fieldName = event.target.dataset.name
      // const fieldIndex = event.target.dataset.index
      const value = parseInt(event.target.value, 10)
      newState.fields[fieldName].last = value > 0 ? value : 0
      return newState
    })
  }

  private handleDeleteItem(event: React.MouseEvent<HTMLButtonElement,MouseEvent>,fieldName:string,fieldIndex:string) {
    event.persist()
    this.setState(
      prevState => {
        const newState = { ...prevState }
        newState.fields[fieldName].history.splice(fieldIndex, 1)
        return newState
      },
      () => this.handleUpdateComponent(fieldName, false)
    )
  }

  private async handleUpdateComponent(fieldName: string, insertLast: boolean = true) {
    this.setState(prevState => ({
      ...prevState,
      loading: true,
    }))
    try {
      const prevHistory = this.state.fields[fieldName].history
      if (insertLast) {
        prevHistory.push(this.state.fields[fieldName].last)
      }
      await this.props.updateComponent(
        this.props.checklistInstanceId,
        this.props.componentData.id,
        [
          {
            subComponentName: 'producedBales',
            value: fieldName === 'producedBales' ? prevHistory : this.state.fields.producedBales.history,
          },
          {
            subComponentName: 'pressedAgainBales',
            value: fieldName === 'pressedAgainBales' ? prevHistory : this.state.fields.pressedAgainBales.history,
          },
          {
            subComponentName: 'toReselectBales',
            value: fieldName === 'toReselectBales' ? prevHistory : this.state.fields.toReselectBales.history,
          },
        ],
        false
      )
      this.setState(prevState => ({
        ...prevState,
        fields: {
          producedBales: {
            history: fieldName === 'producedBales' ? prevHistory : prevState.fields.producedBales.history,
            last: fieldName === 'producedBales' && insertLast ? 0 : prevState.fields.producedBales.last,
          },
          pressedAgainBales: {
            history: fieldName === 'pressedAgainBales' ? prevHistory : prevState.fields.pressedAgainBales.history,
            last: fieldName === 'pressedAgainBales' && insertLast ? 0 : prevState.fields.pressedAgainBales.last,
          },
          toReselectBales: {
            history: fieldName === 'toReselectBales' ? prevHistory : prevState.fields.toReselectBales.history,
            last: fieldName === 'toReselectBales' && insertLast ? 0 : prevState.fields.toReselectBales.last,
          },
        },
        total:
          this.state.fields.producedBales.history.reduce((acc, val) => acc + val, 0) -
          this.state.fields.pressedAgainBales.history.reduce((acc, val) => acc + val, 0) -
          this.state.fields.toReselectBales.history.reduce((acc, val) => acc + val, 0),
        loading: false,
      }))
    } catch (error) {
      this.setState(prevState => ({
        ...prevState,
        loading: false,
      }))
    }
  }

  public render() {
    return (
      <div
        className="
        number-calculator-component
        d-flex flex-column
        flex-md-row
        align-items-start
        justify-content-between
      "
      >
        <div className="produced-bales">
          <div className="d-flex flex-row justify-content-start flex-wrap">
            {this.state.fields.producedBales.history.map((item, index) => (
              <div key={index} className={`input-group ${this.props.disabled ? 'disabled' : ''} pb-1`} style={{ alignItems: 'center'}}>
                <input
                  type="number"
                  min="0"
                  step="1"
                  id={`${this.props.componentData.id}-produced-bales-${index}`}
                  className="form-control"
                  onChange={(e)=> this.handleChange(e,"producedBales",index.toString())}
                  value={item}
                  disabled={
                    this.state.loading || this.props.disabled || index < this.state.fields.producedBales.history.length
                  }
                />
                {!this.props.disabled && index === this.state.fields.producedBales.history.length - 1 ? (
                  <div className="input-group-append">
                    <Button
                      type="button"
                      variant={'secondary-alternate'}
                      textVariant={'danger'}
                      onClick={(e) => this.handleDeleteItem(e,"producedBales",index.toString())}
                      disabled={this.state.loading}
                      iconSize={'sm'}
                      spacing={{ vertical: false,horizontal:false }}
                      icon={"times"}
                    />
                  </div>
                ) : null}
              </div>
            ))}
            <div className={`input-group ${this.props.disabled ? 'disabled' : ''} pb-1`} style={{ alignItems: 'center' }}>
              <NumericInput
                type="number"
                min="0"
                step="1"
                id={`${this.props.componentData.id}-produced-bales-last`}
                className="form-control"
                data-name="producedBales"
                format="integer"
                onChange={this.handleLastItemChange}
                value={this.state.fields.producedBales.last}
                defaultValue={this.state.fields.producedBales.last}
                disabled={this.state.loading || this.props.disabled}
              />
              {!this.props.disabled ? (
                <div className="input-group-append">
                  <Button
                    type="button"
                    variant={'secondary-alternate'}
                    textVariant={'success'}
                    onClick={() => this.handleUpdateComponent('producedBales')}
                    disabled={this.state.loading}
                    icon={"check"}
                    iconSize={'sm'}
                    spacing={{ vertical: false,horizontal:false }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="d-flex flex-row">
          <div className="pressed-again-bales mr-1">
            <div className="form-group">
              <input
                type="number"
                min="0"
                step="1"
                id={`${this.props.componentData.id}-pressed-again-bales`}
                className="form-control"
                data-name="pressedAgainBales"
                onChange={this.handleLastItemChange}
                onBlur={() => this.handleUpdateComponent('pressedAgainBales')}
                value={this.state.fields.pressedAgainBales.last}
                disabled={this.state.loading || this.props.disabled}
              />
              <small className="form-text text-muted">
                {this.props.t('checklist.components.numberCalculator.pressedAgainBales')}
                &nbsp;
                {this.state.fields.pressedAgainBales.history.reduce((acc, val) => acc + val, 0)}
              </small>
            </div>
          </div>
          <div className="to-reselect-bales mr-1">
            <div className="form-group">
              <input
                type="number"
                min="0"
                step="1"
                id={`${this.props.componentData.id}-to-reselect-bales`}
                className="form-control"
                data-name="toReselectBales"
                onChange={this.handleLastItemChange}
                onBlur={() => this.handleUpdateComponent('toReselectBales')}
                value={this.state.fields.toReselectBales.last}
                disabled={this.state.loading || this.props.disabled}
              />
              <small className="form-text text-muted">
                {this.props.t('checklist.components.numberCalculator.toReselectBales')}
                &nbsp;
                {this.state.fields.toReselectBales.history.reduce((acc, val) => acc + val, 0)}
              </small>
            </div>
          </div>
          <div className="total">
            <div className="form-group">
              <input
                type="text"
                min="0"
                step="1"
                id={`${this.props.componentData.id}-total`}
                className="form-control"
                data-name="total"
                placeholder={this.props.t('checklist.components.numberCalculator.total')}
                value={
                  this.state.fields.producedBales.history.reduce((acc, val) => acc + val, 0) -
                  this.state.fields.pressedAgainBales.history.reduce((acc, val) => acc + val, 0) -
                  this.state.fields.toReselectBales.history.reduce((acc, val) => acc + val, 0)
                }
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(withTranslation()(NumberCalculatorComponent))
