import * as React from 'react'
import { ChecklistInstance } from '../../../../types'

export interface Props {
  list: ChecklistInstance[]
  clickHandler: (item: ChecklistInstance) => void
}

function SmallScreenView(props: Props) {
  return (
    <ul className="list-group list-group-flush">
      {props.list.map(item => {
        return (
          <li
            key={item.asset ? `${item.title}-${item.asset.code}` : `${item.title}`}
            className="list-group-item list-group-item-action flex-column align-items-start"
            onClick={() => props.clickHandler(item)}
          >
            <h5 className="mb-0">{item.title}</h5>
            {item.asset ? (
              <p className="text-muted mt-1 mb-0">
                {item.asset.description}
                <br />[{item.asset.code}]
              </p>
            ) : null}
          </li>
        )
      })}
    </ul>
  )
}

export default SmallScreenView
