import * as React from 'react'
import * as ReactDOM from 'react-dom'
import {connect} from 'react-redux'
import {WithTranslation, withTranslation} from 'react-i18next'
import {deleteCheck} from '../../../../redux/actions'
import {Modal} from "@mv-submodules/inplant-components-fe";

export interface CheckPrototype {
  id: string | undefined
  description?: string
  components: any
  fillingTime: string
  visibilityStep: any
}

export interface DispatchProps {
  deleteCheck: (checkId: string) => Promise<void>
}

export interface OwnProps {
  show: boolean
  onClose: (reload?: boolean) => void
  checkId: string
}

export interface OwnState {
  loading: boolean
}

export type Props = DispatchProps & OwnProps & WithTranslation

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    deleteCheck: checkId => dispatch(deleteCheck(checkId)),
  }
}

export class DeleteCheckModal extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
    }
    this.handleDeleteCheck = this.handleDeleteCheck.bind(this)
  }

  private async handleDeleteCheck() {
    this.setState(prevState => ({
      ...prevState,
      loading: true,
    }))
    await this.props.deleteCheck(this.props.checkId)
    this.setState(prevState => ({
      ...prevState,
      loading: false,
    }))
    this.props.onClose(true)
  }

  public render() {
    return this.props.show
      ? ReactDOM.createPortal(
        <Modal visible={true} onClose={this.props.onClose}
               title={this.props.t('checklist.checks.deleteCheckModalTitle')}
               closeLabel={this.props.t('checklist.checks.back')} additionalFooterButtons={[{
          label: this.props.t('checklist.checks.deleteCheck'),
          icon: "exclamation-triangle",
          onClick: this.handleDeleteCheck,
          disabled: this.state.loading,
          isLoading: this.state.loading,
          variant: "danger"
        }]}>
          <p>{this.props.t('checklist.checks.deleteCheckInstructions')}</p>
        </Modal>,
        document.getElementById('modal-container') as Element
      )
      :
      null
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DeleteCheckModal))
