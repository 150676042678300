import { groupBy } from "../../inplant-components-fe/mvfunctions/helpers/objectHelper";
import { ChecklistSummaryInstance } from "../types";
import { DataChecklistSummaryInstance, GroupedSummaryChecklist, SummaryChecklistAggregation } from "../types/summaryGrouped";

export function generateGroupedChecklist(list: ChecklistSummaryInstance[], openedChecklist: string[]): SummaryChecklistAggregation[] {
  const aggregationResult = Object.entries(groupBy(list, 'title')).map(([key, value]) => {
    if (value.length < 2) {
      const singleSummaryChecklist: DataChecklistSummaryInstance = {
        type: 'data',
        openStatus: true,
        ...value[0],
      }
      return singleSummaryChecklist
    } else {
      const groupedData: Array<GroupedSummaryChecklist | DataChecklistSummaryInstance> = [
        {
          type: 'grouped',
          statusType: value.reduce((acc, curr) => (!acc.includes(curr.status) ? [...acc, curr.status] : [...acc]), [
            '',
          ]),
          numberOfAsset: value.length,
          userNumber:
            value.reduce(
              (acc, curr) =>
                curr.user ? (!acc.includes(curr.user.id) ? [...acc, curr.status] : [...acc]) : [...acc],
              ['']
            ).length - 1,
          color: value.reduce((acc, curr) => {
            if (acc === '#f97f8d') {
              return acc
            }
            if (curr.color === '#f97f8d') {
              return curr.color
            }
            if (curr.color === '#fef6dd') {
              return curr.color
            }
            return acc
          }, ''),
          openStatus: true,
          title: key,
          childrenOpen: openedChecklist.includes(key),
        },
        ...value.map(
          v =>
            ({
              type: 'data',
              openStatus: openedChecklist.includes(key),
              ...v,
            } as DataChecklistSummaryInstance)
        ),
      ]
      return groupedData
    }
  })
  const aggregatedData: SummaryChecklistAggregation[] = []
  aggregationResult.forEach(value => {
    if (!Array.isArray(value)) {
      aggregatedData.push(value)
    } else {
      value.forEach(v => aggregatedData.push(v))
    }
  })
  return aggregatedData
}