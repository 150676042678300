import React, {Component} from 'react'
import {
  Badge,
  Filters,
  PageHeader,
  Table,
  TableRowButtonGroup,
  TableRowInfo,
} from '@mv-submodules/inplant-components-fe'
import MainPageContent from '@mv-submodules/inplant-components-fe/ui/components/Layout/MainPageContent'
import {withTranslation, WithTranslation} from 'react-i18next'
import {FilterComponent} from '@mv-submodules/inplant-components-fe/ui/components/Filters/Filters'
import {RouteComponentProps} from 'react-router'
import {Dispatch} from 'redux'
import {connect} from 'react-redux'
import {ChecklistSetupItem} from '@mv-submodules/inplant-checklist-fe-iblu/types'
import {
  applyParams,
  DispatchFetchListProps,
  fetchList,
  ListParams,
  TableParams,
} from '@mv-submodules/inplant-checklist-fe-iblu/redux/actions/checklistSetup'
import SetupModalComponent
  from '@mv-submodules/inplant-checklist-fe-iblu/ui/components/views/SetupComponent/SetupModalComponent'

type OwnProps = RouteComponentProps<any> & WithTranslation & {
  list: ChecklistSetupItem[]
  fetching: boolean
  error: boolean
}

interface OwnState extends ListParams {
  isFetching: boolean,
  fetchErrors: boolean,
  page: number
  modalChecklistId: null | string
  searchAsset?: string
  searchStatus?: string
}

const mapStateToProps = (store: any): ListParams & { list: ChecklistSetupItem[] } => {
  return {
    ...store.checklist.setup,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): any => ({
  fetchData: () => fetchList()(dispatch),
  applyParams: (params: TableParams) => dispatch(applyParams(params)),
})

type Props = OwnProps & ListParams & DispatchFetchListProps<ChecklistSetupItem>

class SetupComponent extends Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      page: this.props.page || 1,
      isFetching: false,
      fetchErrors: false,
      modalChecklistId: null,
      filtered: [],
      pageSize: props.pageSize || 10,
      search: props.search,
      searchAsset: '',
      searchStatus: '',
    }

    this.applyTableParams = this.applyTableParams.bind(this)
    this.handleTablePageChange = this.handleTablePageChange.bind(this)
    this.handleTablePageSizeChange = this.handleTablePageSizeChange.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleSearchStatusChange = this.handleSearchStatusChange.bind(this)
    this.handleChecklistConfigureClick = this.handleChecklistConfigureClick.bind(this)
    this.handleModalClose = this.handleModalClose.bind(this)
  }

  public componentDidMount() {
    this.props.fetchData()
  }

  public render() {
    const {t, pageSize, fetching} = this.props
    const {page, modalChecklistId} = this.state
    const checklists = this.filterData(this.props.list)

    const filters: FilterComponent[] = [
      {
        id: 'checklist-setup-search',
        value: this.state.search || '',
        type: 'SearchInput',
        disabled: this.state.isFetching,
        label: t('checklist.setup.table.searchPlaceholder'),
        onChange: (name, value) => this.handleSearchChange(value && value as string || undefined),
      },
      {
        id: 'checklist-setup-search-status',
        value: this.state.searchStatus || '',
        type: 'Select',
        disabled: this.state.isFetching,
        label: t('checklist.setup.table.searchStatusPlaceholder'),
        onChange: (name, value) => this.handleSearchStatusChange(value && value as string || undefined),
        options: {
          defaultOptionLabel: t('checklist.setup.filterAllStatus'),
          items: [
            {
              value: 'deactivated',
              label: t('checklist.setup.statusLabels.deactivated'),
            },
            {
              value: 'active',
              label: t('checklist.setup.statusLabels.active'),
            },
            {
              value: 'to-be-configured',
              label: t('checklist.setup.statusLabels.tobeconfigured'),
            },
            {
              value: 'to-be-validated',
              label: t('checklist.setup.statusLabels.tobevalidated'),
            },
          ],
        },
      },
    ]

    const badgeType = (type: string) => {
      switch (type) {
        case 'deactivated':
          return 'negative'

        case 'active':
          return 'positive'

        case 'to-be-configured':
          return 'warning'

        case 'to-be-validated':
          return 'warning'

        default:
          return 'neutral'
      }
    }

    return (
      <React.Fragment>
        <PageHeader title={t('checklist.setup.table.title')}/>
        <SetupModalComponent onClose={this.handleModalClose} checklistId={modalChecklistId}/>

        <MainPageContent>
          <Filters fields={filters}/>
          <Table
            data={!fetching ? checklists : []}
            columns={[
              {
                Header: t('checklist.setup.table.status'),
                accessor: 'status',
                Cell: ({original}: TableRowInfo) =>
                  <Badge
                    type={badgeType(original.status)}
                    value={t('checklist.setup.statusLabels.' + original.status.replaceAll('-', ''), {defaultValue: original.status})}
                  />,
              },
              {
                Header: t('checklist.setup.table.code'),
                accessor: 'code',
              },
              {
                Header: t('checklist.setup.table.positionCode'),
                accessor: 'positionCode',
              },
              {
                Header: t('checklist.setup.table.description'),
                accessor: 'description',
              },
              {
                Header: t('checklist.setup.table.model'),
                accessor: 'model',
              },
              {
                Header: this.props.t('checklist.setup.table.actions'),
                sortable: false,
                style: {overflow: 'visible'},
                minWidth: 150,
                Cell: (row: TableRowInfo) => {
                  if (['deactivated'].includes(row.original.status)) {
                    return null
                  }
                  return (
                    <TableRowButtonGroup
                      buttons={[
                        {
                          variant: 'primary',
                          label: this.props.t('checklist.setup.configure').toUpperCase(),
                          onClick: () => this.handleChecklistConfigureClick(row.original.id),
                        },
                      ]}
                    />
                  )
                },
              }
            ]}
            showPagination={true}
            showPaginationBottom={true}
            noDataText={t('checklist.setup.table.noData')}
            isFetching={fetching}
            onPageChange={this.handleTablePageChange}
            onPageSizeChange={this.handleTablePageSizeChange}
            //  onSortedChange={this.handleTableSortedChange}
            page={page - 1}
            manualIsFetching={true}
            pageSize={pageSize}
          />
        </MainPageContent>
      </React.Fragment>
    )
  }

  private applyTableParams(params: ListParams) {
    this.props.applyParams(params)
  }

  private filterData(data: ChecklistSetupItem[]): ChecklistSetupItem[] {
    const {search, searchStatus} = this.state
    if (!(search || searchStatus) || !data) {
      return data
    }
    const q = search ? search.toLowerCase() : ''

    return data
      .filter((checklist) => {

          return searchStatus && searchStatus !== '' && (searchStatus === checklist.status) ||
            !searchStatus || (searchStatus === '')
        },
      )
      .filter((checklist) =>
        !search || (search === '') || ['code', 'model', 'positionCode', 'description'].reduce(
        (check: boolean, key: string) =>
          check || !!(checklist[key] && checklist[key].toLowerCase().match(q)),
        false,
        ),
      )
  }

  private handleTablePageChange(page: number) {
    this.setState({page: page + 1}, () => this.applyTableParams({
      sorted: this.props.sorted,
      search: this.props.search,
      page: page + 1,
      pageSize: this.props.pageSize,
      filtered: this.props.filtered,
    }))
  }

  private handleTablePageSizeChange(pageSize: number) {
    this.applyTableParams({page: 1, pageSize})
  }

  private handleSearchChange(search: string | undefined) {
    this.setState({
      search,
    }, () => {
      this.applyTableParams({
        sorted: this.props.sorted,
        search,
        page: this.props.page,
        pageSize: this.props.pageSize,
        filtered: this.props.filtered,
      })
    })
  }

  private handleSearchStatusChange(searchStatus: string | undefined) {
    this.setState({
      searchStatus: searchStatus || '',
    })
  }

  private handleChecklistConfigureClick(modalChecklistId: string) {
    this.setState({
      modalChecklistId,
    })
  }

  private handleModalClose() {
    this.setState({
      modalChecklistId: null,
    }, () => this.props.fetchData())
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(SetupComponent))
