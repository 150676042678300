import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { isJSON } from '../functions/objects'

export interface LanguageConfiguration {
  defaultLanguage: string,
  allowedLanguages: string[]
}

const languageDetector: LanguageDetector = new LanguageDetector(null, { order: ['localStorage', 'navigator'] })

const availableLngObjJson = (window as any).I18N_CONFIG ||
  process.env.REACT_APP_I18N_CONFIG || { defaultLanguage: 'en', allowedLanguages: ['en'] }

export const LANGUAGE_CONFIGURATION: LanguageConfiguration = isJSON(availableLngObjJson)
  ? JSON.parse(availableLngObjJson)
  : availableLngObjJson

const i18nextInit = (options: any) =>
  i18next
    .use(languageDetector)
    .use(initReactI18next)
    .init({
      debug: false,
      fallbackLng: [LANGUAGE_CONFIGURATION.defaultLanguage, 'en'],
      initImmediate: true,
      react: {
        ignoreJSONStructure: true,
        bindI18n: 'languageChanged',
        bindI18nStore: 'added',
        transEmptyNodeValue: '',
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
        useSuspense: true,
      },
      interpolation: {
        escapeValue: false,
      },
      appendNamespaceToMissingKey: true,
      parseMissingKeyHandler: (key: string) => `@@${key}@@`,
      ns: ['translation'],
      defaultNS: 'translation',
      ...options,
    })

export {
  i18next,
  i18nextInit,
}