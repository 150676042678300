import { Container } from 'inversify'
import React, { useContext, useRef, useState } from 'react'

export const getContainer: () => Container = () => new Container()

function useLazyRef<T>(resolveValue: () => T): T {
  const ref = useRef<{ v: T }>()
  if (!ref.current) {
    ref.current = { v: resolveValue() }
  }
  return ref.current.v
}

export const TYPES = {
  TranslationService: Symbol.for('TranslationService'),
  IconComponentService: Symbol.for('IconComponentService'),
  StateManagerService: Symbol.for('StateManagerService'),
} as const

type UpdateContainer = <T>(identifier: typeof TYPES[keyof typeof TYPES], instance: T) => void

interface ContainerContext {
  container: Container | null
  updateContainer: UpdateContainer
}

const InversifyContext = React.createContext<ContainerContext>({ container: null, updateContainer: () => null })

function useInversifyContainer<T>(): ContainerContext
function useInversifyContainer<T>(resolve: (container: Container) => T): T
function useInversifyContainer<T>(resolve?: (container: Container) => T): T | ContainerContext {
  const context = useContext(InversifyContext)
  if (!context.container) {
    throw new Error(
      'Cannot find Inversify container on React Context. ' + '`Provider` component is missing in component tree.',
    )
  }
  return resolve ? useLazyRef(() => resolve(context.container!)) : context
}

export function useInjection<T>(id: typeof TYPES[keyof typeof TYPES]): T {
  return useInversifyContainer(container => container.get<T>(id))
}

export function useUpdateContainerInstance<T>(): UpdateContainer {
  const { updateContainer } = useContext(InversifyContext)
  return updateContainer
}

interface ContainerProviderPros {
  container: Container
}

/**
 * **Example of implementation**
 * ```js
 *   const container = getContainer()
 *   container.bind<TranslationService>(TYPES.TranslationService).toConstantValue(new TranslationService(i18next))
 *   container.bind<IconComponentService>(TYPES.IconComponentService).toConstantValue(new IconComponentService(FontAwesomeIcon))
 *   <ContainerProvider container={container}>
 *     {MyApplication}
 *   </ContainerProvider>
 * ```
 */
export const ContainerProvider: React.FC<ContainerProviderPros> = ({ container, children }) => {

  const [, setX] = useState<boolean>()

  return (
    <InversifyContext.Provider
      value={
        {
          container,
          updateContainer: (id, instance) => {
            container.rebind<typeof instance>(id).toConstantValue(instance)
            setX(prev => !prev)
          },
        }
      }
    >
      {children}
    </InversifyContext.Provider>
  )
}
