import * as React from 'react'
import Slider from 'react-slick'
import { Tooltip } from 'react-tippy'
import ZoomableImage from '../../widgets/Image/ZoomableImage'
import { SliderPhoto } from '../../../../types/checklistPhotos'
import { SliderArrow } from './SliderArrow'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

interface Props {
  visible: boolean
  title: string
  modalSliderRef: React.RefObject<any>
  modalPhotos: SliderPhoto[] | null
  modalPhotoIndex: number | null
  closeLabel: string
  onClose: () => void
}

const ChecklistPhotosModal = (props: Props) => {
  const sliderSettings = {
    accessibility: true,
    // adaptiveHeight: true,
    // centerMode: true,
    arrows: true,
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <SliderArrow>
        <IconComponent icon={'chevron-right'} size="2x" />
      </SliderArrow>
    ),
    prevArrow: (
      <SliderArrow>
        <IconComponent icon={'chevron-left'} size="2x" />
      </SliderArrow>
    ),
  }
  const { visible, title, modalPhotos, modalPhotoIndex, modalSliderRef, onClose } = props
  const visiblePhotos: SliderPhoto[] = visible ? (modalPhotos!.filter(photo => photo) as SliderPhoto[]) : []
  const visiblePhotoIndex: number = visible ? visiblePhotos.indexOf(modalPhotos![modalPhotoIndex!]!) : -1

  const handleClickOutside = (event: React.MouseEvent<any>) => {
    event.preventDefault()
    if (event.currentTarget === event.target) {
      props.onClose()
    }
  }

  return (
    (visible && (
      <div className="modal" role="dialog" onClick={handleClickOutside}>
        <div className={`modal-dialog checklist-photos-modal`} role="document">
          <div className="modal-content">
            <div className="modal-header">
              {title && <h5 className="modal-title">{title}</h5>}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={event => {
                  event.preventDefault()
                  onClose()
                }}
              >
                <IconComponent icon={'times'} size="sm" />
              </button>
            </div>
            <div className="modal-body">
              <Slider initialSlide={visiblePhotoIndex} {...sliderSettings}>
                {visiblePhotos!.map((photo, index) => (
                  <div key={index} ref={index === visiblePhotoIndex ? modalSliderRef : null}>
                    <>
                      <ZoomableImage
                        className="checklist-photo-image"
                        path={`/file/${photo.path}`}
                        iconSize="2x"
                        zoom={2}
                        zoomSize={300}
                      />
                      <div className="d-flex justify-content-between align-items-center pt-2">
                        {photo.description}
                        {photo.notes && (
                          <Tooltip trigger="mouseenter click" position="top" title={photo.notes}>
                            <IconComponent icon={'info-circle'} size="sm" className="text-muted" />
                          </Tooltip>
                        )}
                      </div>
                    </>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    )) ||
    null
  )
}

export default ChecklistPhotosModal
