import * as React from 'react'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { checklistUpdateComponent } from '../../../../redux/actions'

export interface ComponentData {
  id: string
  optional: boolean
  type: {
    type: string
    value: number
  }
  defaultValue: number
  lastValue: number
  title?: string | null
}

export interface StateProps {
  componentData: ComponentData
  checklistInstanceId: string
  checkId: string
}

export interface DispatchProps {
  updateComponent: (checklistInstanceId: string, componentId: string, value: number | null, conclude: boolean) => Promise<any>
}

export interface OwnProps {
  disabled: boolean
  collapseHandler?: Function
}

export interface OwnState {
  currentValue: number | null
  loading: boolean
}

export type Props = StateProps & DispatchProps & OwnProps & WithTranslation

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    updateComponent: (checklistInstanceId, componentId, value, conclude) =>
      dispatch(checklistUpdateComponent(checklistInstanceId, componentId, value, conclude)),
  }
}

export class NumberComponent extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    const lastValue = props.componentData.lastValue
    const defaultValue = props.componentData.defaultValue
    this.state = {
      loading: false,
      currentValue: lastValue || defaultValue,
    }
    this.updateComponent = this.updateComponent.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.manageCollapsable()
  }

  private manageCollapsable() {
    if (
      this.props.componentData.lastValue !== this.props.componentData.defaultValue &&
      this.props.componentData.optional &&
      this.props.collapseHandler
    ) {
      this.props.collapseHandler()
    }
  }

  private handleChange(event: any) {
    event.persist()
    this.setState(prevState => {
      const value = parseFloat(event.target.value)
      return { ...prevState, currentValue: !isNaN(value) && value || null }
    })
  }

  private updateComponent(event: any) {
    event.persist()
    const value = parseFloat(event.target.value)
    this.setState(
      prevState => {
        return { ...prevState, currentValue: !isNaN(value) && value || null, loading: true }
      },
      () => {
        this.props
          .updateComponent(this.props.checklistInstanceId, this.props.componentData.id, this.state.currentValue, false)
          .then(() => {
            this.setState(prevState => {
              return { ...prevState, loading: false }
            })
          })
      }
    )
  }

  public render() {
    return (
      <div className="collapse cl-controls-optional cl-controls-optional-1 show">
        <div className="form-group text-left">
          <label htmlFor={`${this.props.componentData.id}-value`}>
            {this.props.componentData.title || this.props.t('checklist.components.number.value')}
          </label>
          <input
            type="number"
            min="0"
            step="0.01"
            id={`${this.props.componentData.id}-value`}
            className="form-control"
            placeholder={this.props.t('checklist.components.number.value')}
            onChange={this.handleChange}
            onBlur={this.updateComponent}
            value={this.state.currentValue || ''}
            disabled={this.state.loading || this.props.disabled}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(NumberComponent))
