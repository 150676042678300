import * as React from 'react'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'
import ComponentGroup, { Props as ComponentGroupProps } from './ComponentGroup'

export interface OwnProps extends ComponentGroupProps {
  title: string
}

export interface OwnState {
  opened: boolean
}

export type Props = OwnProps

export default class CollapsableComponentGroup extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = { opened: false }
    this.collapseHandler = this.collapseHandler.bind(this)
    this.toggleCollapseState = this.toggleCollapseState.bind(this)
  }

  public collapseHandler(open: boolean = true) {
    this.setState(prevState => ({
      ...prevState,
      opened: open,
    }))
  }

  private toggleCollapseState() {
    this.setState(prevState => ({
      ...prevState,
      opened: !prevState.opened,
    }))
  }

  public render() {
    const randomId = (0 | (Math.random() * 9e6)).toString(36) // tslint:disable-line
    if (this.props.components.length === 0) {
      return null
    }
    return (
      <div className="collapsable-component-group mt-2">
        {!this.props.disabled ? (
          <button
            className="btn btn-sm btn-outline-secondary btn-block mb-2 collapsable-component-toggler"
            data-toggle="collapse"
            data-target={`#${randomId}`}
            aria-expanded={this.state.opened ? 'true' : 'false'}
            aria-controls={`${randomId}`}
            onClick={this.toggleCollapseState}
          >
            <IconComponent icon={'plus'} className="mr-1" />
            {this.props.title}
          </button>
        ) : null}
        <div id={`${randomId}`} className={`collapse ${this.state.opened ? 'show' : ''}`}>
          <ComponentGroup {...this.props} collapseHandler={this.collapseHandler} />
        </div>
      </div>
    )
  }
}
