import * as React from 'react'

type ClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => void

interface Props {
  className?: string
  children: JSX.Element
  onClick?: ClickHandler
}

export const SliderArrow = (props: Props) => (
  <button className={props.className} onClick={props.onClick}>
    {props.children}
  </button>
)

export const SliderArrowAll = (onClick: ClickHandler) => (props: Props) => (
  <button className={props.className} onClick={onClick}>
    {props.children}
  </button>
)
