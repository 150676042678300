import * as React from 'react'
import {withTranslation, WithTranslation} from 'react-i18next'
import {RowInfo} from 'react-table'
import * as moment from 'moment'
import {fetchList, printReport} from '../../../../redux/actions/gia'
import {GiaListItem} from '../../../../types/gia'
import {Filters, PageHeader, Table, TableRowButtonGroup} from "@mv-submodules/inplant-components-fe";
import MainPageContent from "@mv-submodules/inplant-components-fe/ui/components/Layout/MainPageContent";
import {FilterComponent} from "@mv-submodules/inplant-components-fe/ui/components/Filters/Filters";
import {RouteComponentProps, withRouter} from 'react-router'
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'

export type Props = WithTranslation & RouteComponentProps<any>

export interface OwnState {
  fetching: boolean
  fetchErrors: boolean
  list: GiaListItem[] | null
  printing: { [giaId: string]: boolean }
  filters: {
    user?: string,
    created?: string,
    concluded?: string
  }
}

export class GiaListComponent extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      fetching: false,
      fetchErrors: false,
      list: null,
      printing: {},
      filters: {},
    }
    this.handlePrintGia = this.handlePrintGia.bind(this)
    this.gotoGia = this.gotoGia.bind(this)
  }

  public componentDidMount() {
    this.setState({fetching: true})
    fetchList()
      .then(list => this.setState({list}))
      .catch(() => this.setState({fetchErrors: true}))
      .finally(() => this.setState({fetching: false}))
  }


  public render() {
    const fetching = this.state.fetching
    const filters = this.state.filters
    const list = (this.state.list || []).filter((element) => (
      (!filters.user || (element.user.displayName.toUpperCase().replace(" ", "")
        .includes(filters.user.toUpperCase().replace(" ", "")))) &&
      (!filters.created || (moment(element.createdTs).isSameOrAfter(moment(filters.created), 'day'))) &&
      (!filters.concluded || (moment(element.concludedTs).isSameOrBefore(moment(filters.concluded), 'day')))
    ))
    const filtersElements: FilterComponent[] = [{
      disabled: fetching || !this.state.list,
      name: 'user',
      id: 'checklist-user-name',
      type: 'SearchInput',
      label: this.props.t('gia.table.user'),
      value: this.state.filters.user || null,
      onChange: (name, value) => this.setState({
        filters: {
          ...this.state.filters,
          [name]: value
        }
      }),
    }, {
      disabled: fetching || !this.state.list,
      name: 'created',
      id: 'checklist-created-date',
      type: 'Date',
      value: this.state.filters.created || null,
      label: this.props.t('gia.table.createdDate'),
      onChange: (name, value) => this.setState({
        filters: {
          ...this.state.filters,
          [name]: value
        }
      }),
    }, {
      disabled: fetching || !list,
      name: 'concluded',
      id: 'checklist-concluded-date',
      type: 'Date',
      value: this.state.filters.concluded || null,
      label: this.props.t('gia.table.concludedDate'),
      onChange: (name, value) => this.setState({
        filters: {
          ...this.state.filters,
          [name]: value
        }
      }),
    }]
    return (
      <>
        <PageHeader title={this.props.t('checklist.navigation.gia')}/>
        <MainPageContent>
          <Filters fields={filtersElements}/>
          <Table
            isFetching={fetching}
            manualIsFetching={true}
         //   pageSize={list.length}
            showPaginationTop={true}
            showPaginationBottom={true}
            data={list}
            pageText={this.props.t('checklist.archive.table.page')}
            ofText={this.props.t('checklist.archive.table.of')}
            rowsText={this.props.t('checklist.archive.table.rows')}
            //    minRows={4}
            noDataText={this.props.t('gia.table.noData')}
            columns={[
              {
                Cell: ({original}: RowInfo) => moment(original.date).format('DD/MM/YYYY'),
                Header: this.props.t('gia.table.date'),
                accessor: 'date'
              },
              {
                Header: this.props.t('gia.table.user'),
                accessor: 'user.displayName'
              },
              {
                Cell: ({original}: RowInfo) => moment(original.createdTs).format('DD/MM/YYYY HH:mm'),
                Header: this.props.t('gia.table.createdDate'),
                accessor: 'createdTs'
              },
              {
                Cell: ({original}: RowInfo) => moment(original.concludedTs).format('DD/MM/YYYY HH:mm'),
                Header: this.props.t('gia.table.concludedDate'),
                accessor: 'concludedTs'
              },
              {
                sortable: false,
                filterable: false,
                Cell: ({original}: RowInfo) => (
                  <TableRowButtonGroup
                    buttons={[{
                      variant: 'secondary-alternate',
                      onClick: () => this.gotoGia(original.id),
                      icon: "eye"
                    }, {
                      printButton: true,
                      onClick: () => this.handlePrintGia(original as GiaListItem),
                      isLoading: this.state.printing[original.id]
                    }]}
                  />
                ),
              },
            ]}
          />
        </MainPageContent>
      </>
    )
  }

  private gotoGia(id: string) {
    this.props.history.push(`/checklist/gia/${id}`)
  }

  private async handlePrintGia(gia: GiaListItem) {
    this.setState(prevState => {
      const printing = prevState.printing
      printing[gia.id] = true
      return {
        ...prevState,
        printing,
      }
    })
    try {
      const {user, createdTs} = gia
      const blob = await printReport(gia.id)
      const objectURL = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = objectURL
      link.download = `GIA ${user.displayName} ${mvDate.getDateFromStringWithFormatting(createdTs, 'dd-MM-yyyy')}.pdf`
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (error) {
      console.warn('Download error', error) // tslint:disable-line
    } finally {
      this.setState(prevState => {
        const printing = prevState.printing
        printing[gia.id] = false
        return {
          ...prevState,
          printing,
        }
      })
    }
  }
}

export default withRouter<any, any>(withTranslation()(GiaListComponent))
