import {API} from './index'
import {AnyAction, Dispatch} from 'redux'
import {ChecklistInstance, ChecklistInstanceParams, Asset, ChecklistModel, ChecklistModelParams} from '../../types'
import {logoutUser} from '@mv-submodules/inplant-coreadapter-fe/auth'
import {SortingRule, Filter} from 'react-table'
import FetchError from "@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError";

export const isFetching = (): AnyAction => {
    return {type: 'CHECKLIST_VALIDATION_IS_FETCHING'}
}

export const isValidating = (): AnyAction => {
    return {type: 'CHECKLIST_VALIDATION_IS_VALIDATING'}
}

export const fetchSuccess = (checklists: ChecklistInstance[]): AnyAction => {
    return {
        payload: checklists,
        type: 'CHECKLIST_VALIDATION_FETCH_SUCCESS',
    }
}

export const validateSuccess = (): AnyAction => {
    return {
        type: 'CHECKLIST_VALIDATION_VALIDATE_SUCCESS',
    }
}

export const fetchAssetsSuccess = (assets: Asset[]): AnyAction => {
    return {
        payload: assets,
        type: 'CHECKLIST_VALIDATION_FETCH_ASSETS_SUCCESS',
    }
}

export const fetchModelsSuccess = (models: ChecklistModel[]): AnyAction => {
    return {
        payload: models,
        type: 'CHECKLIST_VALIDATION_FETCH_MODELS_SUCCESS',
    }
}

export const fetchError = (error: Error): AnyAction => {
    return {
        payload: error,
        type: 'CHECKLIST_VALIDATION_FETCH_ERROR',
    }
}

export const validateError = (error: Error): AnyAction => {
    return {
        payload: error,
        type: 'CHECKLIST_VALIDATION_VALIDATE_ERROR',
    }
}

export const fetchAssetsError = (error: Error): AnyAction => {
    return {
        payload: error,
        type: 'CHECKLIST_VALIDATION_FETCH_ASSETS_ERROR',
    }
}

export const fetchModelsError = (error: Error): AnyAction => {
    return {
        payload: error,
        type: 'CHECKLIST_VALIDATION_FETCH_MODELS_ERROR',
    }
}

// TODO
export const fetchPendingValidations = () => async (dispatch: Dispatch<AnyAction>) => {
        dispatch(isFetching())
        try {
            const response = await API().request('/pending-validation-checklists')
            const list: any[] = response.map((cl: ChecklistInstanceParams) => new ChecklistInstance(cl))
            dispatch(fetchSuccess(list))
            dispatch(updatePendingValidationsCounter(list.length))
        } catch (error:any) {
            if (error.name === 'FetchError' && error.statusCode === 401) {
                dispatch(logoutUser())
            }
            dispatch(fetchError(new Error(error)))
            throw error
        }
}

// TODO
export const fetchPendingValidationsNoRedux = () => (dispatch: Dispatch) => {
    return API().request('/pending-validation-checklists')
        .then((response: any) => {
            const list: any[] = response.map((cl: ChecklistInstanceParams) => new ChecklistInstance(cl))
            dispatch(updatePendingValidationsCounter(list.length))
            return list
        })
        .catch((error: FetchError) => {
            if (error.name === 'FetchError' && error.statusCode === 401) {
                dispatch(logoutUser())
            }
            throw error
        })
}

export const fetchAssets = (): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
    return async dispatch => {
        dispatch(isFetching())
        try {
            const response = await API().request('/assets')
            const list = response.map((asset: Asset) => new Asset(asset))
            dispatch(fetchAssetsSuccess(list))
        } catch (error:any) {
            dispatch(fetchAssetsError(new Error(error)))
            throw error
        }
    }
}

// TODO
export const fetchModels = (): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
    return async dispatch => {
        dispatch(isFetching())
        try {
            const response = await API().request('/checklist-models')
            const list = response.map((cl: ChecklistModelParams) => new ChecklistModel(cl))
            dispatch(fetchModelsSuccess(list))
        } catch (error:any) {
            dispatch(fetchModelsError(new Error(error)))
            throw error
        }
    }
}

export const validate = (
    checklistId: string,
    valid: boolean,
    comment?: string,
    file?: string
): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
    return async dispatch => {
        dispatch(isValidating())
        const formData = new FormData()
        formData.append('checklistId', checklistId)
        formData.append('valid', valid.toString())
        if (comment) {
            formData.append('comment', comment)
        }
        if (file) {
            formData.append('file', file)
        }
        try {
            await API().request(`/validate/${checklistId}`, {
                method: 'POST',
                body: formData,
            })
            dispatch(validateSuccess())
            dispatch(decrementPendingValidationsCounter())
        } catch (error:any) {
            if (error.name === 'FetchError' && error.statusCode === 401) {
                dispatch(logoutUser())
            }
            dispatch(validateError(new Error(error)))
            throw error
        }
    }
}

export const getFile = (validationId: string): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
    return async (dispatch: Dispatch<AnyAction>) => {
        try {
            const blob = await API().request(`/validation/file/${validationId}`)
            return blob
        } catch (error:any) {
            if (error.name === 'FetchError' && error.statusCode === 401) {
                dispatch(logoutUser())
            }
            dispatch(fetchError(new Error(error)))
            throw error
        }
    }
}

export const persistTableState = (params: {
    page?: number
    pageSize?: number
    sorted?: SortingRule[]
    filtered?: Filter[]
}): AnyAction => ({
    type: 'CHECKLIST_VALIDATION_PERSIST_TABLE_STATE',
    payload: params,
})

export const updatePendingValidationsCounter = (n: number): AnyAction => {
    return {
        payload: n,
        type: 'CHECKLIST_VALIDATION_UPDATE_COUNTER',
    }
}

export const decrementPendingValidationsCounter = (): AnyAction => {
    return {
        type: 'CHECKLIST_VALIDATION_DECREMENT_COUNTER',
    }
}
