import ChecklistModelsAssets from './components/views/ChecklistModelsAssets/ChecklistModelsAssetsPageView'
import ChecklistIndex from './components/views/ChecklistIndex/ChecklistIndexPageView'
import ChecklistArchive from './components/views/ChecklistArchive/ChecklistArchivePageView'
import ChecklistPersonalArchive from './components/views/ChecklistPersonalArchive/ChecklistPersonalArchivePageView'
import ChecklistValidation from './components/views/ChecklistValidation/ChecklistValidationPageView'
import ChecklistAsset from './components/views/ChecklistAsset/ChecklistAssetPageView'
import ChecklistEdit from './components/views/ChecklistEdit/ChecklistEdit'
import ChecklistPhotos from './components/views/ChecklistPhotos/ChecklistPhotosPageView'
import ChecklistTodaySummary from './components/views/ChecklistTodaySummary/ChecklistTodaySummaryPageView'
import ChecklistYesterdaySummary from './components/views/ChecklistDaySummary/ChecklistDaySummaryPageView'
import ChecklistSummary from './components/views/ChecklistSummary/ChecklistSummaryPageView'
import ResetHoursComponent from './components/views/ResetHoursComponent/ResetHoursComponentPageView'
import GiaListComponent from './components/views/GisListComponent/GiaListComponentPageView'
import GiaDetailsComponent from './components/views/GiaDetailsComponent/GiaDetailsComponentPageView'
import SetupComponent from './components/views/SetupComponent/SetupComponentPageView'
import { RouteProps } from '../../inplant-coreadapter-fe/types/routes'
import ProximityChecklistPageView
  from '@mv-submodules/inplant-checklist-fe-iblu/ui/components/views/ProximityChecklist/ProximityChecklistPageView'

const routes: RouteProps = {
  path: '/checklist/models-assets',
  i18nkey: 'checklist.navigation.checklists',
  icon: 'list',
  visible: true,
  children: [
    {
      path: '/checklist/models-assets',
      exact: true,
      i18nkey: 'checklist.navigation.checklistModelsAssets',
      component: ChecklistModelsAssets,
      visible: true,
    },
    {
      path: '/checklist/models',
      exact: true,
      i18nkey: 'checklist.navigation.checklistModels',
      component: ChecklistIndex,
      visible: true,
    },
    {
      path: '/checklist/archive',
      exact: true,
      i18nkey: 'checklist.navigation.checklistArchive',
      component: ChecklistArchive,
      visible: true,
    },
    {
      path: '/checklist/today-summary',
      exact: true,
      i18nkey: 'checklist.navigation.checklistTodaySummary',
      component: ChecklistTodaySummary,
      visible: true,
    },
    {
      path: '/checklist/day-summary',
      exact: true,
      i18nkey: 'checklist.navigation.checklistYesterdaySummary',
      component: ChecklistYesterdaySummary,
      visible: true,
    },
    {
      path: '/checklist/personal-archive',
      exact: true,
      i18nkey: 'checklist.navigation.checklistPersonalArchive',
      component: ChecklistPersonalArchive,
      visible: true,
    },
    {
      path: '/checklist/validations',
      exact: true,
      i18nkey: 'checklist.navigation.checklistValidations',
      component: ChecklistValidation,
      visible: true,
      counterStoreKey: 'checklist.validation.validationsCount',
    },
    {
      path: '/checklist/:checklistModelId/asset',
      component: ChecklistAsset,
      visible: false,
    },
    {
      path: '/checklist/edit/:checklistId',
      component: ChecklistEdit,
      visible: false,
    },
    {
      path: '/checklist/summary/:checklistId',
      component: ChecklistSummary,
      visible: false,
    },
    {
      path: '/checklist/reset-asset-hours',
      exact: true,
      i18nkey: 'checklist.navigation.resetAssetHours',
      component: ResetHoursComponent,
      visible: true,
    },
    {
      path: '/checklist/photos',
      exact: true,
      i18nkey: 'checklist.navigation.checklistPhotos',
      component: ChecklistPhotos,
      visible: true,
    },
    {
      path: '/checklist/gia',
      exact: true,
      i18nkey: 'checklist.navigation.gia',
      component: GiaListComponent,
      visible: true,
    },
    {
      path: '/checklist/gia/:id',
      exact: true,
      component: GiaDetailsComponent,
      visible: false,
    },
    {
      path: '/checklist/setup',
      exact: true,
      i18nkey: 'checklist.navigation.setup',
      component: SetupComponent,
      visible: true,
    },
    {
      path: '/checklist/proximity-checklists/:beaconId',
      exact: true,
      i18nkey: 'checklist.navigation.test',
      component: ProximityChecklistPageView,
      visible: false,
    },
  ],
}

export default routes
