import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { withTranslation, WithTranslation } from 'react-i18next'
import {
  Button,
  ButtonGroupHeader,
  Filters,
  MainPageContent,
  PageHeader,
  Table,
  TableRowButtonGroup,
} from '@mv-submodules/inplant-components-fe'
import { Dispatch } from 'redux'
import {
  applyGroupsListFilters,
  applyGroupsListPagination,
  fetchGroupsList,
} from '../../../../redux/actions/groupsList'
import { FetchGroupsListResponse } from '../../../../types/fetchData'
import { GroupsListElement, GroupsListFilters } from '../../../../types/groups'
import { connect } from 'react-redux'
import { RowInfo } from 'react-table'
import { AssetManagerAcl, ListPagination } from '@mv-submodules/inplant-asset-manager-fe/types/asset'
import { FilterComponent } from '@mv-submodules/inplant-components-fe/ui/components/Filters/Filters'

interface OwnState {
  data?: FetchGroupsListResponse
  isFetching: boolean
  filters: GroupsListFilters
  pagination: ListPagination
}

interface StateProps {
  filters: GroupsListFilters
  pagination: ListPagination
  forbiddenActions: string[]
}

const mapStateToProps = (store: any): StateProps => {
  return {
    filters: store.assetManager.groupsList.filters,
    pagination: store.assetManager.groupsList.pagination,
    forbiddenActions: store.auth.user.forbiddenActions,
  }
}

interface DispatchProps {
  applyGroupsListFilters: (params: GroupsListFilters) => void
  applyPaginationListGroups: (params: ListPagination) => void
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  applyGroupsListFilters: (params: GroupsListFilters) => dispatch(applyGroupsListFilters(params)),
  applyPaginationListGroups: (params: ListPagination) => dispatch(applyGroupsListPagination(params)),
})

type Props = StateProps & DispatchProps & RouteComponentProps & WithTranslation

class AssetsGroupsListPageView extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      data: undefined,
      isFetching: false,
      filters: {
        searchString: this.props.filters.searchString,
      },
      pagination: {
        ...this.props.pagination,
      },
    }
    this.handleSearchNameChange = this.handleSearchNameChange.bind(this)
    this.applyFilterTable = this.applyFilterTable.bind(this)
    this.applyPaginationTable = this.applyPaginationTable.bind(this)
    this.fetchGroupsList = this.fetchGroupsList.bind(this)
    this.goToCreateGroup = this.goToCreateGroup.bind(this)
    this.handleRowAssetClick = this.handleRowAssetClick.bind(this)
    this.onPageSizeChange = this.onPageSizeChange.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
  }

  public componentDidMount() {
    this.fetchGroupsList()
  }

  private renderGroupCreationButton() {
    const { t } = this.props
    if (this.props.forbiddenActions.includes(AssetManagerAcl.createGroup)) {
      return <></>
    }
    return (
      <ButtonGroupHeader>
        <Button
          type="button"
          onClick={this.goToCreateGroup}
          label={t('assetManager.actions.createGroup')}
          variant="primary"
        />
      </ButtonGroupHeader>
    )
  }

  public render() {
    const { t } = this.props
    const { isFetching, data, filters } = this.state
    const pages = data ? Math.ceil(data.metadata.count / data.metadata.pageSize) : null
    const filterElements: FilterComponent[] = [
      {
        type: 'SearchInput',
        name: 'search-by-name',
        id: 'search-by-name',
        value: filters.searchString,
        disabled: isFetching || !data,
        throttle: 500,
        label: t('assetManager.filters.searchByName'),
        onChange: (name, value) => this.handleSearchNameChange((value && (value as string)) || null),
      },
    ]
    return (
      <div className="inplant-asset-manager-fe">
        {/* Header Section*/}
        <PageHeader
          title={t('assetManager.navigation.assetsGroups')}
          rightButtons={this.renderGroupCreationButton()}
        />
        {/* End Header Section*/}
        {/* Content Section*/}
        <MainPageContent>
          <Filters fields={filterElements} />
          <Table
            columns={[
              {
                Cell: ({ original }: RowInfo) => (
                  <a onClick={() => this.handleRowAssetClick(original)} className="pointer">
                    {original.name}
                  </a>
                ),
                Header: t('assetManager.table.header.groupName'),
                accessor: 'name',
                sortable: false,
              },
              {
                Cell: ({ original }: RowInfo) => original.assetCount,
                Header: t('assetManager.table.header.asset'),
                sortable: false,
                accessor: 'assets',
              },
              {
                Header: '',
                sortable: false,
                Cell: ({ original }: RowInfo) => (
                  <TableRowButtonGroup
                    buttons={[
                      {
                        variant: 'secondary-alternate',
                        icon: 'eye',
                        onClick: () => this.handleRowAssetClick(original),
                      },
                    ]}
                  />
                ),
              },
            ]}
            isFetching={isFetching}
            showPaginationTop={true}
            manualIsFetching={true}
            noDataText={t(`assetManager.table.noData`)}
            ofText={t('assetManager.table.of')}
            rowsText={t('assetManager.table.rows')}
            pageText={t('assetManager.table.page')}
            data={!isFetching && data && data.result ? data.result : undefined}
            pageSize={!isFetching && data ? data.metadata.pageSize : 0}
            page={!isFetching && data ? data.metadata.pageNumber - 1 : 0}
            pages={pages !== null ? pages : 0}
            manual={true}
            onPageSizeChange={this.onPageSizeChange}
            onPageChange={this.onPageChange}
          />
        </MainPageContent>
        {/* End Content Section*/}
      </div>
    )
  }

  private handleSearchNameChange(searchString: string | null) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          searchString,
        },
      },
      this.applyFilterTable
    )
  }

  private applyFilterTable() {
    this.props.applyGroupsListFilters(this.state.filters)
    this.onPageChange(0)
  }

  private applyPaginationTable() {
    this.props.applyPaginationListGroups(this.state.pagination)
    this.fetchGroupsList()
  }

  private async fetchGroupsList() {
    console.log('fetchGroupsList') //tslint:disable-line
    if (!this.state.isFetching) {
      this.setState({ isFetching: true })
      try {
        const data = await fetchGroupsList(this.state.filters, this.state.pagination)
        this.setState({ data })
      } catch (e) {
        console.warn('error', e) //tslint:disable-line
      } finally {
        this.setState({ isFetching: false })
      }
    }
  }

  private goToCreateGroup() {
    this.props.history.push('/asset-manager/groups/create')
  }

  private handleRowAssetClick(group: GroupsListElement) {
    this.props.history.push(`/asset-manager/groups/detail/${group.id}`)
  }

  private onPageSizeChange(pageSize: number) {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          pageSize,
          pageNumber: 1,
        },
      },
      this.applyPaginationTable
    )
  }

  private onPageChange(pageNumber: number) {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          pageNumber: pageNumber + 1,
        },
      },
      this.applyPaginationTable
    )
  }
}

export default withRouter<any, any>(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AssetsGroupsListPageView))
)
