import WithFetchHook, {
  WithFetchHookProps,
} from '@mv-submodules/inplant-core-fe/ui/components/widgets/WithFetchHook/WithFetchHook'
import {
  getChecklistAssociatedToBeacon,
  ProximityChecklist,
} from '@mv-submodules/inplant-checklist-fe-iblu/redux/actions/proximityChecklist'
import React from 'react'
import { Redirect, useParams } from 'react-router'
import { Alert, AlertErrorData } from '@mv-submodules/mvlabs-components-fe/ui/components'
import { MainPageContent } from '@mv-submodules/inplant-components-fe'
import { useComponentsTranslation } from '@mv-submodules/mvlabs-components-fe/services'
import IconComponent from '../../../../../mvlabs-components-fe/ui/components/Icon/Icon'


const ProximityChecklistPageView = (props: WithFetchHookProps<ProximityChecklist, [string]>) => {
  return <Redirect to={`/checklist/edit/${props.state.data.checklistId}`} />
}

const WC = WithFetchHook(ProximityChecklistPageView)

export default () => {
  const { beaconId } = useParams<{ beaconId: string }>()
  const { t } = useComponentsTranslation()

  const padding = "pt-3"

  return <WC
    promise={getChecklistAssociatedToBeacon}
    parameters={[beaconId]}
    renderLoading={() => <div className={`d-flex justify-content-center ${padding}`}>
      <IconComponent className='spinner' icon={'circle-notch'} spin={true} size='4x' />
    </div>}
    renderError={e => {
      if (e.statusCode === 404) {
        return (
          <div className={padding}>
            <MainPageContent>
              <Alert variant={'warning'} text={t(`checklist.proximity.errorNoBeacon`)} />
            </MainPageContent>
          </div>)
      }
      return (
        <div className={padding}>
          <MainPageContent>
            <AlertErrorData />
          </MainPageContent>
        </div>

      )
    }}
  />
}