import * as React from 'react'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { checklistUpdateComponent } from '../../../../redux/actions'

export interface ComponentData {
  id: string
  optional: boolean
  type: {
    type: string
    values: string[]
  }
  defaultValue: string
  lastValue: string
}

export interface StateProps {
  componentData: ComponentData
  checklistInstanceId: string
  checkId: string
}

export type UpdateComponentFunction = (
  checklistInstanceId: string,
  componentId: string | undefined,
  value: Array<{ subComponentName: string; value: number }> | string | number | undefined,
  conclude: boolean
) => Promise<any>

export interface DispatchProps {
  updateComponent: UpdateComponentFunction
}

export interface OwnProps {
  disabled: boolean
  collapseHandler?: Function
}

export interface OwnState {
  currentValue: string
  loading: boolean
}

export type RadioComponentProps = StateProps & DispatchProps & OwnProps & WithTranslation

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    updateComponent: (checklistInstanceId, componentId, value, conclude) =>
      dispatch(checklistUpdateComponent(checklistInstanceId, componentId, value, conclude)),
  }
}

export class RadioComponent extends React.Component<RadioComponentProps, OwnState> {
  constructor(props: RadioComponentProps) {
    super(props)
    this.state = {
      currentValue: props.componentData.lastValue || props.componentData.defaultValue,
      loading: false,
    }
    this.updateComponent = this.updateComponent.bind(this)
    this.manageCollapsable()
  }

  private manageCollapsable() {
    if (
      this.props.componentData.lastValue !== this.props.componentData.defaultValue &&
      this.props.componentData.optional &&
      this.props.collapseHandler
    ) {
      this.props.collapseHandler()
    }
  }

  private updateComponent(event: any) {
    event.persist()
    this.setState(
      prevState => {
        return {
          ...prevState,
          currentValue: event.target.value,
          loading: true,
        }
      },
      () => {
        this.props
          .updateComponent(this.props.checklistInstanceId, this.props.componentData.id, this.state.currentValue, false)
          .then(() => {
            this.setState(prevState => {
              return {
                ...prevState,
                loading: false,
              }
            })
          })
      }
    )
  }

  public render() {
    return (
      <div className="btn-group btn-group" role="group" aria-label="cl-item-example">
        {this.props.componentData.type.values.map(value => {
          if (value === 'OK') {
            return (
              <button
                type="button"
                className={`
                    btn
                    btn-lg
                    btn-outline-secondary
                    hover-success
                    ${this.state.currentValue === value ? 'active' : ''}
                  `}
                key={this.props.componentData.id + value}
                onClick={this.updateComponent}
                value={value}
                disabled={this.state.loading || this.props.disabled}
              >
                {value}
              </button>
            )
          }
          if (value === 'KO') {
            return (
              <button
                type="button"
                className={`
                    btn
                    btn-lg
                    btn-outline-secondary
                    hover-danger
                    ${this.state.currentValue === value ? 'active' : ''}
                  `}
                key={this.props.componentData.id + value}
                onClick={this.updateComponent}
                value={value}
                disabled={this.state.loading || this.props.disabled}
              >
                {value}
              </button>
            )
          }
          return (
            <button
              type="button"
              className={`
                    btn
                    btn-lg
                    btn-outline-secondary
                    hover-secondary
                    ${this.state.currentValue === value ? 'active' : ''}
                  `}
              key={this.props.componentData.id + value}
              onClick={this.updateComponent}
              value={value}
              disabled={this.state.loading || this.props.disabled}
            >
              {value}
            </button>
          )
        })}
      </div>
    )
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(RadioComponent))
