import { getDataFromObject } from '../../mvlabs-components-fe/functions/helpers'

// tslint:disable-next-line:only-arrow-functions
export const extend = function() {
  const extended = {}
  let deep = false
  let i = 0

  if (typeof arguments[0] === 'boolean') {
    deep = arguments[0]
    i++
  }

  // tslint:disable-next-line:only-arrow-functions
  const merge = function(obj: { [x: string]: any; hasOwnProperty: (arg0: string) => any }) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
          // @ts-ignore
          extended[prop] = extend(true, extended[prop], obj[prop])
        } else {
          extended[prop] = obj[prop]
        }
      }
    }
  }

  for (; i < arguments.length; i++) {
    merge(arguments[i])
  }

  return extended
}

/**
 * if slug contain "[]" then the property will be threat as an array and joined as a string
 * else will be taken the object based the slug
 */
export const getRecursiveObject = <T>(slug: string, data: T, arrayJoin: string = '|\n|'): any => {
  const [arraySection, ...rest] = slug.split('[]')
  const restAsString = rest.join('[]')
  if (rest.length >= 1) {
    const array: any[] = getDataFromObject(arraySection, data)
    const slugArrayContent = restAsString[0] === '.' ? restAsString.slice(1) : restAsString
    return array.map(element => getRecursiveObject(slugArrayContent, element)).join(arrayJoin)
  }

  if (slug === '' && typeof data === 'string') {
    return data
  }

  return getDataFromObject(slug, data)
}

export const isJSON = (str: any): boolean => {
  if (typeof str === 'object') {
    return false
  }

  try {
    return JSON.parse(str) && !!str
  } catch (e) {
    return false
  }
}
