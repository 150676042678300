import { API } from './index'
import { AnyAction, Dispatch } from 'redux'
import { ChecklistModel, ChecklistModelParams } from '../../types/index'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { SortingRule, Filter } from 'react-table'
import { fetchPendingValidations } from './checklistValidation'
import FetchError from "@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError";
import { Value } from '@mv-submodules/inplant-checklist-fe-iblu/types/configuration'

export const checklistIndexIsFetching = (): AnyAction => {
  return {
    type: 'CHECKLIST_INDEX_IS_FETCHING',
  }
}

export const checklistIndexFetchSuccess = (checklists: ChecklistModel[]): AnyAction => {
  return {
    payload: checklists,
    type: 'CHECKLIST_INDEX_FETCH_SUCCESS',
  }
}

export const checklistIndexFetchError = (error: Error): AnyAction => {
  return {
    payload: error,
    type: 'CHECKLIST_INDEX_FETCH_ERROR',
  }
}

export const fetchChecklistIndexNoRedux = (unitSelected?:Value) => (dispatch: Dispatch) => {
  fetchPendingValidations()(dispatch) // update validation counter
  return API().request(`/checklist-models${unitSelected ? `?unit=${unitSelected.id}` : ''}`)
      .then((response: any) => response.map((cl: ChecklistModelParams) => new ChecklistModel(cl)))
      .catch((error: FetchError) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
        throw error
      })
}

export const checklistStartChecklist = (
  checklistModelId: string,
  assetId?: string
): ((dispatch: Dispatch<AnyAction>) => Promise<string>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch(checklistIndexIsFetching())
    try {
      const response = await API().request(`/checklist/start/${checklistModelId}`, {
        method: 'POST',
        headers: assetId ? { 'content-type': 'application/json' } : undefined,
        body: assetId ? JSON.stringify({ assetId }) : undefined,
      })
      return response.checklistInstanceId
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(checklistIndexFetchError(new Error(error)))
      throw error
    }
  }
}

export const checklistIndexPersistTableState = (params: {
  page?: number
  pageSize?: number
  sorted?: SortingRule[]
  filtered?: Filter[]
}): AnyAction => ({
  type: 'CHECKLIST_INDEX_PERSIST_TABLE_STATE',
  payload: params,
})
