import { AnyAction } from 'redux'
import { ChecklistAssetResetHoursStateProps } from '../../types'

const indexInitialState = {
  assets: [],
  error: null,
  fetching: false,
}

export const checklistAssetResetHours = (
  state: ChecklistAssetResetHoursStateProps = indexInitialState,
  action: AnyAction
) => {
  switch (action.type) {
    case 'CHECKLIST_ASSET_RESET_HOURS_IS_FETCHING':
      return {
        ...state,
        error: null,
        fetching: true,
      }

    case 'CHECKLIST_ASSET_RESET_HOURS_FETCH_SUCCESS':
      return {
        ...state,
        assets: action.payload,
        error: null,
        fetching: false,
      }
    case 'CHECKLIST_ASSET_RESET_HOURS_FETCH_ERROR':
      return {
        ...state,
        error: action.payload,
        fetching: false,
      }
    case 'CHECKLIST_ASSET_RESET_HOURS_SAVE_SUCCESS':
      return {
        ...state,
        assets: state.assets.map(
          asset => (asset.id === action.assetId ? Object.assign(asset, { hours: action.hours }) : asset)
        ),
      }
    default:
      return state
  }
}
