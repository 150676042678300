import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { printChecklist, printDaySummary } from '../../../../redux/actions'
import 'react-datepicker/dist/react-datepicker.css'
import '../../../../styles/_index.scss'
import { ChecklistAnomaly, ChecklistSummaryInstance } from '../../../../types'
import { Filters, PageHeader, PrintButton, Table, TableRowButtonGroup } from '@mv-submodules/inplant-components-fe'
import MainPageContent from '@mv-submodules/inplant-components-fe/ui/components/Layout/MainPageContent'
import { FilterComponent } from '@mv-submodules/inplant-components-fe/ui/components/Filters/Filters'
import moment from 'moment'
import { fetchDaySummaryNoRedux } from '@mv-submodules/inplant-checklist-fe-iblu/redux/actions/checklistSummary'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import { generateGroupedChecklist } from '../../../../functions/generateGrouppedChecklist'
import { Flex } from '../../../../../mvlabs-components-fe/ui/components'
import { AlignItems } from '../../../../../inplant-components-fe/ui/components/MVFlex/Flex'
import { generateSpace, SpaceType } from '../../../../../inplant-components-fe/mvfunctions/bootstrapUtility'

export interface StateProps {
  dateSummary: string
}

export interface DispatchProps {
  fetchDaySummary: (date: string) => Promise<any>
  printChecklist: (checklistId: string) => Promise<Blob | null>
}

export interface OwnProps extends RouteComponentProps<any> {
  // t: TranslationFunction
}

export interface OwnState {
  printing: { [checklistId: string]: boolean }
  printingGeneral: boolean
  titleFilter: string
  checklistsDaySummary: ChecklistSummaryInstance[]
  isFetching: boolean
  fetchErrors: boolean
  openedChecklist: string[]
}

export type Props = StateProps & DispatchProps & OwnProps & WithTranslation

const mapStateToProps = (state: any) => ({
  checklistsDaySummary: state.checklist.summary.checklistsDaySummary,
  fetchingDaySummary: state.checklist.summary.fetchingDaySummary,
  errorFetchDaySummary: state.checklist.summary.errorFetchDaySummary,
  dateSummary: state.checklist.summary.dateSummary,
})

const mapDispatchToProps = (
  dispatch: Function
): {
  printChecklist: (checklistId: string) => Promise<Blob | null>
  fetchDaySummary: (date: string) => Promise<any>
} => ({
  fetchDaySummary: (date: string) => dispatch(fetchDaySummaryNoRedux(date)),
  printChecklist: checklistId => dispatch(printChecklist(checklistId)),
})

export class ChecklistDaySummary extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      printing: {},
      titleFilter: '',
      printingGeneral: false,
      checklistsDaySummary: [],
      isFetching: false,
      fetchErrors: false,
      openedChecklist: []
    }
    this.fetchData = this.fetchData.bind(this)
    this.handleChecklistClick = this.handleChecklistClick.bind(this)
    this.handlePrintChecklist = this.handlePrintChecklist.bind(this)
    this.printDaySummary = this.printDaySummary.bind(this)
  }

  public componentDidMount(): void {
    this.fetchData(this.props.dateSummary)
  }

  public fetchData(date: string) {
    if (!this.state.isFetching) {
      this.setState({ isFetching: true, fetchErrors: false })
      this.props
        .fetchDaySummary(date)
        .then(checklistsDaySummary => this.setState({ checklistsDaySummary }))
        .catch((error: FetchError) => {
          this.setState({ fetchErrors: true })
        })
        .finally(() => this.setState({ isFetching: false }))
    }
  }

  public handleChecklistClick(checklist: ChecklistSummaryInstance) {
    return this.props.history.push(`/checklist/summary/${checklist.checklistId}`)
  }

  private async handlePrintChecklist(checklist: ChecklistSummaryInstance) {
    this.setState(prevState => {
      const printing = prevState.printing
      printing[checklist.checklistId!] = true
      return {
        ...prevState,
        printing,
      }
    })
    try {
      const blob = await this.props.printChecklist(checklist.checklistId!)
      const objectURL = URL.createObjectURL(blob)
      const link = document.createElement('a')
      const date = checklist.date ? mvDate.getDateFromStringWithFormatting(checklist.date!, 'yyyy-MM-dd HH:mm:ss') : ''
      const title = checklist.title
      const asset = checklist.asset ? `[${checklist.asset!.code}] ${checklist.asset!.description}` : null
      link.href = objectURL
      link.download = `${date} - ${title}${asset ? ` - ${asset}` : ''}.pdf`
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      link.remove()
      this.setState(prevState => {
        const printing = prevState.printing
        printing[checklist.checklistId!] = false
        return {
          ...prevState,
          printing,
        }
      })
    } catch (error) {
      this.setState(prevState => ({ ...prevState, error }))
      this.setState(prevState => {
        const printing = prevState.printing
        printing[checklist.checklistId!] = false
        return {
          ...prevState,
          printing,
        }
      })
    }
  }

  private async printDaySummary() {
    this.setState({ printingGeneral: true })
    try {
      const blob = await printDaySummary(this.props.dateSummary, this.state.openedChecklist)
      const objectURL = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = objectURL
      link.download = `${this.props.t('checklist.todaySummary.title')} ${this.props.dateSummary}.pdf`
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (error) {
      this.setState(prevState => ({ ...prevState, error }))
    } finally {
      this.setState({ printingGeneral: false })
    }
  }

  public render() {
    const { isFetching } = this.state
    const filters: FilterComponent[] = [
      {
        id: 'checklist-title',
        name: 'title',
        value: this.state.titleFilter,
        label: this.props.t('checklist.yesterdaySummary.table.title'),
        type: 'SearchInput',
        throttle: 0,
        onChange: (name, value) => this.setState({ titleFilter: (value && (value as string)) || '' }),
        disabled: isFetching || this.state.checklistsDaySummary.length === 0,
      },
      {
        id: 'checklist-date',
        name: 'date',
        value: this.props.dateSummary,
        label: this.props.t('checklist.yesterdaySummary.table.date'),
        disabled: isFetching || this.state.checklistsDaySummary.length === 0,
        type: 'Date',
        notClearable: true,
        max: moment().format('YYYY-MM-DD'),
        onChange: (name, value) => this.fetchData(value ? moment(value).format('YYYY-MM-DD') : this.props.dateSummary),
      },
    ]
    const checklistsDaySummary = this.state.checklistsDaySummary.filter(value =>
      value.title
        .concat(value.asset ? value.asset.code + value.asset.description : '')
        .toLowerCase()
        .replace(' ', '')
        .includes(this.state.titleFilter)
    )
    const aggregatedData = generateGroupedChecklist(checklistsDaySummary, this.state.openedChecklist)
    return (
      <React.Fragment>
        <PageHeader
          title={this.props.t('checklist.yesterdaySummary.title')}
          rightButtons={
            <>
              <PrintButton
                onClick={this.printDaySummary}
                isPrinting={this.state.printingGeneral}
                label={this.props.t('checklist.edit.print')}
                additionalClass="ml-3"
              />
            </>
          }
        />
        <MainPageContent>
          <Filters fields={filters} />
          <Table
            data={aggregatedData.filter(v => v.openStatus)}
            isFetching={isFetching}
            manualIsFetching={true}
            // onFetchData={this.fetchData}
            //  className="-striped -highlight mv4iot-fe-checklist-summary-table"
            showPagination={true}
            pageSize={100}
            //  sortable={false}
            noDataText={this.props.t('checklist.archive.table.noData')}
            // previousText={this.props.t('checklist.archive.table.previous')}
            // nextText={this.props.t('checklist.archive.table.next')}
            // loadingText={this.props.t('checklist.archive.table.loading')}
            pageText={this.props.t('checklist.archive.table.page')}
            ofText={this.props.t('checklist.archive.table.of')}
            rowsText={this.props.t('checklist.archive.table.rows')}
            showPaginationTop={true}
            showPaginationBottom={true}
            // getTheadFilterThProps={() => ({style: {overflow: 'inherit'}})}
            getTrProps={(state: any, rowInfo: any, column: any) => {
              return {
                style: {
                  background: rowInfo && rowInfo.original.color,
                  border: '2px solid white',
                },
              }
            }}
            columns={[
              {
                Header: this.props.t('checklist.yesterdaySummary.table.title'),
                accessor: 'title',
                sortable: true,
                minWidth: 170,
                Cell: data => (
                  <div>
                    <Flex alignItems={AlignItems.center} className={this.state.openedChecklist.includes(data.original.title) && data.original.type === 'data' ? generateSpace(SpaceType.margin, {left: 5}) : ''}>
                    {data.original.type === 'grouped' &&
                            <TableRowButtonGroup
                              buttons={[
                                {
                                  icon: this.state.openedChecklist.includes(data.original.title)
                                    ? 'chevron-up'
                                    : 'chevron-down',
                                  onClick: () => {
                                    if (this.state.openedChecklist.includes(data.original.title)) {
                                      this.setState(prev => ({
                                        openedChecklist: prev.openedChecklist.filter(x => x !== data.original.title),
                                      }))
                                      return
                                    }
                                    this.setState(prev => ({
                                      openedChecklist: [...prev.openedChecklist, data.original.title],
                                    }))
                                  },
                                  variant: 'secondary-alternate',
                                },
                              ]}
                            />
                          }
                      <p>
                      {this.state.openedChecklist.includes(data.original.title) && data.original.type === 'data' ? (
                            data.original.title
                          ) : (
                            <>
                              <b>{data.original.title}</b> 
                              {data.original.type === 'grouped' &&
                                this.props.t(`checklist.todaySummary.table.asset`, {
                                  assetNumber: data.original.numberOfAsset
                                })
                              }
                            </>
                          )}
                        {`${
                          data.original.asset
                          ? `[${data.original.asset.code}] ${data.original.asset.positionCode && data.original.asset.positionCode !== data.original.asset.code ? `[${data.original.asset.positionCode}]` : ``} ${data.original.asset.description}`
                          : ''
                        }`}
                      </p>
                    </Flex>
                    {data.original.anomalies && data.original.anomalies.length > 0 ? (
                      <div>
                        <span>Anomalie:</span>
                        {data.original.anomalies.map((value: ChecklistAnomaly, index: number) => (
                          <span key={`anomalie_${index}_${value.description}`}>
                            <br />
                            {value.description} <b>{value.note ? `[${value.note}]` : ''}</b>
                          </span>
                        ))}
                      </div>
                    ) : null}
                  </div>
                ),
              },
              {
                Header: this.props.t('checklist.yesterdaySummary.table.status'),
                width: 130,
                accessor: 'status',
                sortable: true,
                Cell: data => (
                  <div className="text-left">
                    {
                      data.original.status && <p>{this.props.t(`checklist.yesterdaySummary.status.${data.original.status}`)}</p>
                    }
                  </div>
                ),
              },
              {
                Header: this.props.t('checklist.yesterdaySummary.table.user'),
                accessor: 'user',
                sortable: true,
                width: 130,
                Cell: data => (
                  <div className="text-left">
                    <p>{data.original.user ? data.original.user.displayName : ''}</p>
                  </div>
                ),
              },
              {
                Header: this.props.t('checklist.yesterdaySummary.table.date'),
                width: 150,
                Cell: data => (
                  <div className="text-left">
                    <p>
                      {data.original.date
                        ? mvDate.getDateFromStringWithFormatting(data.original.date, 'dd/MM/yyyy HH:mm')
                        : ''}
                    </p>
                  </div>
                ),
              },
              {
                sortable: false,
                width: 130,
                Cell: data =>
                  data && data.original && data.original.checklistId ? (
                    <TableRowButtonGroup
                      buttons={[
                        {
                          icon: 'eye',
                          onClick: () => this.handleChecklistClick(data.original),
                          variant: 'secondary',
                        },
                        {
                          printButton: true,
                          onClick: () => this.handlePrintChecklist(data.original),
                          isLoading: this.state.printing[data.original.checklistId],
                        },
                      ]}
                    />
                  ) : null,
              },
            ]}
          />
        </MainPageContent>
      </React.Fragment>
    )
  }
}

export default withRouter<any, any>(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChecklistDaySummary))
)
