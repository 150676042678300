import * as React from 'react'
import {connect} from 'react-redux'
import {withTranslation, WithTranslation} from 'react-i18next'
import {RouteComponentProps, withRouter} from 'react-router'
import {Asset} from '../../../../types'
import {
  checklistAssetResetHours,
  checklistAssetResetHoursFetchIndexNoRedux,
} from '../../../../redux/actions/checklistAssetResetHours'
import ResetHoursModal from './ResetHoursModal'
import {Filters, PageHeader, Table, TableRowButtonGroup} from "@mv-submodules/inplant-components-fe";
import MainPageContent from "@mv-submodules/inplant-components-fe/ui/components/Layout/MainPageContent";
import {FilterComponent} from "@mv-submodules/inplant-components-fe/ui/components/Filters/Filters";

export interface DispatchProps {
  fetchList: () => Promise<any>
  resetHours: (assetId: string, hours: number) => Promise<any>
}

export interface OwnProps extends RouteComponentProps<any> {
}

export interface OwnState {
  resetHoursShowModal: boolean
  asset: Asset | null
  assets: Asset[]
  isFetching: boolean
  fetchErrors: boolean,
  filters: {
    code?: string,
    description?: string
  }
}

export type Props = DispatchProps & OwnProps & WithTranslation

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  fetchList: () => dispatch(checklistAssetResetHoursFetchIndexNoRedux()),
  resetHours: (assetId: string, hours: number) => dispatch(checklistAssetResetHours(assetId, hours)),
})

export class ResetHoursComponent extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      resetHoursShowModal: false,
      asset: null,
      isFetching: false,
      fetchErrors: false,
      assets: [],
      filters: {}
    }
    this.fetchData = this.fetchData.bind(this)
    this.handleChecklistClick = this.handleChecklistClick.bind(this)
    this.handleResetHoursModalClose = this.handleResetHoursModalClose.bind(this)
    this.compareString = this.compareString.bind(this)
  }

  public componentDidMount() {
    this.fetchData()
  }

  public fetchData() {
    if (!this.state.isFetching) {
      this.setState({isFetching: true, fetchErrors: false})
      this.props.fetchList()
        .then((assets) => this.setState({assets}))
        .catch((error) => this.setState({fetchErrors: true}))
        .finally(() => this.setState({isFetching: false}))
    }
  }

  private handleChecklistClick(asset: Asset) {
    this.setState({
      resetHoursShowModal: true,
      asset,
    })
  }

  public handleResetHoursModalClose() {
    this.setState({
      resetHoursShowModal: false,
      asset: null,
    })
  }

  public compareString(value?: string, compareValue?: string) {
    if (!value) {
      return false
    }
    if (!compareValue) {
      return true
    }
    return value.toUpperCase().replace(" ", "").includes(compareValue.toUpperCase().replace(" ", ""))
  }

  public render() {
    const colums = [
      {
        Header: this.props.t('checklist.resetHours.table.code'),
        accessor: 'code',
      },
      {
        Header: this.props.t('checklist.resetHours.table.asset'),
        accessor: 'description',
        minWidth: 500,
      },
      {
        Header: '',
        sortable: false,
        filterable: false,
        Cell: (data: any) => (
          <TableRowButtonGroup
            buttons={[{
              variant: 'secondary',
              label: this.props.t('checklist.resetHours.table.reset'),
              onClick: () => this.handleChecklistClick(data.original)
            }]}
          />
        ),
      },
    ]

    const {isFetching, assets, filters} = this.state
    const filtersElement: FilterComponent[] = [{
      disabled: isFetching || !assets,
      name: 'code',
      id: 'asset-code',
      type: 'SearchInput',
      label: this.props.t('checklist.resetHours.table.code'),
      value: filters.code || null,
      onChange: (name, value) => this.setState({
        filters: {
          ...this.state.filters,
          [name]: value
        }
      }),
    }, {
      disabled: isFetching || !assets,
      name: 'description',
      id: 'asset-description',
      type: 'SearchInput',
      label: this.props.t('checklist.resetHours.table.asset'),
      value: filters.description || null,
      onChange: (name, value) => this.setState({
        filters: {
          ...this.state.filters,
          [name]: value
        }
      }),
    }]

    const elements = assets.filter((asset) => (
      this.compareString(asset.code, this.state.filters.code) &&
      this.compareString(asset.description, this.state.filters.description)
    ))

    return (
      <div className="mv4iot-fe-checklist-reset-hours-table">
        <PageHeader title={this.props.t('checklist.resetHours.title')}/>
        <MainPageContent>
          <Filters fields={filtersElement}/>
          <Table
            columns={colums}
            isFetching={isFetching}
            manualIsFetching={true}
            showPaginationTop={true}
            showPaginationBottom={true}
            data={elements}
            noDataText={this.props.t('checklist.resetHours.table.noData')}
            pageText={this.props.t('checklist.resetHours.table.page')}
            ofText={this.props.t('checklist.resetHours.table.of')}
            rowsText={this.props.t('checklist.resetHours.table.rows')}
          />
        </MainPageContent>
        <ResetHoursModal
          show={this.state.resetHoursShowModal}
          resetHours={this.props.resetHours}
          asset={this.state.asset!}
          onClose={this.handleResetHoursModalClose}
        />
      </div>
    )
  }
}

export default withRouter<any, any>(
  connect(
    undefined,
    mapDispatchToProps
  )(withTranslation()(ResetHoursComponent))
)
