// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- MODULE --------------------------------------
import Alert from './Alert'
import { IFlexItem } from '../Flex/FlexItem'
import { useComponentsTranslation } from '../../../services/translation'

// * ------------------------------------------
// * Helpers Component that pre-fill some props
// * ------------------------------------------

interface Props {
  error?: string
}

const AlertErrorData = (props: Props & IFlexItem) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- HOOKs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const translation = useComponentsTranslation()

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const { error, ...flexProps } = props
  const base = 'components.alertErrorData'

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  return <Alert {...flexProps} variant={'warning'} text={error || translation.t(`${base}.defaultMessage`)} />
}

export default AlertErrorData
