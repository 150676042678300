import * as React from 'react'
import Magnifier from 'react-magnifier'
import withObserver, { WithObserverProps } from './withObserver'

interface Props {
  zoom?: number
  zoomSize?: number
}

// https://github.com/samuelmeuli/react-magnifier
const ZoomableImage = (props: Props & WithObserverProps) => (
  <Magnifier
    className={props.className}
    src={props.src}
    zoomFactor={props.zoom}
    mgWidth={props.zoomSize}
    mgHeight={props.zoomSize}
  />
)

export default withObserver<any>(ZoomableImage)
