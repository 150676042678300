// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- MODULE --------------------------------------
import Alert from '../../../Alert/Alert'
import Flex, { AlignItems, Direction, JustifyContent } from '../../../Flex/Flex'
import IconComponent from '../../../Icon/Icon'
import TableBadge from './TableBadge'
import TableFilters, { initFiltersInState } from './TableFilters'
import TableNavigation from './TableNavigation'
import Title from '../../../Text/Title'
import createResizeColumn from '../functions/resizeColumn'
import {
  AllPropsTable,
  AllPropsTableComponent,
  Column,
  ContextualInformationPositions,
  DispatchProps,
  ListPagination,
  PaginationTypes,
  State,
  TableState,
} from '../types/table'
import { CheckboxItem } from '../../../Input/Checkbox'
import { ComponentsReducers } from '../../../../../redux/reducers'
import { fetchTableData, setColumnSize, setFilters, setPagination } from '../redux/actions'
import { instanceOf } from '../../../../../functions/helpers/objectHelper'
import { useComponentsTranslation } from '../../../../../services/translation'
import ButtonFactory from '../../../Buttons/ButtonFactory'
import { useGetState, useComponentDispatch } from '../../../../../services/stateManager'

/**
 * @deprecated instead use tableHelpers!
 */
const Table = <T, K = any>(props: AllPropsTable<T, K>) => {
  const translation = useComponentsTranslation()
  const tableState = useGetState<{ components: ComponentsReducers }>(state => state.components.tableReducer)
  const dispatch = useComponentDispatch()

  const anyProps: AllPropsTable<any> = props
  return (
    <TableReactComponentConnected
      {...anyProps}
      {...tableState}
      {...mapDispatchToProps(dispatch)}
      translations={translation}
    />
  )
}

class TableReactComponent<T, K> extends React.Component<AllPropsTableComponent<T, K>, State<T, K>> {
  // * ------------------------------------------------------------------------
  // * -------------------------------- INIT ----------------------------------
  // * ------------------------------------------------------------------------
  private DELAY_ON_CHANGE_PAGE = 500
  private abortController = new AbortController()
  private BASE_TRAD = 'components.table'
  private CN = 'mv-table'
  private CN_INFO = `${this.CN}__info`
  private CN_CHECK = `${this.CN}__checkbox`
  private CN_BUTTON = `${this.CN}__action`
  private CN_BADGE = `${this.CN}__badge`
  private persistenceSlug = window.location.pathname + (this.props.id || '')

  constructor(props: AllPropsTableComponent<T>) {
    super(props)
    const pagination = (props.pagination && props.pagination[this.persistenceSlug]) || {
      limit: 20,
      offset: 0,
      count: 0,
      sort: '',
    }
    let sort: '' | 'desc' | 'asc' = ''
    if (pagination?.sort) {
      sort = pagination.sort[0] === '-' ? 'desc' : 'asc'
    }

    let applyFiltersOn = props.applyFiltersOn || 'BE'
    if (!props.applyFiltersOn) {
      if (this.props.kind === PaginationTypes.noPagination) {
        applyFiltersOn = 'FE'
      } else if (this.props.kind === PaginationTypes.withPagination) {
        applyFiltersOn = 'BE'
      }
    }

    const listFilters = initFiltersInState(props.listFilters?.[this.persistenceSlug], props.filters)

    this.state = {
      showModalAdvanceFilters: false,
      data: undefined,
      dataUnchanged: undefined,
      tableState: TableState.loadInProgress,
      pagination,
      timer: null,
      sort,
      dataSelected: [],
      listFilters: listFilters || {},
      columnSize: (props.columnSize && props.columnSize[this.persistenceSlug]) || {},
      applyFiltersOn,
      dateReturnedFromFetchRequest: undefined,
    }

    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)
    this.changeSort = this.changeSort.bind(this)
  }

  // * ------------------------------------------------------------------------
  // * -------------------------- STATE MANAGEMENT ----------------------------
  // * ------------------------------------------------------------------------
  public componentDidMount() {
    this.fetchRequest()
  }

  public componentWillUnmount() {
    this.abortController.abort()
  }

  public componentDidUpdate(
    prevProps: Readonly<AllPropsTableComponent<T>>,
    prevState: Readonly<State<T, K>>,
    snapshot?: any
  ) {
    if (prevProps.forceRefresh !== this.props.forceRefresh) {
      this.fetchRequest(undefined, true)
    }
    if (prevProps.request !== this.props.request) {
      this.fetchRequest()
    }
    if (prevProps.localTable?.data !== this.props.localTable?.data) {
      this.fetchRequest()
    }
  }

  // * ------------------------------------------------------------------------
  // * ---------------------- COLUMN RESIZING ---------------------------------
  // * ------------------------------------------------------------------------
  private createResizeColumn = () => {
    createResizeColumn({
      tableId: this.CN,
      columnSize: this.state.columnSize,
      callback: newSizes =>
        this.setState({ columnSize: { ...this.state.columnSize, ...newSizes } }, () => {
          this.updateColumnSize()
        }),
    })
  }

  // * ------------------------------------------------------------------------
  // * ---------------------------- LOGIC -------------------------------------
  // * ------------------------------------------------------------------------
  private fetchRequest = async (previousPagination?: ListPagination, forceRefresh?: boolean) => {
    if (this.props.localTable) {
      const { data } = this.props.localTable
      this.setState(
        {
          data,
          tableState: TableState.loadSuccess,
          pagination: { ...this.state.pagination, count: data.length },
          dataUnchanged: data,
        },
        () => {
          if (this.state.applyFiltersOn === 'FE') {
            this.generateFilteredFEData()
          }
          this.createResizeColumn()
          this.updateStateManagement()
        }
      )
      return
    }

    if (this.state.tableState === TableState.loadInProgress) {
      this.abortController.abort()
      this.abortController = new AbortController()
    }

    // condition in this way is more readable
    if (forceRefresh && this.props.silentForceRefresh) {
      // silent refresh
    } else {
      this.setState({ tableState: TableState.loadInProgress, dataSelected: [] })
    }

    this.props
      .fetchTableData(
        this.props.fetchWrapperInstance,
        this.props.request,
        this.props.kind === PaginationTypes.withPagination ? this.state.pagination : undefined,
        this.state.listFilters,
        this.props.filters,
        this.props.parseResult,
        this.abortController
      )
      .then(fetchTableDataResult => {
        const { dateReturnedFromFetchRequest, parsedData } = fetchTableDataResult
        if (
          instanceOf<{ result: T[]; pagination: ListPagination }>(parsedData, 'pagination') &&
          this.props.kind === PaginationTypes.withPagination
        ) {
          this.setState(
            {
              dateReturnedFromFetchRequest,
              data: parsedData.result,
              tableState: TableState.loadSuccess,
              pagination: { ...this.state.pagination, count: parsedData.pagination.count },
            },
            () => {
              this.createResizeColumn()
              this.updateStateManagement()
            }
          )
        } else if (
          instanceOf<{ result: T[] }>(parsedData, 'result') &&
          this.props.kind === PaginationTypes.noPagination
        ) {
          this.setState(
            {
              dateReturnedFromFetchRequest,
              data: parsedData.result,
              tableState: TableState.loadSuccess,
              pagination: { ...this.state.pagination, count: parsedData.result.length },
              dataUnchanged: parsedData.result,
            },
            () => {
              if (this.state.applyFiltersOn === 'FE') {
                this.generateFilteredFEData()
              }
              this.createResizeColumn()
              this.updateStateManagement()
            }
          )
        } else {
          console.warn('Error expected fetch with pagination BE/FE but found pagination FE/BE') // tslint:disable-line
        }
      })
      .catch(error => {
        // if is aborted the component will not propagate error
        if (!(error.name && error.name === 'AbortError')) {
          this.props.onCatchError(error)
          if (previousPagination !== undefined) {
            this.setState({ pagination: previousPagination }, () => {
              this.updateStateManagement()
            })
          } else {
            this.setState({ tableState: TableState.loadFailure })
          }
        }
      })
  }

  private changePageWithDebounce = (page: number) => {
    const numberOfPages = this.numberOfPages
    const { timer } = this.state
    if (timer !== null) {
      clearTimeout(timer)
      this.setState({ timer: null })
    }
    if (page < numberOfPages && page >= 0) {
      const pagination = {
        pagination: {
          ...this.state.pagination,
          offset: this.state.pagination.limit * page,
        },
      }
      this.setState(pagination, () => {
        this.updateStateManagement()
        this.setState({
          timer: setTimeout(() => {
            this.changePage(page)
          }, this.DELAY_ON_CHANGE_PAGE),
        })
      })
    }
  }

  private nextPage = () => {
    const pageNumber = this.page
    this.changePage(pageNumber + 1)
  }

  private previousPage = () => {
    const pageNumber = this.page
    this.changePage(pageNumber - 1)
  }

  private changePage = (page: number) => {
    const previousPagination = this.state.pagination
    const newPagination = {
      ...this.state.pagination,
      offset: this.state.pagination.limit * page,
    }
    this.setState({ pagination: newPagination, dataSelected: [] }, () => {
      this.updateStateManagement()
      if (this.props.kind === PaginationTypes.withPagination) {
        this.fetchRequest(previousPagination)
      }
    })
  }

  private changePageSize = (size: number) => {
    const oldPagination = this.state.pagination
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          offset: 0,
          limit: size,
        },
      },
      () => {
        this.updateStateManagement()
        if (this.props.kind === PaginationTypes.withPagination) {
          this.fetchRequest(oldPagination)
        }
      }
    )
  }

  private changeSort = (filter: string) => {
    const oldPagination = this.state.pagination
    let paginationSort = filter
    let sort: '' | 'asc' | 'desc' = ''
    if (this.state.sort === 'asc') {
      paginationSort = `-${paginationSort}`
      sort = 'desc'
    } else if (this.state.sort === 'desc') {
      paginationSort = ''
      sort = ''
    } else {
      paginationSort = `${paginationSort}`
      sort = 'asc'
    }
    this.setState(
      {
        sort,
        pagination: {
          ...this.state.pagination,
          sort: paginationSort,
        },
      },
      () => {
        this.updateStateManagement()
        if (this.props.kind === PaginationTypes.withPagination) {
          this.fetchRequest(oldPagination)
        } else {
          this.sortFEData()
        }
      }
    )
  }

  private changeFilter = (key: string, value: string | string[] | null | undefined) => {
    const newFilters = { ...this.state.listFilters }
    newFilters[key] = value
    this.setState(
      {
        listFilters: newFilters,
        pagination: {
          ...this.state.pagination,
          offset: 0,
        },
      },
      () => {
        this.updateStateManagement()
        if (this.state.applyFiltersOn === 'BE') {
          this.fetchRequest()
        }
        if (this.state.applyFiltersOn === 'FE') {
          this.generateFilteredFEData()
        }
      }
    )
  }

  private updateStateManagement = () => {
    this.updatePaginationStateManagement()
    this.updateFiltersStateManagement()
  }

  private updatePaginationStateManagement = () => {
    this.props.setPagination(this.persistenceSlug, this.state.pagination)
  }

  private updateFiltersStateManagement = () => {
    this.props.setFilters(this.persistenceSlug, this.state.listFilters)
  }

  private updateColumnSize = () => {
    this.props.setColumnSize(this.persistenceSlug, this.state.columnSize)
  }

  // * ------------------------------------------------------------------------
  // * ---------------------- FILTERS FE LOGIC --------------------------------
  // * ------------------------------------------------------------------------
  private generateFilteredFEData = () => {
    const { dataUnchanged, listFilters } = this.state
    let dataFiltered = dataUnchanged
    if (dataUnchanged) {
      dataFiltered =  dataUnchanged.filter((row: { [key: string]: any }) => {
        return Object.entries(listFilters)
          .map(([, value]) => {
            return Object.keys(row)
              .map(k => {
                return row[k]
                  ?.toString()
                  .toLowerCase()
                  .includes(value?.toString().toLowerCase())
              })
              .reduce((acc, curr) => acc || curr)
          })
          .reduce((acc, curr) => acc && curr, true)
      }).sort((a,b) => 1)
    }
    this.setState({ data: dataFiltered }, () => {
      this.sortFEData()
    })
  }

  private sortFEData = () => {
    const { data, pagination } = this.state
    let dataSorted = data
    if (dataSorted) {
      dataSorted = data?.sort((a: { [key: string]: any }, b: { [key: string]: any }) => {
        if (this.state.sort === 'asc') {
          if (typeof a[pagination.sort] === 'string') {
            return a[pagination.sort] > b[pagination.sort] ? -1 : 1
          } else {
            return 1
          }
        } else {
          if (typeof a[pagination.sort.substr(1)] === 'string') {
            return a[pagination.sort.substr(1)] > b[pagination.sort.substr(1)] ? 1 : -1
          } else {
            return 1
          }
        }
      })
    }
    this.setState({ data: dataSorted })
  }

  // * ------------------------------------------------------------------------
  // * ---------------------------- RENDERs -----------------------------------
  // * ------------------------------------------------------------------------

  public render() {
    return (
      <Flex
        spaceSize={'md'}
        direction={Direction.column}
        alignItems={AlignItems.stretch}
        justifyContent={JustifyContent.start}
        className={'mv-table-container'}
      >
        {this.renderTableState()}
      </Flex>
    )
  }

  public renderTableState = () => {
    const { tableState } = this.state

    const filters = (
      <TableFilters
        key={1}
        changeFilter={this.changeFilter}
        filters={this.props.filters}
        advancedFilters={this.props.advanceFilters}
        listFilters={this.state.listFilters}
        translation={{ t: this.props.translations.t, base: this.BASE_TRAD }}
      />
    )

    switch (tableState) {
      case TableState.loadSuccess:
        return this.renderTableSuccess(filters)
      case TableState.loadInProgress:
        return this.renderTableLoading(filters)
      case TableState.loadFailure:
        return this.renderTableError(filters)
    }
  }

  private renderTableSkeleton = (
    tHeadContent: React.ReactNode = (
      <tr>
        <th className={`${this.CN}__heading`} />
      </tr>
    ),
    tBodyContent: React.ReactNode
  ) => {
    return (
      <div className={`${this.CN}-wrapper`}>
        <table className={this.tableName} id={this.CN}>
          <thead>{tHeadContent}</thead>
          <tbody>{tBodyContent}</tbody>
        </table>
      </div>
    )
  }

  private renderTableLoading = (Filters: JSX.Element) => {
    return (
      <>
        {Filters}
        {this.renderTableNavigation()}
        {this.renderTableSkeleton(
          null,
          <tr className={`${this.CN}-row__info`}>
            <td className={this.CN_INFO}>
              <IconComponent className={'mv-spinner'} size={'2x'} icon={'circle-notch'} spin={true} />{' '}
            </td>
          </tr>
        )}

        {this.renderTableNavigation()}
      </>
    )
  }

  private renderTableError = (Filters: JSX.Element) => {
    return (
      <>
        {Filters}
        {this.renderTableNavigation()}
        {this.renderTableSkeleton(
          null,
          <tr className={`${this.CN}-row__info`}>
            <td className={this.CN_INFO}>
              <Alert variant={'warning'} text={this.props.translations.t(`${this.BASE_TRAD}.messages.error`)} />
            </td>
          </tr>
        )}
        {this.renderTableNavigation()}
      </>
    )
  }

  private renderTableSuccess = (Filters: JSX.Element) => {
    const data = this.renderData()
    return (
      <>
        {this.renderContextualInformation('above-filter')}
        {Filters}
        {this.renderContextualInformation('under-filter')}
        {this.renderTableNavigation()}
        {((!data || data?.length === 0) &&
          this.renderTableSkeleton(
            null,
            <tr className={`${this.CN}-row__info`}>
              <td className={this.CN_INFO}>
                <Alert variant={'warning'} text={this.props.translations.t(`${this.BASE_TRAD}.messages.noData`)} />
              </td>
            </tr>
          )) ||
          this.renderTableSkeleton(this.renderColumns(), data)}

        {this.renderTableNavigation()}
      </>
    )
  }

  private renderContextualInformation = (position: ContextualInformationPositions) => {
    if (this.props.contextualInformation?.position === position && this.state.dateReturnedFromFetchRequest) {
      return this.props.contextualInformation.content(this.state.dateReturnedFromFetchRequest)
    }
    return null
  }

  private renderTableNavigation = () => {
    return (
      <TableNavigation
        batchActions={this.props.batchActions}
        changePageSize={this.changePageSize}
        changePageWithDebounce={this.changePageWithDebounce}
        data={this.state.data}
        dataSelected={this.state.dataSelected}
        nextPage={this.nextPage}
        numberOfPages={this.numberOfPages}
        page={this.page}
        pagination={this.state.pagination}
        previousPage={this.previousPage}
        tableState={this.state.tableState}
        translation={{ t: this.props.translations.t, base: this.BASE_TRAD }}
      />
    )
  }

  private renderColumns = () => {
    const nm = `${this.CN}__heading`
    const columns: React.ReactNode[] = []

    // * Badges
    if (this.props.badges) {
      columns.push(
        <th scope={'col'} className={this.CN_BADGE} key={0}>
          <div className={`${nm}`} />
        </th>
      )
    }

    // * Checks
    if (this.props.batchActions) {
      columns.push(
        <th className={this.CN_CHECK} scope={'col'} key={1}>
          <div className={`${nm}`}>
            <CheckboxItem
              value={'value'}
              initialSelected={this.state.data?.length === this.state.dataSelected.length}
              onChange={checked => {
                if (this.state.data) {
                  if (checked) {
                    const allSelected = this.state.data?.map((v, index) => ({ id: index.toString(), data: v }))
                    this.setState({ dataSelected: allSelected })
                  } else {
                    this.setState({ dataSelected: [] })
                  }
                }
              }}
            />
          </div>
        </th>
      )
    }

    // * Headers for Data

    this.props.columns.forEach((column: Column<T>, index) => {
      columns.push(
        <th
          className={this.props.columns.length - 1 === index && this.props.buttons === undefined ? '' : 'can-resize'}
          style={{ position: 'relative' }}
          scope={'col'}
          key={index + 2}
          id={(index + 2).toString()}
        >
          <div className={`${nm}`}>
            <Flex
              justifyContent={JustifyContent.center}
              direction={Direction.row}
              onClick={
                column.isSortable
                  ? () => {
                      this.changeSort(column.dataColumnId)
                    }
                  : undefined
              }
            >
              {(column.isSortable &&
                (this.state.sort === 'asc' && this.state.pagination.sort === column.dataColumnId ? (
                  <IconComponent className={'mv-sorted'} icon={'arrow-up'} />
                ) : this.state.sort === 'desc' && this.state.pagination.sort.substr(1) === column.dataColumnId ? (
                  <IconComponent className={'mv-sorted'} icon={'arrow-down'} />
                ) : (
                  <IconComponent icon={'sort'} />
                ))) ||
                null}
              <Title level={6} title={column.name?.toUpperCase()} />
            </Flex>
          </div>
        </th>
      )
    })

    // * Buttons
    if (this.props.buttons) {
      columns.push(
        <th className={this.CN_BUTTON} scope={'col'} key={0.1}>
          <div className={`${nm}`} />
        </th>
      )
    }

    return <tr>{columns}</tr>
  }

  private renderData = () => {
    try {
      const nm = 'mv-flex--placeholder mv-table__data'
      const allData: JSX.Element[] = []

      if (this.state.data !== undefined) {
        let stateData = [...this.state.data]

        if (this.props.kind === PaginationTypes.noPagination) {
          stateData = stateData.slice(
            this.state.pagination.offset,
            this.state.pagination.offset + this.state.pagination.limit
          )
        }

        stateData.forEach((row: T, index1: number) => {
          const data: React.ReactNode[] = []

          // * Badge
          if (this.props.badges) {
            const badgeInfo = this.props.badges.cell(row)
            data.push(
              <td key={0} className={`${this.CN_BADGE} ${this.CN}__data--min-content`}>
                <TableBadge badge={badgeInfo} />
              </td>
            )
          }

          // * Checks
          if (this.props.batchActions) {
            data.push(
              <td key={1} className={`${this.CN_CHECK} ${nm}--min-content`}>
                <div className={``}>
                  <CheckboxItem
                    value={'value'}
                    initialSelected={this.state.dataSelected.filter(el => el.id === index1.toString()).length > 0}
                    name={'row-checkbox'}
                    onChange={checked => {
                      if (checked) {
                        const newData = [...this.state.dataSelected]
                        newData.push({ id: index1.toString(), data: row })
                        this.setState({ dataSelected: newData })
                      } else {
                        let newData = [...this.state.dataSelected]
                        newData = newData.filter(d => d.id !== index1.toString())
                        this.setState({ dataSelected: newData })
                      }
                    }}
                  />
                </div>
              </td>
            )
          }

          // * Data
          this.props.columns.forEach((col, index2) => {
            if (!col.hideColumn?.(row)) {
              data.push(
                <td className={col.className?.(row)} colSpan={col.colSpan?.(row)} key={index2 + 2}>
                  <div className={`${!!col.className?.(row) ? '' : nm}`}>{col.cell(row)}</div>
                </td>
              )
            }
          })

          // * Buttons
          if (this.props.buttons) {
            data.push(
              <td key={'0.1'} className={`${this.CN_BUTTON} ${nm}--min-content`}>
                {this.props.buttons.map((b, index) => {
                  const buttonProps = b.cell(row)
                  return <ButtonFactory key={index} {...buttonProps} />
                })}
              </td>
            )
          }
          allData.push(<tr key={index1}>{data}</tr>)
        })
      }
      return allData
    } catch (e) {
      this.setState({ tableState: TableState.loadFailure })
    }
  }

  // * --------------------------------------------------------------------------
  // * ---------------------- HELPER - GETTER & SETTER --------------------------
  // * --------------------------------------------------------------------------

  private get tableName(): string {
    return `${this.CN}${
      this.props.stickyFirstColumn ? ` ${this.CN}--sticky-badge-start ${this.CN}--sticky-checkbox-start ` : ''
    }${this.props.stickyLastColumn ? ` ${this.CN}--sticky-action-end ` : ''} ${this.CN}--resizable`
  }

  private get page() {
    if (this.state.data !== undefined) {
      return Math.ceil(this.state.pagination.offset / this.state.pagination.limit)
    }
    return 0
  }

  private get numberOfPages() {
    if (this.state.data !== undefined) {
      return Math.ceil(this.state.pagination.count / this.state.pagination.limit)
    }
    return 0
  }
}

// * --------------------------------------------------------------------------
// * --------------------------------- State Management ----------------------------------
// * --------------------------------------------------------------------------

const mapDispatchToProps = <T, K>(dispatch: Function): DispatchProps<T, K> => ({
  setPagination: (slug, pagination) => dispatch(setPagination(slug, pagination)),
  setFilters: (slug, filters) => dispatch(setFilters(slug, filters)),
  setColumnSize: (slug, listColumnSize) => dispatch(setColumnSize(slug, listColumnSize)),
  fetchTableData: (fetchWrapper, request, pagination, filters, f, parseResult, abortController, headers) =>
    dispatch(fetchTableData(fetchWrapper, request, pagination, filters, f, parseResult, abortController, headers)),
})

const TableReactComponentConnected = TableReactComponent

export default Table
