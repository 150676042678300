import { changeUnitSelected } from '@mv-submodules/inplant-checklist-fe-iblu/redux/actions/filters'
import { Value } from '@mv-submodules/inplant-checklist-fe-iblu/types/configuration'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

const UnitSelector = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const units: Value[] = useSelector((state: any) => state.checklist.configuration.data.units)
  const unitSelected: Value | undefined =
    useSelector((state: any) => state.checklist.filters.unitSelected) || (units.length >= 1 ? units[0] : undefined)

  const handleChangeUnit = (unit: Value) => {
    dispatch(changeUnitSelected(unit))
  }

  if (units.length <= 1) {
    return null
  }

  return (
    <div className="unit-selector d-inline-block mb-2">
      <label className="mr-2">{t('checklist.general.unit')}</label>
      <div className="btn-group" role="group">
        {units.map(unit => {
          return (
            <button
              key={unit.id}
              type="button"
              className={`btn btn-outline-secondary ${unitSelected?.id === unit.id ? 'active' : ''}`}
              onClick={() => handleChangeUnit(unit)}
            >
              {unit.name}
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default UnitSelector
