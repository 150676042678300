import { format } from 'date-fns'
import React from 'react'
import { useComponentsTranslation } from '../../../../../inplant-components-fe/services/translation'
import { LastCompilationData } from '../../../../types'

interface Props {
  title: React.ReactNode
  lastCompilationData?: LastCompilationData
  smallScreen?: boolean
}

const TitleWithCompilationData = (props: Props) => {
  const { title, lastCompilationData, smallScreen } = props
  const { t } = useComponentsTranslation()
  return !smallScreen ? (
    <>
      <div>{title}</div>
      {lastCompilationData && (
        <small>
          {t(`checklist.index.table.infoCompile`, {
            user: lastCompilationData.user.displayName,
            date: format(new Date(lastCompilationData.concludedDate), 'dd/MM/yyyy HH:mm'),
          })}
        </small>
      )}
    </>
  ) : <>
    <h5 className="my-2">{title}</h5>
    {lastCompilationData && (
        <small>
          {t(`checklist.index.table.infoCompile`, {
            user: lastCompilationData.user.displayName,
            date: format(new Date(lastCompilationData.concludedDate), 'dd/MM/yyyy HH:mm'),
          })}
        </small>
      )}
  </>
}

export default TitleWithCompilationData
