// * -------------------------- NPM --------------------------
import React from 'react'

// * -------------------------- MODULE --------------------------
import { BadgeSemantics } from '../../../Badges/Badge'
import { FetchWrapper } from '../../../../../types/fetchWrapperInterface'
import { FilterType } from '../../../Filters/Filters'
import { ITranslationService } from '../../../../../services/translation'
import { Icon } from '../../../../../services/icon'
import { Kind } from '../../../../../types/kind'
import { ButtonFactoryProps } from '../../../Buttons/ButtonFactory'

export interface ListPagination {
  limit: number
  offset: number
  sort: string
  count: number
}

type Key = string
export type ListFilters = Record<Key, string | string[] | null | undefined>

export type ListColumnSize = Record<Key, string>

export enum TableState {
  loadSuccess,
  loadInProgress,
  loadFailure,
}

export enum PaginationTypes {
  withPagination = 'withPagination',
  noPagination = 'noPagination',
}

// * ------------------------------- State Management props --------------------------------
export interface StoreProps {
  pagination: Record<Key, ListPagination>
  listFilters: Record<Key, ListFilters>
  columnSize: Record<Key, ListColumnSize>
}

export interface DispatchProps<T, K> {
  setPagination: (slug: string, pagination: ListPagination) => Promise<void>
  setFilters: (slug: string, filters: ListFilters) => Promise<void>
  setColumnSize: (slug: string, filters: ListColumnSize) => Promise<void>
  fetchTableData: (
    fetchWrapper: FetchWrapper,
    request: string,
    pagination: ListPagination | undefined,
    listFilters: ListFilters,
    filters: FilterTable[] | undefined,
    parseResult: ParseResultNoPagination<T, K> | ParseResultWithPagination<T, K>,
    abortController: AbortController,
    headers?: Record<string, any>
  ) => Promise<{ parsedData: ReturnType<typeof parseResult>; dateReturnedFromFetchRequest: K }>
}

// * ------------------------------- Props --------------------------------

// * for parse result
export type ParseResultWithPagination<T, K> = (
  fetchResult: K
) => { result: T[]; pagination: Omit<ListPagination, 'sort'> }

export type ParseResultNoPagination<T, K> = (fetchResult: K) => { result: T[] }

export interface DataPropsPagination<T, K> extends DataProps<PaginationTypes.withPagination> {
  parseResult: ParseResultWithPagination<T, K>
}

export interface DataPropsNoPagination<T, K> extends DataProps<PaginationTypes.noPagination> {
  parseResult: ParseResultNoPagination<T, K>
}

// * filters
/**
 * @param filterId: used as key for queryParams (can be overridden by stringify)
 * @param stringify: use it to override the default stringify used for the request
 * @param onChangeValue: if is needed the value for other task (for example information that change due to filter value cahnges)
 */
export type FilterTable = FilterType & {
  stringify?: (key: string, value: any) => string
  onChangeValue?: (value: any) => void
}

/**
 * @param applyFiltersOn: default is:
 *  PaginationTypes.withPagination --> applyFiltersOn = "BE"
 *  PaginationTypes.noPagination --> applyFiltersOn = "FE"
 */
export interface FiltersProps {
  applyFiltersOn?: 'FE' | 'BE'
  filters?: FilterTable[]
  /**
   * inside modal
   */
  advanceFilters?: React.ReactNode
}

// * manage API request
interface DataProps<K extends PaginationTypes> extends Kind<K> {
  fetchWrapperInstance: FetchWrapper
  request: string
  onCatchError: (error: any) => void
  headers?: Record<string, any>
}

// * will scope all request and will present only localData
export interface LocalData<T> {
  localTable?: {
    data: T[]
  }
}

export type ContextualInformationPositions = 'above-filter' | 'under-filter'

export type TableButtonType = ButtonFactoryProps

// * TableProps

/**
 * @param id: required if there are more table in one page
 * @param contextualInformation: render a custom component with date retrieved from the table request
 * @param forceRefresh: change forceRefresh value to request a new fetch
 */
export interface TableProps<T, K> {
  id?: string
  batchActions?: Array<BatchAction<T>>
  badges?: {
    cell: (data: T) => BadgeCell | undefined
  }
  buttons?: Array<{
    cell: (data: T) => ButtonFactoryProps
  }>
  columns: Array<Column<T>>

  contextualInformation?: {
    position: ContextualInformationPositions
    content: (data: K) => React.ReactNode
  }

  forceRefresh?: boolean
  silentForceRefresh?: boolean

  // esthetics props
  stickyFirstColumn?: boolean
  stickyLastColumn?: boolean

  translations: ITranslationService
}

export interface BadgeCell {
  icon?: Icon
  semantic: BadgeSemantics
}

/**
 * @param className Remove class from div inside <td></td>
 */
export interface Column<T> {
  name: string
  dataColumnId: string
  cell: (data: T) => React.ReactNode
  isSortable?: boolean
  hideColumn?: (data: T) => boolean
  colSpan?: (data: T) => number | undefined
  className?: (data: T) => string | undefined 
}

export interface BatchAction<T> {
  action: string
  onAction: (data: T[]) => void
  disable?: ((data: T[]) => boolean) | boolean
}

type StateManagementProps<T, K> = StoreProps & DispatchProps<T, K>
/**
 * generics T = type of table row
 * generics K = type of result returned from fetchRequest
 */
export type AllPropsTableComponent<T, K = any> = TableProps<T, K> &
  StateManagementProps<T, K> &
  FiltersProps &
  (DataPropsPagination<T, K> | DataPropsNoPagination<T, K>) &
  LocalData<T>

/**
 * generics T = type of table row
 * generics K = type of result returned from fetchRequest
 */
export type AllPropsTable<T, K = any> = Omit<TableProps<T, K>, 'translations'> &
  FiltersProps &
  (DataPropsPagination<T, K> | DataPropsNoPagination<T, K>) &
  LocalData<T>

// * ------------------------------- State --------------------------------

export interface State<T, K> {
  dateReturnedFromFetchRequest: K | undefined
  data: T[] | undefined
  tableState: TableState
  pagination: ListPagination
  listFilters: ListFilters
  columnSize: ListColumnSize
  timer: ReturnType<typeof setTimeout> | null
  sort: 'asc' | 'desc' | ''
  dataSelected: DataSelected<T>
  showModalAdvanceFilters: boolean
  applyFiltersOn: 'FE' | 'BE'

  // used for table with FE pagination
  dataUnchanged: T[] | undefined
}

export type DataSelected<T> = Array<{
  id: string
  data: T
}>
