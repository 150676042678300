import * as React from 'react'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { checklistUpdateComponent } from '../../../../redux/actions'

export interface ComponentData {
  id: string
  optional: boolean
  type: {
    type: string
    options: string[]
  }
  defaultValue: string
  lastValue: string
}

export interface StateProps {
  componentData: ComponentData
  checklistInstanceId: string
  checkId: string
}

export interface DispatchProps {
  updateComponent: (
    checklistInstanceId: string,
    componentId: string | undefined,
    value: Array<{ subComponentName: string; value: number }> | string | number | undefined,
    conclude: boolean
  ) => Promise<any>
}

export interface OwnProps {
  disabled: boolean
  collapseHandler?: Function
}

export interface OwnState {
  currentValue: string
  loading: boolean
}

export type Props = StateProps & DispatchProps & OwnProps & WithTranslation

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    updateComponent: (checklistInstanceId, componentId, value, conclude) =>
      dispatch(checklistUpdateComponent(checklistInstanceId, componentId, value, conclude)),
  }
}

export class SelectComponent extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      currentValue: props.componentData.lastValue || props.componentData.defaultValue,
      loading: false,
    }
    this.updateComponent = this.updateComponent.bind(this)
    this.manageCollapsable()
  }

  private manageCollapsable() {
    if (this.props.componentData.lastValue && this.props.componentData.optional && this.props.collapseHandler) {
      this.props.collapseHandler()
    }
  }

  private updateComponent(event: any) {
    event.persist()
    this.setState(
      prevState => {
        return {
          ...prevState,
          currentValue: event.target.value,
          loading: true,
        }
      },
      () => {
        this.props
          .updateComponent(this.props.checklistInstanceId, this.props.componentData.id, this.state.currentValue, false)
          .then(() => {
            this.setState(prevState => {
              return {
                ...prevState,
                loading: false,
              }
            })
          })
      }
    )
  }

  public render() {
    return (
      <select
        className="custom-select"
        disabled={this.state.loading || this.props.disabled}
        onChange={this.updateComponent}
      >
        {this.props.componentData.type.options.map(option => (
          <option key={`option-${option}`} value={option} selected={this.state.currentValue === option}>
            {option}
          </option>
        ))}
      </select>
    )
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(SelectComponent))
