import * as React from 'react'
import {withRouter, RouteComponentProps} from 'react-router'
import {connect} from 'react-redux'
import {withTranslation, WithTranslation} from 'react-i18next'
import {ChecklistModel, ChecklistInstance} from '../../../../types'
import {
  checklistEditFetchChecklist,
  fetchArchiveAssets,
  fetchArchiveModels,
  printArchive,
  printChecklist,
} from '../../../../redux/actions'
import {SortingRule, Filter} from 'react-table'
import 'react-datepicker/dist/react-datepicker.css'
import {Filters, PageHeader, Table, TableRowButtonGroup} from "@mv-submodules/inplant-components-fe";
import MainPageContent from "@mv-submodules/inplant-components-fe/ui/components/Layout/MainPageContent";
import {FilterComponent} from "@mv-submodules/inplant-components-fe/ui/components/Filters/Filters";
import {fetchArchiveNoRedux} from "@mv-submodules/inplant-checklist-fe-iblu/redux/actions/checklistArchive";
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

export interface StateProps {
  page: number
  pageSize: number
  sorted: SortingRule[]
  filtered: Filter[]
  createdDate: any
  concludedDate: any
  assets: any
  models: ChecklistModel[]
}

export interface DispatchProps {
  fetchArchive: (
    pageSize: number,
    sorted: Array<{ id: string; desc: boolean }>,
    filtered: Array<{ id: string; value: string }>,
    page: number,
    personal?: boolean
  ) => Promise<any>
  fetchChecklist: (checklistInstanceId: string) => Promise<any>
  fetchAssets: () => Promise<any>
  fetchModels: () => Promise<any>
  printArchive: (
    pageSize: number,
    sorted: Array<{ id: string; desc: boolean }>,
    filtered: Array<{ id: string; value: string }>,
    page: number
  ) => Promise<Blob | null>
  printChecklist: (checklistId: string) => Promise<Blob | null>
}

export interface OwnProps extends RouteComponentProps<any> {
  // t: TranslationFunction
}

export interface OwnState {
  downloading: boolean
  printing: { [checklistId: string]: boolean }
  sorted: SortingRule[]
  filtered: Filter[]
  pageSize: number
  page: number
  isFetching: boolean
  fetchErrors: boolean
  checklists: Array<Partial<ChecklistInstance>>
  pages: number
}

export type Props = StateProps & DispatchProps & OwnProps & WithTranslation

const mapStateToProps = (state: any) => ({
  page: state.checklist.archive.page,
  pageSize: state.checklist.archive.pageSize,
  sorted: state.checklist.archive.sorted,
  filtered: state.checklist.archive.filtered,
  createdDate: state.checklist.archive.createdDate,
  concludedDate: state.checklist.archive.concludedDate,
  assets: state.checklist.archive.assets,
  models: state.checklist.archive.models,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  fetchArchive: (pageSize, sorted, filtered, page, personal) =>
    dispatch(fetchArchiveNoRedux(pageSize, sorted, filtered, page, personal)),
  fetchChecklist: (checklistInstanceId: string) => dispatch(checklistEditFetchChecklist(checklistInstanceId)),
  fetchAssets: () => dispatch(fetchArchiveAssets()),
  fetchModels: () => dispatch(fetchArchiveModels()),
  printArchive: (pageSize, sorted, filtered, page) => dispatch(printArchive(pageSize, sorted, filtered, page)),
  printChecklist: checklistId => dispatch(printChecklist(checklistId)),
})

export class ChecklistArchive extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      downloading: false,
      printing: {},
      sorted: this.props.sorted,
      filtered: this.props.filtered,
      pageSize: this.props.pageSize,
      page: this.props.page,
      isFetching: false,
      fetchErrors: false,
      checklists: [],
      pages: 0
    }
    this.handleChecklistClick = this.handleChecklistClick.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.handlePrintArchive = this.handlePrintArchive.bind(this)
    this.handlePrintChecklist = this.handlePrintChecklist.bind(this)
    this.handleFilters = this.handleFilters.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this)
    this.getFilterValue = this.getFilterValue.bind(this)
  }

  public async componentDidMount() {
    this.props.fetchModels()
    this.props.fetchAssets()
    this.fetchData({
      sorted: this.props.sorted,
      pageSize: this.props.pageSize,
      page: !this.props.page && this.props.page < 0 ? 0 : this.props.page
      ,
      filtered: this.props.filtered
    })
  }

  public handleChecklistClick(checklist: ChecklistInstance) {
    return this.props.history.push(`/checklist/edit/${checklist.id}`)
  }

  public fetchData(state: { sorted: SortingRule[]; filtered: Filter[]; pageSize: number; page: number }) {
    if (!this.state.isFetching) {
      this.setState({isFetching: true, fetchErrors: false})
      this.props.fetchArchive(
        state.pageSize,
        state.sorted,
        state.filtered,
        state.page + 1,
        true
      ).then((response) => this.setState({checklists: response.checklists, pages: response.pages}))
        .catch((error) => {
          this.setState({fetchErrors: true})
        })
        .finally(() => this.setState({isFetching: false}))
    }
  }

  private async handlePrintArchive() {
    this.setState(prevState => ({...prevState, downloading: true}))
    try {
      const blob = await this.props.printArchive(
        this.props.pageSize,
        this.prepareSortingData(this.props.sorted),
        this.props.filtered,
        this.props.page + 1
      )
      const objectURL = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = objectURL
      link.download = 'archive.pdf'
      link.click()
      this.setState(prevState => ({...prevState, downloading: false}))
    } catch (error) {
      this.setState(prevState => ({...prevState, error}))
      this.setState(prevState => ({...prevState, downloading: false}))
    }
  }

  private async handlePrintChecklist(checklist: ChecklistInstance) {
    this.setState(prevState => {
      const printing = prevState.printing
      printing[checklist.id!] = true
      return {
        ...prevState,
        printing,
      }
    })
    try {
      const blob = await this.props.printChecklist(checklist.id!)
      const objectURL = URL.createObjectURL(blob)
      const link = document.createElement('a')
      const date = checklist.concludedDate
        ? mvDate.getDateFromStringWithFormatting(checklist.concludedDate!, 'yyyy-MM-dd HH:mm:ss')
        : mvDate.getDateFromStringWithFormatting(checklist.createdDate!, 'yyyy-MM-dd HH:mm:ss')
      const title = checklist.title
      const asset = checklist.asset ? `[${checklist.asset!.code}] ${checklist.asset!.description}` : null
      link.href = objectURL
      link.download = `${date} - ${title}${asset ? ` - ${asset}` : ''}.pdf`
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      link.remove()
      this.setState(prevState => {
        const printing = prevState.printing
        printing[checklist.id!] = false
        return {
          ...prevState,
          printing,
        }
      })
    } catch (error) {
      this.setState(prevState => ({...prevState, error}))
      this.setState(prevState => {
        const printing = prevState.printing
        printing[checklist.id!] = false
        return {
          ...prevState,
          printing,
        }
      })
    }
  }

  private prepareSortingData(sorted: SortingRule[]): Array<{ id: string; desc: boolean }> {
    return sorted.map(s => ({
      id: s.id,
      desc: s.desc ? s.desc : false,
    }))
  }

  private handleFilters(filter: { id: string, value: string | number | null }) {
    const filters = this.props.filtered
    const indexFilter = this.props.filtered.findIndex(fil => fil.id === filter.id)
    if (indexFilter >= 0) {
      filters[indexFilter] = filter
    } else {
      filters.push(filter)
    }
    this.fetchData({
      page: 0,
      filtered: this.props.filtered,
      sorted: this.props.sorted,
      pageSize: this.props.pageSize
    })
  }

  private getFilterValue(name: string) {
    if (!this.props.filtered) {
      return null
    }
    const index = this.props.filtered.findIndex(filter => filter.id === name)
    if (index === -1) {
      return null
    }
    return this.props.filtered[index].value
  }

  private handlePageChange(pageNumber: number) {
    this.fetchData({
      page: pageNumber,
      filtered: this.props.filtered,
      sorted: this.props.sorted,
      pageSize: this.props.pageSize
    })
  }

  private handlePageSizeChange(pageSize: number) {
    this.fetchData({
      pageSize,
      sorted: this.props.sorted,
      filtered: this.props.filtered,
      page: this.props.page
    })
  }

  public render() {
    const {isFetching, checklists} = this.state
    const filters: FilterComponent[] = [{
      disabled: isFetching || !checklists,
      name: 'title',
      id: 'checklist-title-select',
      type: 'Select',
      label: this.props.t('checklist.archive.table.title'),
      options: {
        items: this.props.models
          .sort((a: ChecklistModel, b: ChecklistModel) => a.title.localeCompare(b.title))
          .map((model: ChecklistModel) => ({
            value: model.id,
            label: model.title
          })),
        defaultOptionLabel: this.props.t('checklist.archive.table.showAll')
      },
      onChange: (name, value) => this.handleFilters({id: name, value}),
      value: this.getFilterValue('title'),
      customSize: 2
    }, {
      disabled: isFetching || !checklists,
      name: 'user.displayName',
      id: 'checklist-user-name',
      type: 'SearchInput',
      label: this.props.t('checklist.archive.table.user.displayName'),
      value: this.getFilterValue('user.displayName'),
      onChange: (name, value) => this.handleFilters({id: name, value}),
      throttle: 500,
      customSize: 2
    }, {
      disabled: isFetching || !checklists,
      name: 'asset',
      id: 'checklist-asset-name',
      type: 'SearchInput',
      label: this.props.t('checklist.archive.table.asset.description'),
      value: this.getFilterValue('asset'),
      onChange: (name, value) => this.handleFilters({id: name, value}),
      throttle: 500,
      customSize: 2
    }, {
      disabled: isFetching || !checklists,
      name: 'createdDate',
      id: 'checklist-created-date',
      type: 'Date',
      value: this.getFilterValue('createdDate'),
      label: this.props.t('checklist.archive.table.createdDate'),
      onChange: (name, value) => this.handleFilters({
        id: name,
        value: value ? mvDate.endOfDay(mvDate.now()).toISOString() : null
      }),
      customSize: 2
    }, {
      disabled: isFetching || !checklists,
      name: 'concludedDate',
      id: 'checklist-concluded-date',
      type: 'Date',
      value: this.getFilterValue('concludedDate'),
      label: this.props.t('checklist.archive.table.concludedDate'),
      onChange: (name, value) => this.handleFilters({
        id: name,
        value: value ? mvDate.endOfDay(new Date(value)).toISOString() : null
      }),
      customSize: 2
    }]

    return (
      <React.Fragment>
        <PageHeader
          title={this.props.t('checklist.archive.title')}
        />
        <MainPageContent>
          <Filters fields={filters}/>
          <Table
            manual={true}
            data={checklists as any}
            isFetching={isFetching}
            manualIsFetching={true}
            //  onFetchData={this.fetchData}
            page={this.props.page}
            pages={this.state.pages}
            className="-striped -highlight mv4iot-fe-checklist-archive-table"
            showPagination={true}
            minRows={checklists.length === 0 ? 8 : 0}
            noDataText={this.props.t('checklist.archive.table.noData')}
            showPaginationTop={true}
            showPaginationBottom={true}
            pageText={this.props.t('checklist.archive.table.page')}
            ofText={this.props.t('checklist.archive.table.of')}
            rowsText={this.props.t('checklist.archive.table.rows')}
            onPageChange={this.handlePageChange}
            onPageSizeChange={this.handlePageSizeChange}
            columns={[
              {
                Header: this.props.t('checklist.archive.table.title'),
                accessor: 'title',
                minWidth: 170,
                Cell: row => (
                  <>
                    <p className="mb-0">{row.original.title}</p>
                    <small>
                      {this.props.t('checklist.archive.table.lastValidation.label')}:
                      <strong className="ml-1">
                        {row.original.lastValidation
                          ? `
                                               ${row.original.lastValidation.user.displayName}
                                               (${mvDate.getDateFromStringWithFormatting(row.original.lastValidation.validatedDate, 'dd/MM/yyyy HH:mm')})
                                             `
                          : this.props.t('checklist.archive.table.lastValidation.none')}
                      </strong>
                    </small>
                  </>
                )
              },
              {
                Header: this.props.t('checklist.archive.table.user.displayName'),
                accessor: 'user.displayName',
                minWidth: 80,
              },
              {
                Header: this.props.t('checklist.archive.table.asset.description'),
                id: 'asset',
                accessor: (checklist: ChecklistInstance) => {
                  if (checklist.asset) {
                    return `${checklist.asset.description} [${checklist.asset.code}]`
                  }
                  return ''
                },
                minWidth: 170,
                Cell: data => {
                  if (data.original.asset) {
                    return `${data.original.asset.description} [${data.original.asset.code}]`
                  }
                  return null
                }
              },
              {
                Header: this.props.t('checklist.archive.table.createdDate'),
                accessor: 'createdDate',
                minWidth: 130,
                Cell: data => mvDate.getDateFromStringWithFormatting(data.original.createdDate, 'dd/MM/yyyy HH:mm'),
              },
              {
                Header: this.props.t('checklist.archive.table.concludedDate'),
                accessor: 'concludedDate',
                minWidth: 130,
                Cell: data => mvDate.getDateFromStringWithFormatting(data.original.concludedDate, 'dd/MM/yyyy HH:mm')
              },
              {
                Header: this.props.t('checklist.archive.table.invalidChecksCount'),
                accessor: 'invalidChecksCount',
                sortable: false,
                maxWidth: 70,
                Cell: data => {
                  if (data.original.invalidChecksCount > 0) {
                    return (
                      <React.Fragment>
                        {data.original.invalidChecksCount}
                        <IconComponent icon={'exclamation-triangle'} className={'text-warning ml-1'} />
                      </React.Fragment>
                    )
                  }
                  return null
                },
              },
              {
                Header: this.props.t('checklist.archive.table.actions'),
                sortable: false,
                Cell: data => (
                  <TableRowButtonGroup buttons={[
                    {
                      variant: 'secondary-alternate',
                      icon: "eye",
                      onClick: () => this.handleChecklistClick(data.original),
                    }, {
                      onClick: () => this.handlePrintChecklist(data.original),
                      printButton: true,
                      isLoading: this.state.printing[data.original.id]
                    }
                  ]}
                  />
                ),
              },
            ]}
          />
        </MainPageContent>
      </React.Fragment>
    )
  }
}

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChecklistArchive)))
