import { API } from './index'
import { AnyAction, Dispatch } from 'redux'
import { Asset, AssetParams } from '../../types'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import FetchError from "@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError";

export const checklistAssetIsFetching = (): AnyAction => {
  return {
    type: 'CHECKLIST_ASSET_RESET_HOURS_IS_FETCHING',
  }
}

export const checklistAssetFetchSuccess = (assets: Asset[]): AnyAction => {
  return {
    payload: assets,
    type: 'CHECKLIST_ASSET_RESET_HOURS_FETCH_SUCCESS',
  }
}

export const checklistAssetFetchError = (error: Error): AnyAction => {
  return {
    payload: error,
    type: 'CHECKLIST_ASSET_RESET_HOURS_FETCH_ERROR',
  }
}

export const checklistAssetIsSubmitting = (assetId: string, hours: number): AnyAction => {
  return {
    assetId,
    hours,
    type: 'CHECKLIST_ASSET_RESET_HOURS_IS_SUBMITTING',
  }
}

export const checklistAssetSaveSuccess = (assetId: string, hours: number): AnyAction => {
  return {
    assetId,
    hours,
    type: 'CHECKLIST_ASSET_RESET_HOURS_SAVE_SUCCESS',
  }
}

export const checklistAssetResetHoursFetchIndex = (): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async dispatch => {
    dispatch(checklistAssetIsFetching())
    try {
      const response = await API().request(`/assets`)
      const list = response.map((asset: AssetParams) => new Asset(asset))
      dispatch(checklistAssetFetchSuccess(list))
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(checklistAssetFetchError(new Error(error)))
      throw error
    }
  }
}

export const checklistAssetResetHoursFetchIndexNoRedux = () => (dispatch: Dispatch) => {
  return API().request(`/assets`)
      .then((response: any) => response.map((asset: AssetParams) => new Asset(asset)))
      .catch((error: FetchError) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
        throw error
      })
}

export const checklistAssetResetHours = (
  assetId: string,
  hours: number
): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async dispatch => {
    dispatch(checklistAssetIsSubmitting(assetId, hours))
    try {
      const response = await API().request(`/reset-asset-hours`, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ assetId, hours }),
      })
      dispatch(checklistAssetSaveSuccess(assetId, hours))
      return response
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}
