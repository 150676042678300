import * as React from 'react'
import {ChecklistSection as IChecklistSection, ChecklistInstance} from '../../../../types'
import {withTranslation, WithTranslation} from 'react-i18next'
import ChecklistCheck from './ChecklistCheck'
import NewCheckModal, {CheckPrototype} from './NewCheckModal'
import {RouteComponentProps, withRouter} from 'react-router'
import {checklistEditFetchChecklist} from '../../../../redux/actions'
import DeleteCheckModal from './DeleteCheckModal'
import {connect} from 'react-redux'
import RunningTotals from './RunningTotals'
import SectionAttributes from './SectionAttributes'
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";
import { IconComponent } from '../../../../../mvlabs-components-fe/ui/components'

export interface StateProps {
    checklist: ChecklistInstance | null
}

export interface DispatchProps {
    fetchChecklist: (id: string) => Promise<any>
}

export interface OwnProps extends RouteComponentProps<any> {
    section: IChecklistSection
    isSummary: boolean
}

export interface OwnState {
    newCheckModalShow: boolean
    newCheckModalSectionId: string | null
    newCheckModalCheckPrototype: CheckPrototype | null
    deleteCheckModalShow: boolean
    deleteCheckModalCheckId: string | null
}

export type Props = StateProps & DispatchProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
    checklist: state.checklist.edit.checklist,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
    return {
        fetchChecklist: id => dispatch(checklistEditFetchChecklist(id)),
    }
}

export class ChecklistSection extends React.Component<Props, OwnState> {
    constructor(props: Props) {
        super(props)
        this.state = {
            newCheckModalShow: false,
            newCheckModalSectionId: null,
            newCheckModalCheckPrototype: null,
            deleteCheckModalShow: false,
            deleteCheckModalCheckId: null,
        }
        this.handleAddCheck = this.handleAddCheck.bind(this)
        this.handleDeleteCheck = this.handleDeleteCheck.bind(this)
        this.handleAddCheckModalClose = this.handleAddCheckModalClose.bind(this)
        this.handleDeleteCheckModalClose = this.handleDeleteCheckModalClose.bind(this)
    }

    private handleAddCheck(event: any) {
        event.persist()
        const checkPrototype = this.props.section.customCheckPrototype as CheckPrototype
        this.setState(prevState => ({
            ...prevState,
            newCheckModalSectionId: this.props.section.id,
            newCheckModalCheckPrototype: checkPrototype,
            newCheckModalShow: true,
        }))
    }

    private handleDeleteCheck(event: any,checkId:string) {
        event.persist()
        this.setState(prevState => ({
            ...prevState,
            deleteCheckModalCheckId: checkId,
            deleteCheckModalShow: true,
        }))
    }

    private async handleAddCheckModalClose(reload: boolean = false) {
        this.setState(prevState => ({
            ...prevState,
            newCheckModalSectionId: null,
            newCheckModalCheckPrototype: null,
            newCheckModalShow: false,
            loading: true,
        }))
        if (reload) {
            try {
                await this.props.fetchChecklist(this.props.match.params.checklistId)
                this.setState(prevState => ({
                    ...prevState,
                    checklist: this.props.checklist,
                    loading: false,
                }))
            } catch (error) {
                this.setState(prevState => ({
                    ...prevState,
                    loading: false,
                }))
            }
        } else {
            this.setState(prevState => ({
                ...prevState,
                loading: false,
            }))
        }
    }

    private async handleDeleteCheckModalClose(reload: boolean = false) {
        this.setState(prevState => ({
            ...prevState,
            deleteCheckModalCheckId: null,
            loading: true,
        }))
        if (reload) {
            try {
                await this.props.fetchChecklist(this.props.match.params.checklistId)
                this.setState(prevState => ({
                    ...prevState,
                    checklist: this.props.checklist,
                    loading: false,
                }))
            } catch (error) {
                this.setState(prevState => ({
                    ...prevState,
                    loading: false,
                }))
            }
        } else {
            this.setState(prevState => ({
                ...prevState,
                loading: false,
            }))
        }
    }

    public render() {
        const UILayoutParamsClasses = []
        const UILayoutParams = this.props.section.UILayoutParams
        if (UILayoutParams) {
            if (UILayoutParams.bigText) {
                UILayoutParamsClasses.push('big-text')
            }
            if (UILayoutParams.reducedVerticalSpace) {
                UILayoutParamsClasses.push('reduced-vertical-space')
            }
            if (UILayoutParams.increasedComponentWidths) {
                UILayoutParamsClasses.push('increased-component-widths')
            }
            if (UILayoutParams.hideComponentTitles) {
                UILayoutParamsClasses.push('hide-component-titles')
            }
        }
        return (
            <React.Fragment>
                {this.state.newCheckModalCheckPrototype ? (
                    <NewCheckModal
                        show={this.state.newCheckModalShow}
                        onClose={this.handleAddCheckModalClose}
                        sectionId={this.state.newCheckModalSectionId!}
                        checkPrototype={this.state.newCheckModalCheckPrototype!}
                    />
                ) : null}
                {this.state.deleteCheckModalCheckId ? (
                    <DeleteCheckModal
                        show={this.state.deleteCheckModalShow}
                        onClose={this.handleDeleteCheckModalClose}
                        checkId={this.state.deleteCheckModalCheckId!}
                    />
                ) : null}
                <SectionAttributes
                    attributes={
                        !this.props.checklist!.isConcluded() || this.props.isSummary ? this.props.section.attributes : null
                    }
                />
                <div className="cl-container" key={this.props.section.id}>
                    <div className="cl-header">
                        <Row noGutters={true} spacing={{vertical:false,horizontal:false}}>
                            <Column xs={12}>
                                <h5 className="title">{this.props.section.title}</h5>
                            </Column>
                        </Row>

                    </div>
                    <div className={`cl-item-list ${UILayoutParamsClasses.join(' ')}`}>
                        <Row spacing={{vertical:false,horizontal:false}}>
                            {this.props.section.checks
                                .filter(
                                    check =>
                                        ((this.props.checklist!.isConcluded() || this.props.isSummary) && check.visible && check.canBeFilled) ||
                                        (check.visibilityStep === this.props.checklist!.currentState().name &&
                                            check.visible &&
                                            check.canBeFilled)
                                )
                                .map((check, index) => (
                                    <ChecklistCheck
                                        key={index}
                                        check={check}
                                        checklistId={this.props.checklist!.id!}
                                        checklistIsConcluded={this.props.checklist!.isConcluded() || this.props.isSummary}
                                        deleteCheckHandler={this.handleDeleteCheck}
                                    />
                                ))}
                        </Row>

                        <RunningTotals/>
                        {this.props.section.customCheckPrototype && !this.props.checklist!.isConcluded() ? (
                            <div className="cl-item">
                                <Column xs={12}>
                                    <div className={"text-center"}>
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            data-section-id={this.props.section.id}
                                            onClick={this.handleAddCheck}
                                        >
                                            <IconComponent icon={'plus'} className="mr-1"/>
                                            {this.props.t('checklist.edit.addCheck')}
                                        </button>
                                    </div>
                                </Column>


                            </div>
                        ) : null}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter<any, any>(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation()(ChecklistSection))
)
