import * as React from 'react'
import {RouteComponentProps, withRouter} from 'react-router'
import {connect} from 'react-redux'
import {WithTranslation, withTranslation} from 'react-i18next'
import {validate, getFile} from '../../../../redux/actions/checklistValidation'
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";
import {Button} from "@mv-submodules/inplant-components-fe";
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import { parse } from '../../../../../inplant-components-fe/mvfunctions/apiFormatter'

export interface DispatchProps {
  validate: (checklistId: string, valid: boolean, comment?: string, file?: string) => Function
  getFile: (validationId: string) => Promise<any>
}

export interface OwnStateProps {
  loading: boolean
  valid: boolean
  comment: string
  file: string
  fileBlob: string | undefined
  allowValidation: boolean
}

export interface OwnProps extends RouteComponentProps<any> {
  checklistId: string
  lastValidation: any
  validationFields: any
  disabled: boolean
}

export type Props = DispatchProps & OwnProps & WithTranslation

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  validate: (checklistId, valid, comment, file) => dispatch(validate(checklistId, valid, comment, file)),
  getFile: (validationId: string) => dispatch(getFile(validationId)),
})

export class ValidationComponent extends React.Component<Props, OwnStateProps> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
      valid: this.props.lastValidation ? this.props.lastValidation.data.valid : false,
      comment: this.props.lastValidation ? this.props.lastValidation.data.comment : '',
      file: this.props.lastValidation ? this.props.lastValidation.data.file : '',
      fileBlob: undefined,
      allowValidation: parse(this.props.location.search.substr(1)).allowValidation === 'true',
    }
    this.updateValid = this.updateValid.bind(this)
    this.updateComment = this.updateComment.bind(this)
    this.updateFile = this.updateFile.bind(this)
    this.validate = this.validate.bind(this)
    this.getFile = this.getFile.bind(this)
  }

  public componentDidMount() {
    if (this.state.file) {
      this.getFile()
    }
  }

  private async getFile() {
    this.setState(prevState => ({...prevState, loading: true}))
    try {
      const fileBlob = await this.props.getFile(this.props.lastValidation.id)
      this.setState(prevState => ({...prevState, fileBlob, loading: false}))
    } catch (error) {
      this.setState(prevState => ({...prevState, loading: false}))
    }
  }

  private updateValid(event: any) {
    event.persist()
    this.setState(prevState => {
      return {
        ...prevState,
        valid: Boolean(event.target.value),
      }
    })
  }

  private updateComment(event: any) {
    event.persist()
    this.setState(prevState => {
      return {
        ...prevState,
        comment: event.target.value,
      }
    })
  }

  private updateFile(event: any) {
    event.persist()
    this.setState(prevState => {
      return {
        ...prevState,
        fileBlob: event.target.files[0],
      }
    })
  }

  private async validate() {
    try {
      await this.props.validate(this.props.checklistId, this.state.valid, this.state.comment, this.state.fileBlob)
      this.setState(prevState => {
        return {
          ...prevState,
          loading: false,
        }
      })
      this.props.history.goBack()
    } catch (error) {
      this.setState(prevState => {
        return {
          ...prevState,
          loading: false,
        }
      })
    }
  }

  public render() {
    return (
      <div className="validation-component">
        <Row>
          <Column xs={12}>
            <h5>{this.props.t('checklist.edit.validation.title')}</h5>
          </Column>
        </Row>
        <Row>
          {this.props.lastValidation ? (
            <Column xs={12}>
              <p>
                {`
                        ${this.props.t('checklist.edit.validation.validatedBy')}
                        ${this.props.lastValidation.user.displayName}
                        (${mvDate.getDateFromStringWithFormatting(this.props.lastValidation.validatedDate, 'dd/MM/yyyy HH:mm')})
                      `}
              </p>
            </Column>
          ) : null}
          {this.props.lastValidation || this.state.allowValidation ? (
            <React.Fragment>
              <Column sm={12} md={4}>
                <div className="radio-component">
                  <label>{this.props.t('checklist.edit.validation.valid')}</label>
                  <div className="btn-group btn-group form-group" role="group">
                    <button
                      type="button"
                      className={`
                              btn
                              btn-lg
                              btn-outline-secondary
                              hover-success
                              ${this.state.valid === true ? 'active' : ''}
                            `}
                      onClick={this.updateValid}
                      disabled={this.state.loading || this.props.disabled || !this.state.allowValidation}
                      value="true"
                    >
                      OK
                    </button>
                    <button
                      type="button"
                      className={`
                              btn
                              btn-lg
                              btn-outline-secondary
                              hover-danger
                              ${this.state.valid === false ? 'active' : ''}
                            `}
                      onClick={this.updateValid}
                      disabled={this.state.loading || this.props.disabled || !this.state.allowValidation}
                      value=""
                    >
                      KO
                    </button>
                  </div>
                </div>
              </Column>
              <Column sm={12} md={4}>
                <div className="text-component">
                  <div className="form-group">
                    <label>{this.props.t('checklist.edit.validation.comment')}</label>
                    <textarea
                      className="form-control"
                      rows={3}
                      value={this.state.comment}
                      onChange={this.updateComment}
                      disabled={this.state.loading || this.props.disabled || !this.state.allowValidation}
                    />
                  </div>
                </div>
              </Column>
              <Column sm={12} md={4}>
                <div className="file-component">
                  <Row>
                    <Column>
                      <label>{this.props.t('checklist.edit.validation.photo')}</label>
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      {this.state.fileBlob ? (
                        <img className="img-thumbnail"
                             src={URL.createObjectURL(this.state.fileBlob)}/>
                      ) : (
                        this.props.t('checklist.edit.validation.noPhoto')
                      )}
                    </Column>
                    <Column>
                      <div className="form-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            lang="it"
                            onChange={this.updateFile}
                            disabled={this.state.loading || this.props.disabled || !this.state.allowValidation}
                          />
                          <label className="custom-file-label" htmlFor="customFileLang">
                            {this.props.t('checklist.components.browse')}
                          </label>
                        </div>
                      </div>
                    </Column>
                  </Row>
                </div>
              </Column>
            </React.Fragment>
          ) : (
            <Column>
              <p>{this.props.t('checklist.edit.validation.notYetValidated')}</p>
            </Column>
          )}
        </Row>
        {!this.props.disabled && this.state.allowValidation ? (
          <Row>
            <Column>
              <div className={"text-right"}>
                <Button
                  variant={"primary"}
                  onClick={this.validate}
                  disabled={this.state.loading || this.props.disabled || !this.state.allowValidation}
                  icon={"user-check"}
                  label={this.props.t('checklist.edit.validation.validate')}
                />

              </div>

            </Column>
          </Row>
        ) : null}
      </div>
    )
  }
}

export default withRouter(
  connect(
    (state: any) => ({}),
    mapDispatchToProps
  )(withTranslation()(ValidationComponent))
)
