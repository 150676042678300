import React, { /*useEffect, useState*/ } from 'react'
import { useInjection /*useInversifyContainer*/ } from './binding'

/*
import { ComponentServiceError } from './error'
*/

export interface IComponentService<T extends {} = {}> {
  render(props: T): React.ReactElement<T>
}

export class ComponentService<T> implements IComponentService<T> {
  private _component: (props: T) => React.ReactElement<T>

  constructor(component: (props: T) => React.ReactElement<T>) {
    this._component = component
  }

  public render = (props: T): React.ReactElement<T, string | React.JSXElementConstructor<any>> => {
    return React.createElement(this._component, props)
  }
}

/**
 * @throws {ComponentServiceError}
 */
export function useComponent<T>(serviceId: symbol) {
  return useInjection<T>(serviceId)
}
