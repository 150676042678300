import * as React from 'react'
import withObserver, { WithObserverProps } from './withObserver'
import './Image.scss'
import { connect } from 'react-redux'
import { checklistPrediction } from '@mv-submodules/inplant-checklist-fe-iblu/redux/actions/checklistPhotos'
import EXIF from 'exif-js'
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'

export interface InfoImage {
  dateTimeOriginal:string
}

interface OwnProps {
  alt?: string
  path: string
  category: string
  onImageLoaded: (infoImage:Partial<InfoImage>) => void
}

interface DispatchProps {
  checklistPrediction: (imagePath: string) => Promise<Results>
}

export type Results = 'ok' | 'ko' | 'unknown'

interface State {
  prediction?: Results
  categoryForIA: string[]
}

type Props = OwnProps & WithObserverProps & DispatchProps

class Image extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      prediction: undefined,
      categoryForIA: ['area segregata vro', 'sala qe ventilatori'], // TODO move it on .env
      // JSON.parse(process.env.REACT_APP_CATEGORY_IA as any)
    }
  }

  public componentDidMount() {
    if (
      this.state.categoryForIA !== undefined &&
      Array.isArray(this.state.categoryForIA) &&
      this.state.categoryForIA.some(cat => {
        return this.props.category
          .trim()
          .toLocaleLowerCase()
          .includes(cat)
      })
    ) {
      this.props
        .checklistPrediction(this.props.path)
        .then(prediction => {
          this.setState({ prediction })
        })
        .catch(e => console.error('e', e)) //tslint:disable-line
    }
  }

  public render() {
    return (
      <img
        className={`${this.props.className} ${this.renderClassName(this.state.prediction)}`}
        src={this.props.src}
        alt={this.props.alt}
        onError={this.props.onError}
        onLoad={(imgElement) => {
          const self = this
          EXIF.getData(imgElement.currentTarget as any,function (this:any){
            const tags = EXIF.getAllTags(this)
            if (tags.DateTimeOriginal) {
              self.props?.onImageLoaded?.({dateTimeOriginal: mvDate.format(mvDate.parseDateFromStringWithFormatting(tags.DateTimeOriginal,'yyyy:MM:dd HH:mm:ss'),'dd/MM/yyyy HH:mm') })
            }
          })
        }}
      />
    )
  }

  private renderClassName(result?: Results) {
    if (this.state.prediction !== undefined) {
      switch (result) {
        case 'ok':
          return 'image_ia image_ia--ok'
        case 'ko':
          return 'image_ia image_ia--ko'
        default:
          return 'image_ia'
      }
    } else {
      return ''
    }
  }
}

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  checklistPrediction: (imagePath: string) => dispatch(checklistPrediction(imagePath)),
})

export default (connect(null, mapDispatchToProps)(withObserver<any>(Image)))
