import FetchWrapper from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper'

export const API = () => FetchWrapper.getInstance('checklist')
export const API_IA = () => FetchWrapper.getInstance('checklistIA')
export {
  checklistIndexIsFetching,
  checklistIndexFetchSuccess,
  checklistIndexFetchError,
  checklistStartChecklist,
} from './checklistIndex'

export {
  checklistEditIsFetching,
  checklistEditFetchSuccess,
  checklistEditFetchError,
  checklistEditFetchChecklist,
  checklistUpdateComponent,
  checklistGetFile,
  checklistUpdateFile,
  checklistDeleteFile,
  checklistNextStep,
  addCheck,
  deleteCheck,
  printChecklist,
  deleteChecklist,
} from './checklistEdit'

export {
  checklistAssetIsFetching,
  checklistAssetFetchSuccess,
  checklistAssetFetchError,
  checklistAssetFetchIndex,
} from './checklistAsset'

export {
  checklistArchiveIsFetching,
  checklistArchiveFetchSuccess,
  checklistArchiveFetchError,
  createdDateChanged,
  concludedDateChanged,
  fetchAssets as fetchArchiveAssets,
  fetchModels as fetchArchiveModels,
  checklistArchiveFetchAssetsError,
  checklistArchiveFetchAssetsSuccess,
  printArchive,
} from './checklistArchive'

export { printTodaySummary, printDaySummary } from './checklistSummary'

export {
  fetchPendingValidations,
  fetchAssets as fetchValidationAssets,
  fetchModels as fetchValidationModels,
} from './checklistValidation'

export { checklistAssetResetHoursFetchIndex, checklistAssetResetHours } from './checklistAssetResetHours'
