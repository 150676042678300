import { AnyAction } from 'redux'
// import { ChecklistInstance } from '../../types'
import { StateProps as EditStateProps } from '../../ui/components/views/ChecklistEdit/ChecklistEdit'

const editInitialState = {
  checklist: null,
  error: null,
  fetching: false,
  modified: false,
  validating: false,
  componentValidations: [],
}

export const checklistEdit = (state: EditStateProps = editInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      return { ...state }
    case 'CHECKLIST_EDIT_IS_FETCHING':
      return {
        ...state,
        error: null,
        fetching: true,
        modified: false,
      }
    case 'CHECKLIST_EDIT_FETCH_SUCCESS':
      return {
        ...state,
        checklist: action.payload || state.checklist,
        error: null,
        fetching: false,
        modified: false,
      }
    case 'CHECKLIST_EDIT_FETCH_ERROR':
      return {
        ...state,
        error: action.payload,
        fetching: false,
        modified: false,
      }
    case 'CHECKLIST_EDIT_IS_MODIFIED':
      return {
        ...state,
        modified: action.payload,
      }
    case 'CHECKLIST_EDIT_IS_VALIDATING':
      return {
        ...state,
        validating: action.payload,
      }
    case 'CHECKLIST_EDIT_IS_UPDATING_COMPONENT':
      return {
        ...state,
        error: null,
      }
    case 'CHECKLIST_EDIT_ADD_COMPONENT_VALIDATION':
      return {
        ...state,
        componentValidations: [...state.componentValidations, action.payload],
      }
    case 'CHECKLIST_EDIT_REMOVE_COMPONENT_VALIDATION':
      const componentIndex = state.componentValidations.findIndex(c => c.componentId === action.payload)
      const newComponentValidations = [...state.componentValidations]
      if (componentIndex > -1) {
        newComponentValidations.splice(componentIndex, 1)
      }
      return {
        ...state,
        componentValidations: newComponentValidations,
      }
    default:
      return state
  }
}
