import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";

interface OwnState {
  intervalId: any | null
  lastRefresh: number | null
}

class RunningTotals extends React.Component<WithTranslation, OwnState> {
  public constructor(props: WithTranslation) {
    super(props)
    this.state = {
      intervalId: null,
      lastRefresh: null,
    }
    this.getCurrentTotal = this.getCurrentTotal.bind(this)
    this.updateLastRefresh = this.updateLastRefresh.bind(this)
  }

  public componentDidMount() {
    this.setState({ intervalId: setInterval(this.updateLastRefresh, 1000) })
  }

  public componentWillUnmount() {
    clearInterval(this.state.intervalId as NodeJS.Timer)
  }

  private updateLastRefresh() {
    this.setState({ lastRefresh: Date.now() })
  }

  private getCurrentTotal(inputs: NodeList) {
    let total = 0
    inputs.forEach((input: any) => (total += parseInt(input.value, 10)))
    return total
  }

  public render() {
    const totalInputs = document.querySelectorAll('.number-calculator-component [data-name="total"]')
    const currentTotal = this.getCurrentTotal(totalInputs)
    if (totalInputs.length === 0) {
      return null
    }
    return (
      <div className="mt-3 mb-3">
        <Column xs={12}>
          <div className={"text-right"} >
            {this.props.t('checklist.edit.total')} <strong>{currentTotal}</strong>
          </div>
        </Column>
      </div>
    )
  }
}

export default withTranslation()(RunningTotals)
