import { AnyAction } from 'redux'
import { ChecklistAssetStateProps } from '../../types'

const indexInitialState = {
  assets: [],
  error: null,
  fetching: false,
}

export const checklistAsset = (state: ChecklistAssetStateProps = indexInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'CHECKLIST_ASSET_IS_FETCHING':
      return Object.assign(
        {},
        {
          ...state,
          error: null,
          fetching: true,
        }
      )
    case 'CHECKLIST_ASSET_FETCH_SUCCESS':
      return Object.assign(
        {},
        {
          ...state,
          assets: action.payload,
          error: null,
          fetching: false,
        }
      )
    case 'CHECKLIST_ASSET_FETCH_ERROR':
      return Object.assign(
        {},
        {
          ...state,
          error: action.payload,
          fetching: false,
        }
      )
    default:
      return state
  }
}
