import * as React from 'react'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import {
  checklistUpdateComponent,
  removeComponentValidation,
  addComponentValidation,
  isValidating,
} from '../../../../redux/actions/checklistEdit'

export interface ComponentData {
  id: string
  optional: boolean
  type: {
    type: string
    value: number
  }
  defaultValue: number
  lastValue: number
}

export interface DispatchProps {
  isValidating: (validating: boolean) => Function
  addComponentValidation: (validation: { componentId: string; errors: string[] }) => Function
  removeComponentValidation: (componentId: string) => Function
  updateComponent: (checklistInstanceId: string, componentId: string, value: number, conclude: boolean) => Promise<any>
}

export interface OwnProps {
  componentData: ComponentData
  checklistInstanceId: string
  checkId: string
  disabled: boolean
  collapseHandler?: Function
}

export interface OwnState {
  currentValue: number
  loading: boolean
}

export type Props = DispatchProps & OwnProps & WithTranslation

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    isValidating: validating => dispatch(isValidating(validating)),
    addComponentValidation: validation => dispatch(addComponentValidation(validation)),
    removeComponentValidation: componentId => dispatch(removeComponentValidation(componentId)),
    updateComponent: (checklistInstanceId, componentId, value, conclude) =>
      dispatch(checklistUpdateComponent(checklistInstanceId, componentId, value, conclude)),
  }
}

export class AssetHoursComponent extends React.Component<Props, OwnState> {
  public inputRef: React.RefObject<HTMLInputElement>
  public inputInvalidFeedbackRef: React.RefObject<HTMLDivElement>

  constructor(props: Props) {
    super(props)
    this.inputRef = React.createRef()
    this.inputInvalidFeedbackRef = React.createRef()
    const lastValue = props.componentData.lastValue
    const defaultValue = props.componentData.defaultValue
    this.state = {
      loading: false,
      currentValue: lastValue || defaultValue,
    }
    this.updateComponent = this.updateComponent.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.manageCollapsable()
  }

  private manageCollapsable() {
    if (
      this.props.componentData.lastValue !== this.props.componentData.defaultValue &&
      this.props.componentData.optional &&
      this.props.collapseHandler
    ) {
      this.props.collapseHandler()
    }
  }

  private handleChange(event: any) {
    event.persist()
    this.setState(prevState => {
      const value = parseInt(event.target.value, 10)
      return { ...prevState, currentValue: value }
    })
  }

  private updateComponent(event: any) {
    event.persist()
    const value = parseInt(event.target.value, 10)
    if (isNaN(value)) {
      this.setState(prevState => {
        return { ...prevState, currentValue: 0 }
      })
      return
    }
    this.setState(
      prevState => ({ ...prevState, currentValue: value, loading: true }),
      async () => {
        try {
          this.props.isValidating(true)
          this.props.removeComponentValidation(this.props.componentData.id)
          this.inputRef.current!.classList.remove('is-invalid')
          await this.props.updateComponent(
            this.props.checklistInstanceId,
            this.props.componentData.id,
            this.state.currentValue,
            false
          )
        } catch (error:any) {
          this.props.addComponentValidation({ componentId: this.props.componentData.id, errors: error.errors })
          this.inputRef.current!.classList.add('is-invalid')
          this.inputInvalidFeedbackRef.current!.innerHTML = error.errors[this.props.componentData.id].toString()
        } finally {
          this.props.isValidating(false)
          this.setState(prevState => {
            return { ...prevState, loading: false }
          })
        }
      }
    )
  }

  public render() {
    return (
      <div className="collapse cl-controls-optional cl-controls-optional-1 show">
        <div className="form-group text-left">
          <label htmlFor={`${this.props.componentData.id}-value`}>
            {this.props.t('checklist.components.assetHours.value')}
          </label>
          <input
            ref={this.inputRef}
            type="number"
            min="0"
            step="1"
            id={`${this.props.componentData.id}-value`}
            className="form-control"
            placeholder={this.props.t('checklist.components.assetHours.value')}
            onChange={this.handleChange}
            onBlur={this.updateComponent}
            value={this.state.currentValue}
            disabled={this.state.loading || this.props.disabled}
          />
          <div ref={this.inputInvalidFeedbackRef} className="invalid-feedback" />
        </div>
      </div>
    )
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(AssetHoursComponent))
