// * -------------------------------- MODULE --------------------------------------
import { tableReducer, TableStore } from '../../ui/components/Table'

const componentsReducersMap = {
  tableReducer,
}

export default componentsReducersMap

export interface ComponentsReducers {
  tableReducer: TableStore
}
