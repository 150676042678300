import * as React from 'react'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { checklistUpdateComponent } from '../../../../redux/actions'

export interface ComponentData {
  id: string
  optional: boolean
  type: {
    type: string
    values: string[]
  }
  defaultValue: string
  lastValue: string
}

export interface StateProps {
  componentData: ComponentData
  checklistInstanceId: string
  checkId: string
}

export type UpdateComponentFunction = (
  checklistInstanceId: string,
  componentId: string | undefined,
  value: Array<{ subComponentName: string; value: number }> | string | number | undefined,
  conclude: boolean
) => Promise<any>

export interface DispatchProps {
  updateComponent: UpdateComponentFunction
}

export interface OwnProps {
  disabled: boolean
  collapseHandler?: Function
}

export interface OwnState {
  currentValue: string
  loading: boolean
}

export type Props = StateProps & DispatchProps & OwnProps & WithTranslation

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    updateComponent: (checklistInstanceId, componentId, value, conclude) =>
      dispatch(checklistUpdateComponent(checklistInstanceId, componentId, value, conclude)),
  }
}

export class TextComponent extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      currentValue: props.componentData.lastValue || props.componentData.defaultValue,
      loading: false,
    }
    this.updateComponent = this.updateComponent.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.manageCollapsable()
  }

  private manageCollapsable() {
    if (this.props.componentData.lastValue && this.props.componentData.optional && this.props.collapseHandler) {
      this.props.collapseHandler()
    }
  }

  private handleChange(event: any) {
    event.persist()
    this.setState(prevState => {
      return {
        ...prevState,
        currentValue: event.target.value,
      }
    })
  }

  private updateComponent(event: any) {
    event.persist()
    this.setState(
      prevState => {
        return {
          ...prevState,
          currentValue: event.target.value,
          loading: true,
        }
      },
      () => {
        this.props
          .updateComponent(this.props.checklistInstanceId, this.props.componentData.id, this.state.currentValue, false)
          .then(() => {
            this.setState(prevState => {
              return {
                ...prevState,
                loading: false,
              }
            })
          })
      }
    )
  }

  public render() {
    return (
      <div className="collapse cl-controls-optional cl-controls-optional-1 show">
        <div className="form-group">
          <textarea
            className="form-control"
            id="exampleFormControlTextarea1"
            rows={3}
            value={this.state.currentValue}
            onChange={this.handleChange}
            onBlur={this.updateComponent}
            disabled={this.state.loading || this.props.disabled}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(TextComponent))
