import { API } from './index'
import { AnyAction, Dispatch } from 'redux'
import { ChecklistAssetItem, ChecklistModelFilter } from '../../types'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { SortingRule } from 'react-table'
import { fetchPendingValidations } from './checklistValidation'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'
import { Value } from '@mv-submodules/inplant-checklist-fe-iblu/types/configuration'

export const isFetching = (): AnyAction => {
  return { type: 'CHECKLIST_ASSET_PAGE_IS_FETCHING' }
}

export const fetchSuccess = (checklists: ChecklistAssetItem[]): AnyAction => {
  return {
    payload: checklists,
    type: 'CHECKLIST_ASSET_PAGE_FETCH_SUCCESS',
  }
}

export const fetchError = (error: Error): AnyAction => {
  return {
    payload: error,
    type: 'CHECKLIST_ASSET_PAGE_FETCH_ERROR',
  }
}

export const fetchListNoRedux = (unitSelected?: Value) => (dispatch: Dispatch) => {
  fetchPendingValidations()(dispatch) // update validation counter
  return API()
    .request(`/checklist-models-assets-by-user${unitSelected ? `?unit=${unitSelected.id}` : ''}`)
    .then((response: any) => response.map((item: ChecklistAssetItem) => new ChecklistAssetItem(item)))
    .catch((error: FetchError) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    })
}

export const startChecklist = (
  checklistModelId: string,
  assetId?: string
): ((dispatch: Dispatch<AnyAction>) => Promise<string>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch(isFetching())
    try {
      const response = await API().request(`/checklist/start/${checklistModelId}`, {
        method: 'POST',
        headers: assetId ? { 'content-type': 'application/json' } : undefined,
        body: assetId ? JSON.stringify({ assetId }) : undefined,
      })
      return response.checklistInstanceId
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(fetchError(new Error(error)))
      throw new Error(error)
    }
  }
}

export const quickConclude = (items: Set<ChecklistAssetItem>): ((dispatch: Dispatch<AnyAction>) => Promise<any>) => {
  return async dispatch => {
    dispatch(isFetching())
    try {
      await API().request('/quick-conclude', {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(
          Array.from(items).map(item => ({
            checklistModelId: item.model.id,
            assetId: item.asset ? item.asset.id : null,
          }))
        ),
      })
      return true
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(fetchError(new Error(error)))
      throw error
    }
  }
}

export const persistTableState = (params: {
  page?: number
  pageSize?: number
  sorted?: SortingRule[]
  filtered?: ChecklistModelFilter
}): AnyAction => ({
  type: 'CHECKLIST_ASSET_PAGE_PERSIST_TABLE_STATE',
  payload: params,
})
