import { Value } from '@mv-submodules/inplant-checklist-fe-iblu/types/configuration'
import { ActionCreator } from 'redux'

export const CHANGE_UNIT_SELECTED = 'CHANGE_UNIT_SELECTED'

interface ChangeUnitSelected {
  type: typeof CHANGE_UNIT_SELECTED
  payload: Value
}

export const changeUnitSelected: ActionCreator<ChangeUnitSelected> = (value: Value): ChangeUnitSelected => {
  return {
    type: CHANGE_UNIT_SELECTED,
    payload: value,
  }
}

export type FiltersActionTypes = ChangeUnitSelected 
