// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import { useEffect, useState } from 'react'

// * -------------------------------- MODULE --------------------------------------
import Button from '../Buttons/Button'
import Flex, { AlignItems } from '../Flex/Flex'
import Input from '../Input/Input'
import Modal from '../Modal/Modal'
import Tree, { TreeMultiSelectProps } from '../Tree/Tree'
import { IFlexItem } from '../Flex/FlexItem'
import { Icon } from '../../../services/icon'
import { LightNode } from '../Tree/types'
import { WithTranslation } from '../../../types/base'
import { getNodeFromString } from '../Tree/function'

export interface TreeFilterProps extends Partial<Pick<TreeMultiSelectProps, 'data'>> {
  hideInfoFeedback?: boolean
  label?: string
  kind: 'tree'
  buttonLabel?: string
  buttonIcon?: Icon
  placeholder?: string
} 

export type TreeComponents = Omit<TreeMultiSelectProps,'data'> & TreeFilterProps & IFlexItem

const TreeFilter = (props: TreeComponents & WithTranslation) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const { grow, shrink, basis } = props
  const { t } = props.translation
  const base = props.translation.base + '.treeFilter'

  const [showTreeFilter, setShowTreeFilter] = useState(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [valuesSelected, setValueSelected] = useState<LightNode[]>([])

  useEffect(() => {
    setInputValue(getValue(props.initialSelected))
  }, [props.data])

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- BLoS --------------------------------------
  // * ----------------------------------------------------------------------------------------
  function getValue(values: Array<string | LightNode> | undefined): string {
    const { data } = props
    if(data === undefined) {
      return ''
    }
    return (
      values
        ?.map(iS => {
          if (typeof iS === 'string') {
            return getNodeFromString(data, iS)?.label || ''
          }
          return iS?.label || ''
        })
        .join(', ') || ''
    )
  }

  const handleClickModal = () => {
    setShowTreeFilter(prev => !prev)
  }

  const resetValues = () => {
    setValueSelected([])
    props.onChange?.([])
    setInputValue('')
  }

  const updateValues = () => {
    props.onChange?.(valuesSelected)
    setInputValue(getValue(valuesSelected))
  }

  const onChangeTreeNodes = (nodes: LightNode[]) => {
    setValueSelected(nodes)
  }

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  return (
    <>
      {showTreeFilter && props.data !== undefined && (
        <Modal
          disableModalHeader={true}
          onClose={handleClickModal}
          visible={true}
          closeButtonLabel={t(`${base}.close`)}
          verticalPosition={'top-center'}
          rightFooterContents={[
            {
              label: t(`${base}.apply`),
              type: 'button',
              kind: 'button',
              semantic: 'primary',
              onClick: () => {
                updateValues()
                handleClickModal()
              },
            },
          ]}
        >
          <Tree {...props} data={props.data} selection={'MultiSelect'} onChange={onChangeTreeNodes} />
        </Modal>
      )}
      <Flex key={`filter_${props.id}`} alignItems={AlignItems.end} grow={grow} shrink={shrink} basis={basis}>
        <Input
          initialValue={inputValue}
          overrideValue={true}
          kind={'input'}
          label={props.label}
          readonly={true}
          placeholder={props.placeholder}
          hideInfoFeedback={props.hideInfoFeedback}
          grow={1}
          customAppendIcon={{ icon: 'times-circle', onClick: resetValues }}
        />
        <Button
          semantic={'secondary'}
          isDisable={props.data === undefined}
          grow={0}
          label={props.buttonLabel}
          icon={props.buttonIcon}
          onClick={handleClickModal}
        />
      </Flex>
    </>
  )
}

export default TreeFilter
