import { AnyAction } from 'redux'
import { Asset, ChecklistInstance, ChecklistModel } from '../../types'
import { SortingRule, Filter } from 'react-table'

export interface ChecklistValidationStateProps {
  fetching: boolean
  error: Error | null
  checklists: ChecklistInstance[]
  assets: Asset[]
  models: ChecklistModel[]
  page: number
  pageSize: number
  sorted: SortingRule[]
  filtered: Filter[]
  validationsCount: number
}

const initialState = {
  checklists: [],
  error: null,
  fetching: false,
  assets: [],
  models: [],
  page: 0,
  pageSize: 50,
  sorted: [],
  filtered: [],
  validationsCount: 0,
}

export const checklistValidation = (state: ChecklistValidationStateProps = initialState, action: AnyAction) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      try {
        return action.payload.checklist.validation
      } catch {
        return { ...state }
      }
    case 'CHECKLIST_VALIDATION_IS_FETCHING':
      return {
        ...state,
        error: null,
        fetching: true,
      }
    case 'CHECKLIST_VALIDATION_IS_VALIDATING':
      return {
        ...state,
        error: null,
        fetching: true,
      }
    case 'CHECKLIST_VALIDATION_FETCH_SUCCESS':
      return {
        ...state,
        checklists: action.payload,
        error: null,
        fetching: false,
      }
    case 'CHECKLIST_VALIDATION_VALIDATE_SUCCESS':
      return {
        ...state,
        error: null,
        fetching: false,
      }
    case 'CHECKLIST_VALIDATION_FETCH_ASSETS_SUCCESS':
      return {
        ...state,
        assets: action.payload,
        // error: null,
        // fetching: false,
      }
    case 'CHECKLIST_VALIDATION_FETCH_MODELS_SUCCESS':
      return {
        ...state,
        models: action.payload,
        // error: null,
        // fetching: false,
      }
    case 'CHECKLIST_VALIDATION_FETCH_ERROR':
      return {
        ...state,
        error: action.payload,
        fetching: false,
      }
    case 'CHECKLIST_VALIDATION_VALIDATE_ERROR':
      return {
        ...state,
        error: action.payload,
        fetching: false,
      }
    case 'CHECKLIST_VALIDATION_FETCH_ASSETS_ERROR':
      return {
        ...state,
        assets: [],
        // error: action.payload,
        // fetching: false,
      }
    case 'CHECKLIST_VALIDATION_FETCH_MODELS_ERROR':
      return {
        ...state,
        models: [],
        // error: action.payload,
        // fetching: false,
      }
    case 'CHECKLIST_VALIDATION_PERSIST_TABLE_STATE':
      return {
        ...state,
        page: action.payload.page !== undefined ? action.payload.page : state.page,
        pageSize: action.payload.pageSize !== undefined ? action.payload.pageSize : state.pageSize,
        sorted: action.payload.sorted || state.sorted,
        filtered: action.payload.filtered || state.filtered,
      }
    case 'CHECKLIST_VALIDATION_UPDATE_COUNTER':
      return {
        ...state,
        validationsCount: action.payload,
      }
    case 'CHECKLIST_VALIDATION_DECREMENT_COUNTER':
      return {
        ...state,
        validationsCount: state.validationsCount - 1,
      }
    default:
      return state
  }
}
