import { API } from './index'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { GiaDetails, GiaListItem } from '../../types/gia'
import { Dispatch } from 'redux'

export const fetchList = async (): Promise<GiaListItem[]> => {
  try {
    const list = await API().request(`/gia/concluded`)
    return list.reverse()
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      logoutUser()
    }
    throw error
  }
}

export const fetchDetails = async (id: string): Promise<GiaDetails> => {
  try {
    const details = await API().request(`/gia/details/${id}`)
    return details
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      logoutUser()
    }
    throw error
  }
}

export const printReport = async (id: string): Promise<Blob> => {
  try {
    const blob = await API().request(`/reports/gia/${id}`)
    return blob
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      logoutUser()
    }
    throw error
  }
}

export const materialGiaListImagesId = (giaId: string) => async (dispatch: Dispatch): Promise<void> => {
  return API().request(`/gia/material-images/${giaId}`).then((result: any) => result).catch((error: any) => {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      logoutUser()
    }
    throw error
  })
}

export const imageUrl = (giaId: string, imageId: string) => async (dispatch: Dispatch): Promise<string> => {
  return API().request(`/gia/material-images/${giaId}/${imageId}`).then((result: any) => result).catch((error: any) => {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      logoutUser()
    }
    throw error
  })
}