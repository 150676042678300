import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

interface OwnProps {
  attributes: Array<{ name: string; value: string }> | null
}

export type Props = OwnProps & WithTranslation

class SectionAttributes extends React.Component<Props> {
  public constructor(props: Props) {
    super(props)
  }

  public render() {
    if (!this.props.attributes) {
      return null
    }
    return (
      <div className="section-attributes">
        {this.props.attributes.map((attribute, index) => (
          <div key={index} className="text-right mt-3">
            {attribute.name}: <strong>{attribute.value || 0}</strong>
          </div>
        ))}
      </div>
    )
  }
}

export default withTranslation()(SectionAttributes)
