import * as React from 'react'
import { API } from '../../../../redux/actions'
import { InfoImage } from './Image'
import { IconSize } from '../../../../../inplant-components-fe/services/icon'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

export interface OwnProps {
  className?: string
  path: string
  iconSize?: IconSize
  onImageLoaded: (infoImage:Partial<InfoImage>) => void
}

export interface WithObserverProps {
  className?: string
  iconSize?: IconSize
  src: string
  onError: (event: React.SyntheticEvent<any>) => void
}

export interface OwnState {
  imageRef: React.RefObject<any>
  isFetching: boolean
  hasError: boolean
  fetchController: AbortController
  observer: IntersectionObserver | null
  src?: string
}

function withObserver<P extends object>(WrappedComponent: React.ComponentType<P>) {
  type HocProps = P & OwnProps
  // type ChildProps = P & WithObserverProps

  return class extends React.Component<HocProps, OwnState> {
    constructor(props: HocProps) {
      super(props)
      this.state = {
        imageRef: React.createRef(),
        isFetching: true,
        hasError: false,
        fetchController: new AbortController(),
        observer: new IntersectionObserver(([entry]) => {
          if (entry.isIntersecting && this.state.isFetching) {
            this.getURL()
              .then(src =>
                this.setState(currentState => {
                  if (currentState.observer && currentState.imageRef.current) {
                    currentState.observer.unobserve(currentState.imageRef.current)
                  }
                  return {
                    ...currentState,
                    isFetching: false,
                    src,
                    observer: null,
                  }
                })
              )
              .catch(this.handleError)
          }
        }),
      }
      this.getURL = this.getURL.bind(this)
      this.handleError = this.handleError.bind(this)
    }

    public componentDidMount() {
      this.state.observer!.observe(this.state.imageRef.current)
    }

    public componentWillUnmount() {
      this.state.fetchController.abort()
      if (this.state.observer && this.state.imageRef.current) {
        this.state.observer.unobserve(this.state.imageRef.current)
      }
    }

    public render() {
      const { imageRef, isFetching, src, hasError } = this.state
      const { className, iconSize = 'sm' } = this.props
      const innerProps = {
        ...(this.props as object),
        className,
        iconSize,
        src: src as string,
        onError: this.handleError,
      } as any

      return isFetching ? (
        <div ref={imageRef} className={`d-flex justify-content-center align-items-center ${className}`}>
          <IconComponent icon={'circle-notch'} size={iconSize} spin={true} />
        </div>
      ) : hasError ? (
        <div className={`d-flex justify-content-center align-items-center ${className}`}>
          <IconComponent icon={'eye-slash'} size={iconSize} />
        </div>
      ) : (
        <WrappedComponent {...innerProps} />
      )
    }

    public async getURL() {
      const result: Blob = await API().request(this.props.path, {
        signal: this.state.fetchController.signal,
      })
      return URL.createObjectURL(result)
    }

    public handleError(error: React.SyntheticEvent<any>) {
      console.warn('Image fetch error', this.props.path, error) // tslint:disable-line

      this.setState({
        isFetching: false,
        hasError: true,
      })
    }
  }
}

export default withObserver
