import * as React from 'react'
import RadioComponent from '../../widgets/RadioComponent/RadioComponent'
import TextComponent from '../../widgets/TextComponent/TextComponent'
import FileComponent from '../FileComponent/FileComponent'
import NumberCalculatorComponent from '../../widgets/NumberCalculatorComponent/NumberCalculatorComponent'
import NumberComponent from '../../widgets/NumberComponent/NumberComponent'
import SelectComponent from '../../widgets/SelectComponent/SelectComponent'
import AssetHoursComponent from '../../widgets/AssetHoursComponent/AssetHoursComponent'
import MultiFileComponent from '../../widgets/MultiFileComponent/MultiFileComponent'
import CalculatorComponent from '../../widgets/CalculatorComponent/CalculatorComponent'
import { ChecklistComponent } from '../../../../types'

export interface OwnProps {
  checklistInstanceId: string
  checkId: string
  disabled: boolean
  components: ChecklistComponent[]
  className?: string
  collapseHandler?: Function
}

export type Props = OwnProps

export default function ComponentGroup(props: Props) {
  const componentTypes = {
    radio: RadioComponent,
    text: TextComponent,
    file: FileComponent,
    numberCalculator: NumberCalculatorComponent,
    number: NumberComponent,
    select: SelectComponent,
    assetHours: AssetHoursComponent,
    multiFile: MultiFileComponent,
    calculator: CalculatorComponent,
  }
  return (
    <div className={`component-group ${props.className}`}>
      {props.components.map(component => {
        const ComponentName = componentTypes[component.type.type]
        if (!ComponentName) {
          return null
        }
        return (
          <ComponentName
            key={component.id + props.checkId}
            componentData={component as any}
            checklistInstanceId={props.checklistInstanceId}
            checkId={props.checkId}
            disabled={props.disabled}
            collapseHandler={props.collapseHandler}
          />
        )
      })}
    </div>
  )
}
