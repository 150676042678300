import { Dispatch } from 'redux'
import { manageRequestErrors } from '@mv-submodules/inplant-core-fe/functions'
import { API } from '@mv-submodules/inplant-checklist-fe-iblu/redux/actions/index'

export interface ProximityChecklist {
  checklistId: string
}

interface ProximityChecklistBE {
  checklist_id: string
}

export const getChecklistAssociatedToBeacon = (beaconId: string) => async (dispatch: Dispatch): Promise<ProximityChecklist> => {
  try {
    return API()
      .request(`/proximity-checklist/${beaconId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      },false,true)
      .then((result: ProximityChecklistBE) => {
        return ({ checklistId: result.checklist_id })
      })
  } catch (error) {
    manageRequestErrors(error, dispatch)
    throw error
  }
}
