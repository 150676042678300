import * as React from 'react'
import {ChecklistInstance} from '../../../../types'
import {withTranslation, WithTranslation} from 'react-i18next'
import TabulatedText from "@mv-submodules/inplant-components-fe/ui/components/Text/TabulatedText";
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper';

export interface OwnProps {
    checklist: ChecklistInstance
}

export type Props = OwnProps & WithTranslation

export function ChecklistMeta(props: Props) {
    if (!props.checklist.isConcluded()) {
        return null
    }
    const {user, createdDate, concludedDate} = props.checklist
    if (!user && !createdDate && !concludedDate){
        return  null
    }
    return (
            <TabulatedText
                data={[
                    ...(user && [{label: props.t('checklist.edit.user'), value: user.displayName}] || []),
                    ...(createdDate && [{label: props.t('checklist.edit.createdDate'), value: mvDate.getDateFromStringWithFormatting(createdDate, 'dd/MM/yyyy HH:mm')}] || []),
                    ...(concludedDate && [{label: props.t('checklist.edit.concludedDate'), value: mvDate.getDateFromStringWithFormatting(concludedDate, 'dd/MM/yyyy HH:mm')}] || []),
                ]}
            />
        )
}

export default withTranslation()(ChecklistMeta)
