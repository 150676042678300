// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import Button, { ButtonProps } from '../Buttons/Button'
import ButtonWithConfirm, { ButtonWithConfirmProps } from '../Buttons/ButtonWithConfirm'
import { ButtonVariants } from '../Buttons/types'
import { Kind } from '../../../types/kind'
import { useComponentsTranslation } from '../../../services/translation'
import WithPortal, {
  WithPortalProps,
} from '@mv-submodules/mvlabs-components-fe/ui/components/Modal/WithPortal'

export type BaseButton = ButtonProps & Kind<'button'>
export type ConfirmButton = ButtonWithConfirmProps & Kind<'confirmButton'>

export type Buttons = BaseButton | ConfirmButton

export interface ModalProps {
  visible: boolean
  title?: string
  onClose: () => void
  closeOnFocusOut?: boolean
  children: React.ReactNode
  width?: 25 | 30 | 40 | 50 | 75 | 'fit-content'
  leftFooterContents?: Buttons[]
  disableCloseButton?: boolean
  rightFooterContents?: Buttons[]
  additionalHeaderContent?: React.ReactNode
  id?: string
  modalBodyBackground?: 'light' | 'dark'
  modalFooterBackground?: 'light' | 'dark'
  closeButtonLabel?: string
  disableTopCloseButton?: boolean
  disableModalHeader?: boolean
  closeButtonTabIndex?: number
  verticalPosition?: 'top' | 'top-center' | 'center' | 'end'
  horizontalPosition?: 'start' | 'center' | 'end'
  formModal?: {
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void | Promise<void>
  }
}

type Props = ModalProps & WithPortalProps

interface State {
  // necessary to manage the animation of the modal
  propVisible: boolean
  visible: boolean
}

class Modal extends React.Component<Props, State> {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  // opacity transition
  private ANIMATION_SHOW_MODAL = 150

  constructor(props: Props) {
    super(props)
    this.state = {
      propVisible: props.visible,
      visible: false,
    }
  }

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- STATE MANAGEMENT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  public componentDidMount() {
    if (this.props.visible) {
      this.handleShowModal(this.props.visible)
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.visible !== this.props.visible) {
      if (this.props.visible) {
        this.props.mountDivForOverlay()
      }
      this.handleShowModal(this.props.visible)
    }
  }

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- BLoS --------------------------------------
  // * ----------------------------------------------------------------------------------------
  /**
   * necessary to trigger (when modal change state from invisible to visible) `display:'block` earlier than classname `mv-show`
   * and (when modal change state from visible to invisible) classname `!mv-show` earlier than `display:none`
   */
  private handleShowModal = async (isVisible: boolean) => {
    if (isVisible) {
      this.setState({ propVisible: isVisible })
      setTimeout(() => {
        this.setState({ visible: isVisible })
      }, this.ANIMATION_SHOW_MODAL)
    } else {
      this.setState({ visible: isVisible })
      setTimeout(() => {
        this.setState({ propVisible: isVisible })
        this.props.clearOverlayContent()
        this.props.onClose()
      }, this.ANIMATION_SHOW_MODAL)
    }
  }

  private generateModalPosition = () => {
    return `mv-modal-dialog--${this.props.horizontalPosition}-${this.props.verticalPosition}`
  }

  private handleClose = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    await this.handleShowModal(false)
  }

  private handleClickOutside = async (event: any) => {
    event.persist()
    if (event.target.className === 'modal') {
      event.preventDefault()
    }
    if (this.props.closeOnFocusOut && event.currentTarget === event.target) {
      await this.handleShowModal(false)
    }
  }
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  private renderModal() {
    const props = this.props
    const state = this.state

    let showCloseButton = !props.disableCloseButton
    if (showCloseButton === undefined) {
      showCloseButton = true
    }

    return (
      <div
        className={`mv-modal mv-fade mv-fade--${this.props.horizontalPosition}-${this.props.verticalPosition} ${state.visible ? `mv-show` : ''}`}
        style={{ display: state.propVisible ? 'block' : 'none' }}
        role='dialog'
        id={props.id}
        onClick={this.handleClickOutside}
      >
        <div className={`mv-modal-dialog ${state.visible ? this.generateModalPosition() : ''}  vw-${props.width || 50}`}
             role='document'>
          <div className='mv-modal-content'>
            {!props.disableModalHeader && (
              <div className='mv-modal-header'>
                <h5 className='mv-modal-title'>
                  {props.title || ''} {props.additionalHeaderContent}{' '}
                </h5>
                {!props.disableTopCloseButton && (
                  <Button
                    semantic={'secondary'}
                    variant={ButtonVariants.outline}
                    data-dismiss='modal'
                    onClick={this.handleClose}
                    icon={'times'}
                  />
                )}
              </div>
            )}
            {<div
              className={`mv-modal-body ${props.modalBodyBackground ? `bg-${props.modalBodyBackground}` : ''}`}>{props.children}</div>}
            {((props.rightFooterContents || props.leftFooterContents || showCloseButton) && (
                <div
                  className={`mv-modal-footer ${props.modalFooterBackground ? `bg-${props.modalFooterBackground}` : ''}`}>
                  <div className={'mv-modal-footer__left'}>
                    {[
                      ...((showCloseButton && [
                          <CancelButton
                            tabIndex={props.closeButtonTabIndex}
                            label={props.closeButtonLabel}
                            onClick={this.handleClose}
                            key={0.1}
                          />,
                        ]) ||
                        []),
                      ...((props.leftFooterContents &&
                          props.leftFooterContents.map((b, idx) => (
                            <ModalButton {...b} key={`additional_button_${idx}`} />
                          ))) ||
                        []),
                    ]}
                  </div>
                  <div className={'mv-modal-footer__right'}>
                    {props.rightFooterContents &&
                      props.rightFooterContents.map((b, idx) => <ModalButton {...b} key={`additional_button_${idx}`} />)}
                  </div>
                </div>
              )) ||
              null}
          </div>
        </div>
      </div>
    )
  }

  public render = () => {

    return (
      this.props.formModal
      && <form onSubmit={this.props.formModal.onSubmit}>{this.renderModal()}</form>
      || this.renderModal())

  }
}

const CancelButton = (props: {
  tabIndex?: number
  label?: string
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}) => {
  const { onClick } = props
  const { t } = useComponentsTranslation()
  return (
    <ModalButton
      semantic={'secondary'}
      variant={ButtonVariants.outline}
      kind='button'
      onClick={onClick}
      label={props.label ? props.label : t(`components.modal.cancel`)}
      tabIndex={props.tabIndex}
    />
  )
}

const ModalButton = (props: Buttons) => {
  const { ...buttons } = props
  switch (buttons.kind) {
    case 'button':
      return <Button {...(buttons as ButtonProps)} />
    case 'confirmButton':
      return <ButtonWithConfirm {...(buttons as ButtonWithConfirmProps)} />
  }
}

const ModalHOC = WithPortal<ModalProps>(Modal)

export default ({ verticalPosition = 'top', horizontalPosition = 'center', ...props }: ModalProps) =>
  <ModalHOC
    {...props}
    divId={'modal-container'}
    children={props.children}
    verticalPosition={verticalPosition}
    horizontalPosition={horizontalPosition}
  />
