import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { ChecklistSectionCheck } from '../../../../types'
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'

export interface OwnProps {
  check: ChecklistSectionCheck
}

export type Props = OwnProps & WithTranslation

export class CheckFillingInfo extends React.Component<Props> {
  public render() {
    return (
      <div className="text-muted text-center">
        <div>
          {this.props.t('checklist.checks.compiledBy', {
            name: this.props.check.lastFilledByUser!.displayName,
          })}
        </div>
        <div>
          {this.props.t('checklist.checks.compiledTime', {
            time: mvDate.getDateFromStringWithFormatting(this.props.check.lastFilledAtTime!, 'yyyy-MM-dd HH:mm:ss'),
          })}
        </div>
      </div>
    )
  }
}

export default withTranslation()(CheckFillingInfo)
