import { AnyAction } from 'redux'
import { StateProps } from '../../ui/components/views/ChecklistIndex/ChecklistIndexPageView'
// import { ChecklistModel } from '../../types/checklists'

const indexInitialState = {
  checklistModels: [],
  error: null,
  fetching: false,
  page: 0,
  pageSize: 50,
  sorted: [],
  filtered: [],
}

export const checklistIndex = (state: StateProps = indexInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      try {
        return action.payload.checklist.index
      } catch {
        return { ...state }
      }
    case 'CHECKLIST_INDEX_IS_FETCHING':
      return {
        ...state,
        error: null,
        fetching: true,
      }
    case 'CHECKLIST_INDEX_FETCH_SUCCESS':
      return {
        ...state,
        checklistModels: action.payload,
        error: null,
        fetching: false,
      }
    case 'CHECKLIST_INDEX_FETCH_ERROR':
      return {
        ...state,
        error: action.payload,
        fetching: false,
      }
    case 'CHECKLIST_INDEX_PERSIST_TABLE_STATE':
      return {
        ...state,
        page: action.payload.page !== undefined ? action.payload.page : state.page,
        pageSize: action.payload.pageSize !== undefined ? action.payload.pageSize : state.pageSize,
        sorted: action.payload.sorted || state.sorted,
        filtered: action.payload.filtered || state.filtered,
      }
    default:
      return state
  }
}
