import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

interface Props {
  divId: string
}

export interface WithPortalProps {
  clearOverlayContent: () => void
  mountDivForOverlay: () => void
}

/// at init will be created (if already not exists) a div. This div will be used to create a React.portal

/// on dispose will be canceled the content
const WithPortal = <P extends object>(Component: React.ComponentType<P & WithPortalProps>) => {
  const WithPortalHoC: React.FC<P & Props> = function withDivMountingHoC(props) {

    const { divId } = props
    const [isDivMounted, setIsDivMounted] = useState<boolean>(false)

    useEffect(() => {
      mountDiv()

      return clearContentOverlay

    }, [])

    const setMounted = () => {
      setIsDivMounted(true)
    }

    function mountDiv() {
      const div = document.getElementById(divId)
      if (!div) {
        const modalDiv = document.createElement('div')
        modalDiv.id = divId
        document.body.appendChild(modalDiv)
      }
      window.requestAnimationFrame(setMounted)

    }

    function clearContentOverlay() {
      setIsDivMounted(false)
    }

    if (!isDivMounted) {
      return <></>
    }

    return ReactDOM.createPortal(
      <Component
        {...props}
        clearOverlayContent={clearContentOverlay}
        mountDivForOverlay={mountDiv}
      />,
      document.getElementById(divId) as Element,
    )
  }

  return WithPortalHoC
}

export default WithPortal