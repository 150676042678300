import * as React from 'react'
import { connect } from 'react-redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { withRouter, RouteComponentProps } from 'react-router'
import { checklistGetFile, checklistUpdateFile } from '../../../../redux/actions'
import MultiFileModal from './MultiFileModal'
import DeleteFileModal from './DeleteFileModal'
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";
import {Button} from "@mv-submodules/inplant-components-fe";
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

export interface FileData {
  path: string
  blob?: Blob
}

export interface ComponentData {
  id: string
  optional: boolean
  type: {
    type: string
    values: string[]
  }
  defaultValue: Array<{ file: string }>
  lastValue: Array<{ file: string }>
}

export interface StateProps {
  componentData: ComponentData
  checklistInstanceId: string
  checkId: string
}

export interface DispatchProps {
  getFile: (filePath: string, options?: { preview: boolean }) => Promise<Blob>
  updateFile: (componentId: string, file: File) => Promise<{ file: string }>
}

export interface OwnStateProps {
  loading: boolean
  files: FileData[]
  previewFilesError: boolean[]
  fileModalShow: boolean
  fileModalPath: string | undefined
  deleteFileModalShow: boolean
  deleteFileModalFilePath: string | undefined
}

export interface OwnProps extends RouteComponentProps<any> {
  disabled: boolean
  collapseHandler?: Function
}

export type Props = StateProps & DispatchProps & OwnProps & WithTranslation

const mapStateToProps = (state: any) => ({})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    getFile: (filePath, options) => dispatch(checklistGetFile(filePath, options)),
    updateFile: (componentId, file) => dispatch(checklistUpdateFile(componentId, file)),
  }
}

export class MultiFileComponent extends React.Component<Props, OwnStateProps> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
      files: [],
      previewFilesError: [],
      fileModalShow: false,
      fileModalPath: undefined,
      deleteFileModalShow: false,
      deleteFileModalFilePath: undefined,
    }
    this.getFiles = this.getFiles.bind(this)
    this.updateFiles = this.updateFiles.bind(this)
    this.handleFileModalClose = this.handleFileModalClose.bind(this)
    this.handleFileModalOpen = this.handleFileModalOpen.bind(this)
    this.handleDeleteFileModalClose = this.handleDeleteFileModalClose.bind(this)
    this.handleDeleteFileModalOpen = this.handleDeleteFileModalOpen.bind(this)
    this.manageCollapsable()
  }

  public componentDidMount() {
    if (this.props.componentData.lastValue.length > 0) {
      this.getFiles({ preview: true })
    }
  }

  private manageCollapsable() {
    if (
      this.props.componentData.lastValue.length > 0 &&
      this.props.componentData.optional &&
      this.props.collapseHandler
    ) {
      this.props.collapseHandler()
    }
  }

  private async getFiles(options?: { preview: boolean }) {
    this.setState(prevState => ({ ...prevState, loading: true }))
    const files: FileData[] = []
    const previewFilesError: boolean[] = []
    for (const fileObject of this.props.componentData.lastValue) {
      const fileBlob = await this.props.getFile(fileObject.file, options)
      if (fileBlob) {
        files.push({ path: fileObject.file, blob: fileBlob })
        previewFilesError.push(false)
      }
    }
    this.setState(prevState => ({ ...prevState, files, loading: false, previewFilesError }))
  }

  private async updateFiles(event: any) {
    event.persist()
    this.setState(prevState => ({ ...prevState, loading: true }))
    try {
      const files: FileData[] = []
      for (const file of event.target.files) {
        const response = await this.props.updateFile(this.props.componentData.id, file)
        files.push({ path: response.file, blob: file })
      }
      this.setState(prevState => ({ ...prevState, files: prevState.files.concat(files), loading: false }))
    } catch (error) {
      this.setState(prevState => ({ ...prevState, loading: false }))
    }
  }

  private handleFileModalOpen(filePath: string) {
    this.setState(prevState => ({ ...prevState, fileModalShow: true, fileModalPath: filePath }))
  }

  private handleFileModalClose() {
    this.setState(prevState => ({ ...prevState, fileModalShow: false }))
  }

  private handleDeleteFileModalOpen(filePath: string) {
    this.setState(prevState => ({ ...prevState, deleteFileModalShow: true, deleteFileModalFilePath: filePath }))
  }

  private async handleDeleteFileModalClose(reload: boolean = false) {
    const newFiles = this.state.files
    if (reload) {
      const deletedFileIndex = this.state.files.findIndex(file => file.path === this.state.deleteFileModalFilePath)
      newFiles.splice(deletedFileIndex, 1)
    }
    await this.setState(prevState => ({
      ...prevState,
      files: newFiles,
      deleteFileModalShow: false,
      deleteFileModalFilePath: undefined,
      loading: false,
    }))
  }

  public render() {
    return (
      <div className="multi-file-component">
        <MultiFileModal
          show={this.state.fileModalShow}
          onClose={this.handleFileModalClose}
          filePath={this.state.fileModalPath}
        />
        <DeleteFileModal
          show={this.state.deleteFileModalShow}
          onClose={this.handleDeleteFileModalClose}
          filePath={this.state.deleteFileModalFilePath}
        />
        {this.state.loading ? (
          <div className="d-flex justify-content-center">
            <IconComponent icon={'circle-notch'} className="spinner" spin={true} size="1x" />
          </div>
        ) : (
          <React.Fragment>
            <div className={'form-group ' + (this.props.disabled && 'hidden')}>
              <div className="custom-file">
                <input
                  type="file"
                  multiple={true}
                  className="custom-file-input"
                  id={`${this.props.componentData.id}-file`}
                  lang="it"
                  onChange={this.updateFiles}
                  disabled={this.props.disabled}
                />
                <label className="custom-file-label" htmlFor="customFileLang">
                  {this.props.t('checklist.components.browse')}
                </label>
              </div>
            </div>
            {this.state.files.length > 0 ? (
              <div className="d-flex flex-row align-items-center">
                {this.state.files.map((file, index) => (
                  <div className="multi-file-thumb">
                    {!this.props.disabled ? (
                      <Button
                        variant={"secondary"}
                        onClick={() => this.handleDeleteFileModalOpen(file.path)}
                        icon={"times"}
                      />
                    ) : null}
                    {this.state.previewFilesError[index] ? (
                      <div>
                        <div className="d-flex align-items-center justify-content-around border border-black">
                          <Column xs={1}>
                            <IconComponent icon={'exclamation-triangle'} />
                          </Column>
                          <Column xs={8}>
                            <div className="text-left align-items-center">
                              {this.props.t('checklist.components.previewImageError')}
                            </div>
                          </Column>
                        </div>
                      </div>
                    ) : (
                      <img
                        className="img-fluid mx-auto d-block img-thumbnail"
                        src={URL.createObjectURL(file.blob)}
                        onClick={() => this.handleFileModalOpen(file.path)}
                        onError={() => {
                          const previousPreviewFilesError = this.state.previewFilesError
                          previousPreviewFilesError[index] = true
                          this.setState(prevState => ({ ...prevState, previewFilesError: previousPreviewFilesError }))
                        }} //tslint:disable-line
                      />
                    )}
                  </div>
                ))}
              </div>
            ) : null}
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(MultiFileComponent))
)
