const en = {
  checklist: {
    navigation: {
      checklists: 'Checklists',
      checklistModels: 'Models',
      checklistArchive: 'Archive',
      checklistTodaySummary: 'Today Summary',
      checklistYesterdaySummary: 'Summary of yesterday',
      checklistValidations: 'Validations',
      checklistModelsAssets: 'Checklists/Assets',
      resetAssetHours: 'Reset Asset Hours',
      gia: 'GIA Archive',
      setup: 'Setup'
    },
    index: {
      title: 'Checklist Models',
      table: {
        noData: 'No data available',
        title: 'Name',
        edit: 'Edit',
        actions: '',
        compile: 'Compile',
      },
    },
    edit: {
      title: 'Checklist Editing',
      back: 'Go back',
      save_and_back: 'Save and go back',
      conclude: 'Conclude Checklist',
      nextStep: 'Confirm',
      print: 'Print',
      user: 'User',
      createdDate: 'Created',
      concludedDate: 'Concluded',
      addCheck: 'Add product',
      concludeTooltipText: 'Please scroll down the checklist and verify that every check is OK before concluding',
      deleteChecklistModalTitle: 'Delete Checklist',
      deleteChecklistInstructions: 'You are about to delete this checklist, are you sure?',
      deleteChecklist: 'Delete Checklist',
      errorMessage:
        'There are errors that need to be solved before going to next step. Please verify the checklist before proceeding.',
      total: 'Total:',
      validation: {
        title: 'Checklist validation',
        valid: 'Outcome',
        comment: 'Comment',
        photo: 'Photo',
        noPhoto: '(no photo)',
        validate: 'Validate Checklist',
        validatedBy: 'Validated by',
        notYetValidated: 'Not validated yet.',
      },
    },
    checks: {
      compiledBy: 'Compiled by {{ name }}',
      compiledTime: 'on {{ time }}',
      addCheckModalTitle: 'Add product',
      deleteCheckModalTitle: 'Delete Check',
      description: 'Description',
      addCheck: 'Add product',
      addCheckInstructions: 'What are you adding?',
      deleteCheck: 'Delete check',
      deleteCheckInstructions: 'Are you sure you want to delete the check?',
      back: 'Cancel',
      optionalChecks: 'Optional fields',
    },
    components: {
      browse: 'Browse',
      previewImageError: 'Preview not supported',
      back: 'Cancel',
      numberCalculator: {
        total: 'Total',
        producedBales: 'Prod.',
        pressedAgainBales: 'Press. Again',
        toReselectBales: 'Reselect',
        partial: 'Part.',
      },
      number: {
        value: 'Value',
      },
      assetHours: {
        value: 'Value',
      },
      multiFile: {
        viewer: 'File viewer',
        deleteFile: 'Delete file',
        deleteFileInstructions: 'Are you sure you want to delete the file?',
        shotAt: 'Shot date',
        missingShotAt: 'no date',
      },
    },
    asset: {
      title: 'Asset Selection',
      back: 'Go back',
      table: {
        noData: 'No data available',
        description: 'Description',
        select: 'Select',
        actions: '',
        compile: 'Compile',
      },
    },
    archive: {
      title: 'Checklist Archive',
      table: {
        title: 'Name',
        showAll: '-- All --',
        noData: 'No data available',
        previous: 'Previous',
        next: 'Next',
        loading: 'Loading...',
        page: 'Page',
        of: 'of',
        rows: 'rows',
        user: {
          displayName: 'User',
        },
        asset: {
          description: 'Asset',
          showAll: '-- All --',
        },
        createdDate: 'Started',
        createdDatePlaceholder: 'From date...',
        concludedDate: 'Concluded',
        concludedDatePlaceholder: 'To date...',
        invalidChecksCount: 'Failed checks',
        actions: '',
        edit: 'Edit',
        details: 'Details',
        lastValidation: {
          label: 'Last validation',
          none: '--',
        },
      },
    },
    todaySummary: {
      title: 'Today Summary',
      table: {
        title: 'Name',
        status: 'State',
        user: 'User',
        date: 'Date',
      },
      status: {
        open: 'Open',
        not_concluded: 'Not compiled',
        open_with_anomalies: 'Open',
        concluded: 'Compiled',
        concluded_with_anomalies: 'Compiled',
      },
    },
    yesterdaySummary: {
      title: 'Summary of yesterday',
      table: {
        title: 'Name',
        status: 'State',
        user: 'User',
        date: 'Date',
      },
      status: {
        open: 'Open',
        not_concluded: 'Not compiled',
        open_with_anomalies: 'Open',
        concluded: 'Compiled',
        concluded_with_anomalies: 'Compiled',
      },
    },
    validation: {
      title: 'Checklist Validations',
      table: {
        title: 'Name',
        showAll: '-- All --',
        noData: 'No data available',
        previous: 'Previous',
        next: 'Next',
        loading: 'Loading...',
        page: 'Page',
        of: 'of',
        rows: 'rows',
        user: {
          displayName: 'User',
        },
        asset: {
          description: 'Asset',
          showAll: '-- All --',
        },
        createdDate: 'Started',
        createdDatePlaceholder: 'From date...',
        concludedDate: 'Concluded',
        concludedDatePlaceholder: 'To date...',
        invalidChecksCount: 'Failed checks',
        actions: '',
        edit: 'Edit',
        details: 'Details',
      },
    },
    modelsAssets: {
      title: 'Checklists/Assets',
      goToModels: 'Other checklist',
      table: {
        modelTitle: 'Checklist',
        assetDescription: 'Asset',
        showAll: '-- All --',
        noData: 'No data available',
        previous: 'Previous',
        next: 'Next',
        loading: 'Loading...',
        page: 'Page',
        of: 'of',
        rows: 'rows',
        actions: '',
        compile: 'Compile',
        select: '',
        quickConclude: 'Quick conclude selected',
      },
    },
    resetHours: {
      title: 'Reset Asset Hours',
      table: {
        code: 'Code',
        asset: 'Asset',
        noData: 'No data available',
        previous: 'Previous',
        next: 'Next',
        loading: 'Loading...',
        page: 'Page',
        of: 'of',
        rows: 'rows',
        reset: 'Reset',
      },
      modal: {
        resetAssetHours: 'Assign Asset Hours',
        code: 'Code',
        asset: 'Asset',
        assetHours: 'Current hours',
        changeAssetHours: 'Assign hours',
        back: 'Cancel',
        reset: 'Reset',
      },
    },
    setup: {
      table: {
        title: 'Setup Checklist',
        noData: 'No data available',
        code: 'Machine Code',
        positionCode: 'Plant Code',
        description: 'Description',
        model: 'Model',
        brand: 'Brand',
        name: 'Name',
        serialNumber: 'Serial Number',
        status: 'Status',
        checklist: 'Checklist',
        searchPlaceholder: 'Search by code or model',
        searchAssetPlaceholder: 'Model',
        searchStatusPlaceholder: 'Status',
        actions: ''
      },
      modal: {
        title: 'Configure Asset',
        cancel: 'Cancel',
        save: 'Save'
      },
      statusLabels: {
        tobeconfigured: "TO BE CONFIGURED",
        deactivated: "DEACTIVATED",
        active: "ACTIVE AND CONFIGURED",
        tobevalidated: "TO BE VALIDATE",
        undefined: "UNDEFINED"
      },
      configure: 'CONFIGURE',
      filterAllStatus: 'All statuses',
      notifications: {
        saved: 'Configuration saved',
        notSaved: 'There was an error saving the configuration'
      },
    }
  },
  gia: {
    table: {
      date: 'Date',
      noData: 'No data available',
      user: 'User',
      createdDate: 'Created on',
      createdDatePlaceholder: 'From...',
      concludedDate: 'Concluded on',
      concludedDatePlaceholder: 'To...',
      details: 'Details',
    },
    section: {
      material: {
        description: 'Description',
        cer: 'CER',
        total: 'Total',
        images: 'IMG.'
      },
    },
  },
}

export default en
