import {API} from './index'
import {AnyAction, Dispatch} from 'redux'
import {Asset, AssetParams} from '../../types'
import {logoutUser} from '@mv-submodules/inplant-coreadapter-fe/auth'
import FetchError from "@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError";

export const checklistAssetIsFetching = (): AnyAction => {
  return {
    type: 'CHECKLIST_ASSET_IS_FETCHING',
  }
}

export const checklistAssetFetchSuccess = (assets: Asset[]): AnyAction => {
  return {
    payload: assets,
    type: 'CHECKLIST_ASSET_FETCH_SUCCESS',
  }
}

export const checklistAssetFetchError = (error: Error): AnyAction => {
  return {
    payload: error,
    type: 'CHECKLIST_ASSET_FETCH_ERROR',
  }
}

export const checklistAssetFetchIndex = (
  checklistModelId: string
): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async dispatch => {
    dispatch(checklistAssetIsFetching())
    try {
      const response = await API().request(`/checklist/assets/${checklistModelId}`)
      const list = response.map((asset: AssetParams) => new Asset(asset))
      dispatch(checklistAssetFetchSuccess(list))
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(checklistAssetFetchError(new Error(error)))
      throw error
    }
  }
}


export const checklistAssetFetchIndexNoRedux = (checklistModelId: string): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return dispatch => {
    return API().request(`/checklist/assets/${checklistModelId}`)
      .then((response: any) => response.map((asset: AssetParams) => new Asset(asset)))
      .catch((error: FetchError) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
        throw error
      })
  }
}