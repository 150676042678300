import { API } from './index'
import { AnyAction, Dispatch } from 'redux'
import { ChecklistSetupFilter, ChecklistSetupItem } from '../../types'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { fetchPendingValidations } from './checklistValidation'

export interface DispatchFetchListProps<T> {
  fetchData: (params?: ListParams, plant?: string) => Promise<T[]>,
  applyParams: (params: ListParams) => void
}

export interface ListParams extends  TableParams {
  search?: string
}

export interface Filter {
  id: string;
  value: any;
  pivotId?: string;
}

export interface SortingRule {
  id: string;
  desc: boolean;
}

export interface TableParams {
  filtered?: Filter[]
  page: number
  pageSize: number
  sorted?: SortingRule[]
}

export const isFetching = (): AnyAction => {
  return { type: 'CHECKLIST_SETUP_PAGE_IS_FETCHING' }
}

export const fetchSuccess = (checklists: ChecklistSetupItem[]): AnyAction => {
  return {
    payload: checklists,
    type: 'CHECKLIST_SETUP_PAGE_FETCH_SUCCESS',
  }
}

export const fetchError = (error: Error): AnyAction => {
  return {
    payload: error,
    type: 'CHECKLIST_SETUP_PAGE_FETCH_ERROR',
  }
}

export const fetchList = (): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async dispatch => {
    dispatch(isFetching())
    console.log('gere') //tslint:disable-line
    fetchPendingValidations()(dispatch) // update validation counter
    try {
      const response = await API().request('/assets-configurations')
      console.log(response) // tslint:disable-line
      const list = response.map((item: ChecklistSetupItem) => new ChecklistSetupItem(item))
      console.log(list) // tslint:disable-line
      dispatch(fetchSuccess(list))
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(fetchError(new Error(error)))
      throw error
    }
  }
}

export const persistTableState = (params: {
  page?: number
  pageSize?: number
  sorted?: SortingRule[]
  filtered?: ChecklistSetupFilter
}): AnyAction => ({
  type: 'CHECKLIST_SETUP_PERSIST_TABLE_STATE',
  payload: params,
})

export const applyParams = ( params: any ): AnyAction => {
  return {
    type: 'TITEC_SETUP_APPLY_PARAMS',
    params,
  }
}
