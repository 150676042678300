import * as React from 'react'
import { connect } from 'react-redux'
import { WithTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'
import { checklistGetFile, checklistUpdateFile } from '../../../../redux/actions'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

export interface ComponentData {
  id: string
  optional: boolean
  type: {
    type: string
    values: string[]
  }
  defaultValue: string
  lastValue: string
}

export interface StateProps {
  componentData: ComponentData
  checklistInstanceId: string
  checkId: string
}

export interface DispatchProps {
  getFile: Function
  updateFile: Function
}

export interface OwnStateProps {
  loading: boolean
  fileBlob: string | undefined
}

export interface OwnProps {
  disabled: boolean
  collapseHandler?: Function
}

export type Props = StateProps & DispatchProps & OwnProps & WithTranslation

const mapStateToProps = (state: any) => ({})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    getFile: (componentId: string) => dispatch(checklistGetFile(componentId)),
    updateFile: (componentId: string, file: File) => dispatch(checklistUpdateFile(componentId, file)),
  }
}

export class FileComponent extends React.Component<Props, OwnStateProps> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
      fileBlob: undefined,
    }
    this.getFile = this.getFile.bind(this)
    this.updateFile = this.updateFile.bind(this)
    this.manageCollapsable()
  }

  public componentDidMount() {
    if (this.props.componentData.lastValue) {
      this.getFile()
    }
  }

  private manageCollapsable() {
    if (this.props.componentData.lastValue && this.props.componentData.optional && this.props.collapseHandler) {
      this.props.collapseHandler()
    }
  }

  private async getFile() {
    this.setState(prevState => ({ ...prevState, loading: true }))
    try {
      const fileBlob = await this.props.getFile(this.props.componentData.id)
      this.setState(prevState => ({ ...prevState, fileBlob, loading: false }))
    } catch (error) {
      this.setState(prevState => ({ ...prevState, loading: false }))
    }
  }

  private async updateFile(event: any) {
    event.persist()
    this.setState(prevState => ({
      ...prevState,
      fileBlob: undefined,
      loading: true,
    }))
    try {
      await this.props.updateFile(this.props.componentData.id, event.target.files[0])
      this.setState(prevState => ({
        ...prevState,
        fileBlob: event.target.files[0],
        loading: false,
      }))
    } catch (error) {
      this.setState(prevState => ({
        ...prevState,
        fileBlob: undefined,
        loading: false,
      }))
    }
  }

  public render() {
    return (
      <div className="collapse cl-controls-optional cl-controls-optional-1 show">
        {this.state.loading ? (
          <div className="d-flex justify-content-center">
            <IconComponent icon={'circle-notch'} className="spinner" spin={true} size="1x" />
          </div>
        ) : (
          <div className="form-group">
            {this.state.fileBlob ? (
              <img className="img-fluid mx-auto d-block" src={URL.createObjectURL(this.state.fileBlob)} />
            ) : (
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id={`${this.props.componentData.id}-file`}
                  lang="it"
                  onChange={this.updateFile}
                  disabled={this.props.disabled}
                />
                <label className="custom-file-label" htmlFor="customFileLang">
                  {this.props.t('checklist.components.browse')}
                </label>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(FileComponent))
