import { AnyAction } from 'redux'
import { ChecklistAssetItem } from '../../types'
import { SortingRule, Filter } from 'react-table'

export interface ChecklistAssetPageStateProps {
  fetching: boolean
  error: Error | undefined
  list: ChecklistAssetItem[]
  page: number
  pageSize: number
  sorted: SortingRule[]
  filtered: Filter[]
}

const initialState = {
  fetching: false,
  error: undefined,
  list: [],
  page: 0,
  pageSize: 50,
  sorted: [],
  filtered: [],
}

export const checklistAssetPage = (state: ChecklistAssetPageStateProps = initialState, action: AnyAction) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      try {
        return action.payload.checklist.modelsAssets
      } catch {
        return { ...state }
      }
    case 'CHECKLIST_ASSET_PAGE_IS_FETCHING':
      return {
        ...state,
        error: undefined,
        fetching: true,
      }
    case 'CHECKLIST_ASSET_PAGE_FETCH_SUCCESS':
      return {
        ...state,
        list: action.payload,
        error: undefined,
        fetching: false,
      }
    case 'CHECKLIST_ASSET_PAGE_FETCH_ERROR':
      return {
        ...state,
        error: action.payload,
        fetching: false,
      }
    case 'CHECKLIST_ASSET_PAGE_PERSIST_TABLE_STATE':
      return {
        ...state,
        page: action.payload.page !== undefined ? action.payload.page : state.page,
        pageSize: action.payload.pageSize !== undefined ? action.payload.pageSize : state.pageSize,
        sorted: action.payload.sorted || state.sorted,
        filtered: action.payload.filtered || state.filtered,
      }
    default:
      return state
  }
}
