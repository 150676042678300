// * -------------------------------- NPM --------------------------------------
import React, { useState } from 'react'

// * -------------------------------- MODULE --------------------------------------
import Button, { ButtonProps } from './Button'
import Flex, { Direction } from '../Flex/Flex'
import Text from '../Text/Text'
import Alert from '../Alert/Alert'

export enum ButtonConfirmVariants {
  button = 'button',
  alert = 'alert',
}

interface ConfirmProps {
  labelConfirm: string
  buttonConfirm: ButtonProps
  buttonConfirmVarian?: ButtonConfirmVariants
  cancelButton?: ButtonProps
}

export type ButtonWithConfirmProps = ButtonProps & ConfirmProps
const ButtonWithConfirm: React.FC<ButtonWithConfirmProps> = ({
  buttonConfirmVarian = ButtonConfirmVariants.button,
  ...props
}) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const [confirm, setConfirm] = useState<boolean>(false)

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------

  function handleRenderConfirm() {
    if (buttonConfirmVarian === ButtonConfirmVariants.alert) {
      return (
        <Alert
          variant={'warning'}
          text={props.labelConfirm}
          actions={
            <>
              <Button
                {...(props.buttonConfirm as ButtonProps)}
                onClick={e => {
                  if (props.buttonConfirm.onClick) {
                    props.buttonConfirm.onClick(e)
                  }
                  setConfirm(false)
                }}
              />
              <Button
                {...(props.cancelButton as ButtonProps)}
                onClick={e => {
                  if (props.cancelButton && props.cancelButton.onClick) {
                    props.cancelButton.onClick(e)
                  }
                  setConfirm(false)
                }}
              />
            </>
          }
        />
      )
    }
    return (
      <>
        <Text text={props.labelConfirm} />
        <Button
          {...(props.buttonConfirm as ButtonProps)}
          onClick={e => {
            if (props.buttonConfirm.onClick) {
              props.buttonConfirm.onClick(e)
            }
            setConfirm(false)
          }}
        />
      </>
    )
  }

  return (
    <Flex direction={Direction.row}>
      {confirm ? handleRenderConfirm() : (
        <Button
          {...(props as ButtonProps)}
          onClick={e => {
            if (props.onClick) {
              props.onClick(e)
            }
            setConfirm(true)
          }}
        />
      )}
    </Flex>
  )
}

export default ButtonWithConfirm
