import { Filters } from '@mv-submodules/inplant-checklist-fe-iblu/types/filters'
import { Reducer } from 'redux'
import { AnyAction } from 'redux'

const filtersInitialState: Filters = {}

export const filters: Reducer<Filters, AnyAction> = (state = filtersInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      return {
        ...state,
        unitSelected: action.payload?.checklist?.filters?.unitSelected || state.unitSelected
      }
    case 'CHANGE_UNIT_SELECTED':
      return {
        ...state,
        unitSelected: action.payload,
      }
    default:
      return state
  }
}

export default filters
