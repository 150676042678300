import * as React from 'react'
import {connect} from 'react-redux'
import {withTranslation, WithTranslation} from 'react-i18next'
import {RouteComponentProps, withRouter} from 'react-router'
import {Asset, ChecklistInstance, ChecklistModel} from '../../../../types'
import {SortingRule, Filter} from 'react-table'
import {fetchValidationModels, fetchValidationAssets} from '../../../../redux/actions'
import 'react-datepicker/dist/react-datepicker.css'
import SmallScreenView from './SmallScreenView'
import {fetchPendingValidationsNoRedux, persistTableState} from '../../../../redux/actions/checklistValidation'
import {Filters, PageHeader, Table, TableRowButtonGroup} from "@mv-submodules/inplant-components-fe";
import FetchError from "@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError";
import MainPageContent from "@mv-submodules/inplant-components-fe/ui/components/Layout/MainPageContent";
import {FilterComponent} from "@mv-submodules/inplant-components-fe/ui/components/Filters/Filters";
import moment from "moment";
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

export interface StateProps {
  models: ChecklistModel[]
  assets: Asset[]
  page: number
  pageSize: number
  sorted: SortingRule[]
  filtered: Filter[]
}

export interface DispatchProps {
  fetchChecklists: () => Promise<any>
  fetchModels: () => Function
  fetchAssets: () => Function
  persistTableState: (params: { page?: number; pageSize?: number; sorted?: SortingRule[]; filtered?: Filter[] }) => void
}

export interface OwnState {
  viewportWidth: number
  isFetching: boolean
  fetchErrors: boolean
  checklists: ChecklistInstance[]
  filtered: Filter[]
}

export interface OwnProps extends RouteComponentProps<any> {
}

export type Props = StateProps & DispatchProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  models: state.checklist.validation.models,
  assets: state.checklist.validation.assets,
  page: state.checklist.validation.page,
  sorted: state.checklist.validation.sorted,
  filtered: state.checklist.validation.filtered,
  pageSize: state.checklist.validation.pageSize,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  fetchChecklists: () => dispatch(fetchPendingValidationsNoRedux()),
  fetchModels: () => dispatch(fetchValidationModels()),
  fetchAssets: () => dispatch(fetchValidationAssets()),
  persistTableState: ({page, pageSize, sorted, filtered}) =>
    dispatch(persistTableState({page, pageSize, sorted, filtered})),
})

export class ChecklistValidation extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      checklists: [],
      isFetching: false,
      fetchErrors: false,
      viewportWidth: window.innerWidth,
      filtered: this.props.filtered || []
    }
    this.handleChecklistClick = this.handleChecklistClick.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.handleOnTablePageChange = this.handleOnTablePageChange.bind(this)
    this.handleOnTablePageSizeChange = this.handleOnTablePageSizeChange.bind(this)
    this.handleOnTableSortedChange = this.handleOnTableSortedChange.bind(this)
    this.handleOnTableFilteredChange = this.handleOnTableFilteredChange.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.getFilterValue = this.getFilterValue.bind(this)
    this.paringStrings = this.paringStrings.bind(this)
  }

  public componentDidMount() {
    this.props.fetchModels()
    this.props.fetchAssets()
    this.fetchData()
    window.addEventListener('resize', this.handleResize)
  }

  private fetchData() {
    if (!this.state.isFetching) {
      this.setState({isFetching: true, fetchErrors: false})
      this.props.fetchChecklists()
        .then((checklists) => {
          this.setState({checklists})
          // TODO reset page if less elements
        })
        .catch((error: FetchError) => {
          this.setState({fetchErrors: true})
        })
        .finally(() => this.setState({isFetching: false}))
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  public handleChecklistClick(checklist: ChecklistInstance) {
    return this.props.history.push(`/checklist/edit/${checklist.id}?allowValidation=true`)
  }

  private handleResize() {
    this.setState(prevState => ({
      ...prevState,
      viewportWidth: window.innerWidth,
    }))
  }

  private handleOnTablePageChange(page: number) {
    this.props.persistTableState({page})
  }

  private handleOnTablePageSizeChange(pageSize: number) {
    this.props.persistTableState({pageSize})
  }

  private handleOnTableSortedChange(sorted: SortingRule[]) {
    this.props.persistTableState({sorted})
  }

  private handleOnTableFilteredChange(filtered: Filter[]) {
    this.setState({filtered}, () => this.props.persistTableState({filtered}))
  }

  private getFilterValue(name: string) {
    if (!this.state.filtered) {
      return null
    }
    const index = this.state.filtered.findIndex(filter => filter.id === name)
    if (index === -1) {
      return null
    }
    return this.state.filtered[index].value
  }

  private handleFilters(filter: { id: string, value: string | number | null }) {
    const filters = this.props.filtered
    const indexFilter = this.props.filtered.findIndex(fil => fil.id === filter.id)
    if (indexFilter >= 0) {
      filters[indexFilter] = filter
    } else {
      filters.push(filter)
    }
    this.handleOnTableFilteredChange(filters)
  }

  private paringStrings(value: string) {
    return value && value.toUpperCase().replace(" ", "") || undefined
  }

  public render() {
    const {isFetching, checklists} = this.state
    const filters: FilterComponent[] = [{
      disabled: isFetching || !checklists,
      name: 'title',
      id: 'checklist-title-select',
      type: 'Select',
      label: this.props.t('checklist.archive.table.title'),
      options: {
        items: this.props.models
          .sort((a: ChecklistModel, b: ChecklistModel) => a.title.localeCompare(b.title))
          .map((model: ChecklistModel) => ({
            value: model.title,
            label: model.title
          })),
        defaultOptionLabel: this.props.t('checklist.archive.table.showAll')
      },
      onChange: (name, value) => this.handleFilters({id: name, value}),
      value: this.getFilterValue('title'),
      customSize: 2
    }, {
      disabled: isFetching || !checklists,
      name: 'user.displayName',
      id: 'checklist-user-name',
      type: 'SearchInput',
      label: this.props.t('checklist.archive.table.user.displayName'),
      value: this.getFilterValue('user.displayName'),
      onChange: (name, value) => this.handleFilters({id: name, value}),
      customSize: 2
    }, {
      disabled: isFetching || !checklists,
      name: 'asset',
      id: 'checklist-asset-name',
      type: 'SearchInput',
      label: this.props.t('checklist.archive.table.asset.description'),
      value: this.getFilterValue('asset'),
      onChange: (name, value) => this.handleFilters({id: name, value}),
      customSize: 2
    }, {
      disabled: isFetching || !checklists,
      name: 'createdDate',
      id: 'checklist-created-date',
      type: 'Date',
      value: this.getFilterValue('createdDate'),
      label: this.props.t('checklist.archive.table.createdDate'),
      max: this.getFilterValue('concludedDate'),
      onChange: (name, value) => this.handleFilters({
        id: name,
        value: value ? mvDate.startOfDay(new Date(value)).toISOString() : null
      }),
      customSize: 2
    }, {
      disabled: isFetching || !checklists,
      name: 'concludedDate',
      id: 'checklist-concluded-date',
      type: 'Date',
      value: this.getFilterValue('concludedDate'),
      label: this.props.t('checklist.archive.table.concludedDate'),
      min: this.getFilterValue('createdDate'),
      onChange: (name, value) => this.handleFilters({
        id: name,
        value: value ? mvDate.endOfDay(new Date(value)).toISOString() : null
      }),
      customSize: 2
    }]
    const checklistList = checklists.filter(checklist => {
      const userFilter = this.paringStrings(this.getFilterValue('user.displayName'))
      const assetFilter = this.paringStrings(this.getFilterValue('asset'))
      const titleFilter = this.paringStrings(this.getFilterValue('title'))
      const createdFilter = this.getFilterValue('createdDate')
      const concludedFilter = this.getFilterValue('concludedDate')
      return (
        (!userFilter || (checklist.user && this.paringStrings(checklist.user.displayName)!.includes(userFilter))) &&
        (!assetFilter || (checklist.asset && this.paringStrings(`${checklist.asset.description}[${checklist.asset.code}]`)!.includes(assetFilter))) &&
        (!titleFilter || (checklist.title && this.paringStrings(checklist.title) === titleFilter)) &&
        (!createdFilter || (checklist.createdDate && moment(checklist.createdDate).isAfter(moment(createdFilter)))) &&
        (!concludedFilter || (checklist.concludedDate && moment(checklist.concludedDate).isBefore(moment(concludedFilter))))
      )
    })
    return (
      <React.Fragment>
        <PageHeader title={this.props.t('checklist.validation.title')}/>
        <MainPageContent>
          {this.state.viewportWidth > 768 ? (
            <>
              <Filters fields={filters}/>
              <Table
                page={this.props.page}
                onPageChange={this.handleOnTablePageChange}
                pageSize={this.props.pageSize}
                onPageSizeChange={this.handleOnTablePageSizeChange}
                // onSortedChange={this.handleOnTableSortedChange}
                isFetching={isFetching}
                manualIsFetching={true}
                defaultSorted={this.props.sorted}
                onFetchData={(state) => {
                  if (state.sorted !== this.props.sorted) {
                    this.handleOnTableSortedChange(state.sorted)
                  }
                }}
                showPagination={true}
                showPaginationTop={true}
                showPaginationBottom={true}
                // pages={this.props.pages}
                noDataText={this.props.t('checklist.validation.table.noData')}
                pageText={this.props.t('checklist.validation.table.page')}
                ofText={this.props.t('checklist.validation.table.of')}
                rowsText={this.props.t('checklist.validation.table.rows')}
                data={checklistList}
                columns={[
                  {
                    Header: this.props.t('checklist.validation.table.title'),
                    accessor: 'title',
                    minWidth: 180
                  },
                  {
                    Header: this.props.t('checklist.validation.table.user.displayName'),
                    accessor: 'user.displayName',
                    minWidth: 80,
                    filterMethod: (filter: any, row: any) => {
                      return true
                    },
                  },
                  {
                    Header: this.props.t('checklist.validation.table.asset.description'),
                    id: 'asset',
                    accessor: (checklist: ChecklistInstance) => {
                      if (checklist.asset) {
                        return `${checklist.asset.description} [${checklist.asset.code}]`
                      }
                      return ''
                    },
                    minWidth: 150,
                    Cell: data => {
                      if (data.original.asset) {
                        return `${data.original.asset.description} [${data.original.asset.code}]`
                      }
                      return null
                    }
                  },
                  {
                    Header: this.props.t('checklist.validation.table.createdDate'),
                    accessor: 'createdDate',
                    minWidth: 130,
                    Cell: data => mvDate.getDateFromStringWithFormatting(data.original.createdDate, 'dd/MM/yyyy HH:mm'),
                    sortMethod: (a, b) => {
                      if (mvDate.isAfter(mvDate.getDateFromString(a), mvDate.getDateFromString(b),'second')) {
                        return 1
                      }
                      if (mvDate.isBefore(mvDate.getDateFromString(a), mvDate.getDateFromString(b),'second')) {
                        return -1
                      }
                      return 0
                    },
                  },
                  {
                    Header: this.props.t('checklist.validation.table.concludedDate'),
                    accessor: 'concludedDate',
                    minWidth: 130,
                    Cell: data => mvDate.getDateFromStringWithFormatting(data.original.concludedDate, 'dd/MM/yyyy HH:mm'),
                    sortMethod: (a, b) => {
                      if (mvDate.isAfter(mvDate.getDateFromString(a), mvDate.getDateFromString(b),'second')) {
                        return 1
                      }
                      if (mvDate.isBefore(mvDate.getDateFromString(a), mvDate.getDateFromString(b),'second')) {
                        return -1
                      }
                      return 0
                    }
                  },
                  {
                    Header: this.props.t('checklist.validation.table.invalidChecksCount'),
                    accessor: 'invalidChecksCount',
                    filterable: false,
                    sortable: false,
                    maxWidth: 70,
                    Cell: data => {
                      if (data.original.invalidChecksCount > 0) {
                        return (
                          <React.Fragment>
                            {data.original.invalidChecksCount}
                            <IconComponent icon={'exlamation-triangle'} className="text-warning ml-1" size="1x"/>
                          </React.Fragment>
                        )
                      }
                      return null
                    },
                  },
                  {
                    Header: this.props.t('checklist.validation.table.actions'),
                    sortable: false,
                    filterable: false,
                    minWidth: 150,
                    Cell: data => (
                      <TableRowButtonGroup
                        buttons={[{
                          variant: 'secondary-alternate',
                          onClick: () => this.handleChecklistClick(data.original),
                          icon: "eye"
                        }]}
                      />
                    ),
                  },
                ]}
              />
            </>
          ) : (
            <SmallScreenView list={checklists} clickHandler={this.handleChecklistClick}/>
          )}
        </MainPageContent>
      </React.Fragment>
    )
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(ChecklistValidation))
)
