import { fetchConfiguration } from '@mv-submodules/inplant-checklist-fe-iblu/redux/actions/configuration'
import { Value } from '@mv-submodules/inplant-checklist-fe-iblu/types/configuration'
import React from 'react'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const UnitSelectorManager = <P extends object>(Component: React.ComponentType<P>) => {
  const UnitSelectorWrapper = (props: P) => {
    const dispatch = useDispatch()

    const units: Value[] = useSelector((state: any) => state.checklist.configuration.data.units)

    const unitSelected: Value | undefined =
      useSelector((state: any) => state.checklist.filters.unitSelected) || (units.length >= 1 ? units[0] : undefined)

    useEffect(() => {
      fetchConfiguration()(dispatch).catch(e => {
        console.warn(e) //tslint:disable-line
      })
    }, [])

    return <Component {...(props as P)} unitSelected={unitSelected} />
  }
  return UnitSelectorWrapper
}

export interface WithUnitSelector {
  unitSelected?: Value
}

export default UnitSelectorManager
