import { i18next, LANGUAGE_CONFIGURATION } from '../i18n/i18n'
import { getContainer, TYPES } from '../../mvlabs-components-fe/services/binding'
import {
  TranslationService, Translator,
} from '../../mvlabs-components-fe/services/translation'
import { mvDate } from '../../mvlabs-components-fe/functions/helpers'
import { consoleLog } from '../../mvlabs-components-fe/functions/logs'

function getTranslator(): Translator {

  const changeLanguage = async (newLanguage: string, callback: ((instance: Translator) => void)): Promise<any> => {
    await i18next.changeLanguage(newLanguage, () => {
      mvDate.setCurrentLanguage(newLanguage)
      callback({
        ...i18next,
        t: i18next.t,
        language: i18next.language,
        availableLanguages: LANGUAGE_CONFIGURATION.allowedLanguages,
        defaultLanguage: LANGUAGE_CONFIGURATION.defaultLanguage,
        changeLanguage,
      })
    })
    return
  }

  mvDate.setCurrentLanguage(i18next.language)
  consoleLog(i18next.language)

  return {
    ...i18next,
    t: i18next.t,
    language: i18next.language,
    availableLanguages: LANGUAGE_CONFIGURATION.allowedLanguages,
    defaultLanguage: LANGUAGE_CONFIGURATION.defaultLanguage,
    changeLanguage,
  }
}

function initTranslation(container: ReturnType<typeof getContainer>) {

  const translationService = new TranslationService(getTranslator())

  container
    .bind<TranslationService>(TYPES.TranslationService)
    .toConstantValue(translationService)
}

export default initTranslation
