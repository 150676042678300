import * as React from 'react'
import {connect} from 'react-redux'
import {withTranslation, WithTranslation} from 'react-i18next'
import {withRouter, RouteComponentProps} from 'react-router'
import {ChecklistInstance, ChecklistComponent} from '../../../../types'
import {
  checklistEditFetchChecklist,
  checklistUpdateComponent,
  checklistNextStep,
  printChecklist,
} from '../../../../redux/actions'
import ValidationComponent from '../../widgets/ValidationComponent/ValidationComponent'
import ChecklistSection from '../ChecklistEdit/ChecklistSection'
import ChecklistMeta from '../ChecklistEdit/ChecklistMeta'
import 'balloon-css/balloon.min.css'
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";
import {BackButton, Button} from "@mv-submodules/inplant-components-fe";
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

export type UpdateComponentFunction = (
  checklistInstanceId: string,
  componentId: string | undefined,
  value: Array<{ subComponentName: string; value: number }> | string | number | undefined,
  conclude: boolean
) => Promise<any>

export type NextStepFunction = (checklistInstanceId: string) => Promise<any>

export interface StateProps {
  fetching: boolean
  error: Error | null
  checklist: ChecklistInstance | null
  modified: boolean
  validating: boolean
  componentValidations: Array<{ componentId: string; errors: string[] }>
}

export interface DispatchProps {
  fetchChecklist: (id: string) => Promise<any>
  updateComponent: UpdateComponentFunction
  nextStep: NextStepFunction
  printChecklist: (checklistId: string) => Promise<Blob | null>
}

export interface OwnProps extends RouteComponentProps<any> {
  // t: TranslationFunction
}

export interface OwnState {
  loading: boolean
  downloading: boolean
  deleteChecklistModalShow: boolean
}

export interface ProximityData {
  beaconId: string
  minDistance: string
}

export type Props = StateProps & DispatchProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  fetching: state.checklist.edit.fetching,
  error: state.checklist.edit.error,
  checklist: state.checklist.edit.checklist,
  modified: state.checklist.edit.modified,
  validating: state.checklist.edit.validating,
  componentValidations: state.checklist.edit.componentValidations,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    fetchChecklist: id => dispatch(checklistEditFetchChecklist(id)),
    nextStep: checklistInstanceId => dispatch(checklistNextStep(checklistInstanceId)),
    updateComponent: (checklistInstanceId, componentId, value, conclude) =>
      dispatch(checklistUpdateComponent(checklistInstanceId, componentId, value, conclude)),
    printChecklist: checklistId => dispatch(printChecklist(checklistId)),
  }
}

export class ChecklistSummary extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
      downloading: false,
      deleteChecklistModalShow: false,
    }
    this.concludeChecklist = this.concludeChecklist.bind(this)
    this.nextState = this.nextState.bind(this)
    this.handlePrintChecklist = this.handlePrintChecklist.bind(this)
  }

  public async componentDidMount() {
    this.setState(prevState => ({...prevState, loading: true}))
    try {
      await this.props.fetchChecklist(this.props.match.params.checklistId)
      this.setState(prevState => ({
        ...prevState,
        checklist: this.props.checklist,
        loading: false,
      }))
    } catch (error) {
      this.setState(prevState => ({
        ...prevState,
        checklist: null,
        loading: false,
      }))
    }
  }

  private getProximityComponents() {
    const proximityComponents: ChecklistComponent[] = []
    this.props.checklist!.sections!.forEach(section =>
      section.checks.forEach(check =>
        check.components
          .filter(component => component.type.type === 'proximity')
          .forEach(component => proximityComponents.push(component))
      )
    )
    return proximityComponents
  }

  private updateProximityComponents() {
    const androidInterface = (window as any).Android
    if (typeof androidInterface !== 'undefined' && this.props.checklist!.proximityEnabled) {
      const beacons: ProximityData[] = JSON.parse(androidInterface.getProximityData())
      const proximityComponents = this.getProximityComponents()
      beacons.forEach(beacon => {
        const component = proximityComponents.find(c => c.type.beaconId === beacon.beaconId)
        if (component) {
          component.lastValue = beacon.minDistance
          this.props.updateComponent(this.props.checklist!.id!, component.id, component.lastValue, false)
        }
      })
    }
  }

  private concludeChecklist(event: any) {
    event.persist()
    this.setState(prevState => ({...prevState, loading: true}))
    this.updateProximityComponents()
    this.props.updateComponent(this.props.checklist!.id!, undefined, undefined, true).then(() => {
      this.props.history.goBack()
      this.setState(prevState => ({...prevState, loading: false}))
    })
  }

  private async nextState() {
    this.setState(prevState => ({...prevState, loading: true}))
    try {
      await this.props.nextStep(this.props.checklist!.id!)
      if (this.props.checklist!.currentState().action === 'next') {
        await this.props.fetchChecklist(this.props.match.params.checklistId)
        this.setState(prevState => ({
          ...prevState,
          checklist: this.props.checklist,
          loading: false,
        }))
      } else {
        this.props.history.goBack()
      }
    } catch (error) {
      this.setState(prevState => ({
        ...prevState,
        loading: false,
      }))
    }
  }

  private async handlePrintChecklist() {
    this.setState(prevState => ({...prevState, downloading: true}))
    try {
      const blob = await this.props.printChecklist(this.props.checklist!.id!)
      const objectURL = URL.createObjectURL(blob)
      const link = document.createElement('a')
      const checklist = this.props.checklist!
      const date = checklist.concludedDate
        ? mvDate.getDateFromStringWithFormatting(checklist.concludedDate!, 'yyyy-MM-dd HH:mm:ss')
        : mvDate.getDateFromStringWithFormatting(checklist.createdDate!, 'yyyy-MM-dd HH:mm:ss')
      const title = checklist.title
      const asset = checklist.asset ? `[${checklist.asset!.code}] ${checklist.asset!.description}` : null
      link.href = objectURL
      link.download = `${date} - ${title}${asset ? ` - ${asset}` : ''}.pdf`
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      link.remove()
      this.setState(prevState => ({...prevState, downloading: false}))
    } catch (error) {
      this.setState(prevState => ({...prevState, error}))
      this.setState(prevState => ({...prevState, downloading: false}))
    }
  }

  public render() {
    const checklistState = this.props.checklist ? this.props.checklist.state : undefined
    const checklistSections =
      this.props.checklist && this.props.checklist.sections
        ? this.props.checklist.sections.filter(
        section =>
          section.checks.some(
            check =>
              check.visible || (check.visibilityStep === checklistState && check.visible && check.canBeFilled)
          ) ||
          (!(this.props.checklist as ChecklistInstance).isConcluded() &&
            section.checks.length === 0 &&
            section.customCheckPrototype)
        )
        : []
    return this.props.fetching || !this.props.checklist ? (
      <div className="d-flex justify-content-center">
        <IconComponent icon={'circle-notch'} className="spinner" spin={true} size="4x"/>
      </div>
    ) : (
      <React.Fragment>
        <header>
          <Row spacing={{vertical: false, horizontal: false}}>
            <Column sm={12} md={7}>
              <Row verticalAlignment={"center"} horizontalAlignment={"start"}
                   spacing={{vertical: false, horizontal: false}}>

                <BackButton
                  onClick={() => this.props.history.goBack()}
                />

                <Column>
                  <h1>
                    {this.props.checklist.title}
                    {this.props.checklist.asset ? (
                      <React.Fragment>
                        <br/>
                        <small className="text-muted">
                          {`[${this.props.checklist.asset.code}] ${this.props.checklist.asset.description}`}
                        </small>
                      </React.Fragment>
                    ) : null}
                  </h1>
                </Column>

              </Row>
            </Column>
            <Column sm={12} md={5}>
              <div className={"text-right"}>
                <Button
                  variant={"secondary-alternate"}
                  type="button"
                  onClick={this.handlePrintChecklist}
                  disabled={this.state.downloading}
                  icon={"print"}
                  isLoading={this.state.downloading}
                  label={this.props.t('checklist.edit.print')}
                />

              </div>

            </Column>
          </Row>
        </header>
        <div className="content">
          <ChecklistMeta checklist={this.props.checklist}/>
          {checklistSections.map((section, index) => (
            <ChecklistSection key={index} section={section} isSummary={true}/>
          ))}
          {this.props.checklist && (this.props.checklist.lastValidation || this.props.checklist.canBeValidated) ? (
            <ValidationComponent
              checklistId={this.props.checklist.id!}
              lastValidation={this.props.checklist.lastValidation}
              validationFields={this.props.checklist.validationFields}
              disabled={this.props.checklist.lastValidation !== null}
            />
          ) : null}
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(ChecklistSummary))
)
