import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import ReactTable, { RowInfo } from 'react-table'
import { Tooltip } from 'react-tippy'
import { fetchDetails, imageUrl, printReport } from '../../../../redux/actions/gia'
import { GiaDetails, GiaSection, GiaSectionMaterial, GiaSectionMaterialInArea, Insertion } from '../../../../types/gia'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import { BackButton, Button, Loader, Modal } from '@mv-submodules/inplant-components-fe'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Magnifier from 'react-magnifier'
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

type Props = WithTranslation & RouteComponentProps<any>

type ModalState =
  { visible: false }
  | { visible: true, type: 'images', insertions: Insertion[] }
  | { visible: true, type: 'image', imageURL: string, insertions: Insertion[] }

export interface OwnState {
  fetching: boolean
  data: GiaDetails | null
  printing: boolean
  modalState: ModalState
}

export class GiaDetailsComponent extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      fetching: false,
      data: null,
      printing: false,
      modalState: { visible: false },
    }
    this.printReport = this.printReport.bind(this)
  }

  public componentDidMount() {
    this.setState({ fetching: true })
    fetchDetails(this.props.match.params.id)
      .then(data => this.setState({ fetching: false, data }))
      .catch(() => this.setState({ fetching: false }))
  }

  private reduceAreasImages = (areas: GiaSectionMaterialInArea[]): Insertion[] => {

    const filteredInsertions = (insertions: Insertion[]): Insertion[] => {
      return insertions.filter(insertion => insertion.images && insertion.images.length > 0)
    }

    return areas.reduce<Insertion[]>((acc, area) => [...acc, ...(area.insertions !== undefined ? filteredInsertions(area.insertions) : [])], [])
  }

  private renderModalImages = () => {
    if (this.state.modalState.visible && this.state.modalState.type === 'images') {
      const modalState = this.state.modalState
      return (
        <Modal
          width={75}
          title={this.props.t('gia.modalImages.title')}
          closeLabel={this.props.t('gia.modalImages.closeButton')}
          visible={true} onClose={() => {
          this.setState({ modalState: { visible: false } })
        }}>
          <Row>
            <>
              {modalState.insertions.map(insertion => {
                return insertion.images?.map(image => {
                  return <Column
                    xs={3}
                    key={image}>
                    <Image
                      onClickImage={(imageURL => {
                        this.setState({
                          modalState: {
                            visible: true,
                            type: 'image',
                            imageURL,
                            insertions: modalState.insertions,
                          },
                        })
                      })}
                      imageId={image}
                      giaMaterialId={insertion.insertion} />
                  </Column>
                })
              })}
            </>
          </Row>
        </Modal>
      )
    }

  }

  private renderModalZoomableImage = () => {
    if (this.state.modalState.visible && this.state.modalState.type === 'image') {
      const modalState = this.state.modalState
      return <Modal
        width={75}
        title={this.props.t('gia.modalImage.title')}
        closeLabel={this.props.t('gia.modalImage.closeButton')}
        visible={true} onClose={() => {
        this.setState({ modalState: { visible: true, type: 'images', insertions: modalState.insertions } })
      }}>
        <Row>
          <>
            <Magnifier
              className='checklist-photo-image'
              src={this.state.modalState.imageURL}
              iconSize='2x'
              zoom={2}
              zoomSize={300}
            />
          </>
        </Row>
      </Modal>
    }

  }

  public render() {
    const fetching = this.state.fetching
    const { user = null, createdTs = null, concludedTs = null, sections = [], notes = '', date = null } =
    this.state.data || {}

    return (
      <>
        {this.renderModalImages()}
        {this.renderModalZoomableImage()}
        <header>
          <Row spacing={{ vertical: false, horizontal: false }}>
            <Column sm={12} md={7}>
              <div className='d-flex flex-row justify-content-start align-items-center'>
                <BackButton onClick={() => window.history.back()} />
                <div>
                  <h1>
                    {date && <span>{mvDate.getDateFromStringWithFormatting(date, 'dd/MM/yyyy')}</span>}
                    {' - '}
                    {this.props.t('checklist.navigation.gia')}
                    {' - '}
                    {user ? (
                      user.displayName
                    ) : (
                      <IconComponent icon={'circle-notch'} className='text-secondary' spin={true} size='sm' />
                    )}
                  </h1>
                  <div style={{ marginTop: '0.5rem' }}>
                    {createdTs && (
                      <p className='text-muted' style={{ marginBottom: 0 }}>
                        {this.props.t('gia.details.createdDate')} {mvDate.getDateFromStringWithFormatting(createdTs, 'dd/MM/yyyy HH:mm')}
                      </p>
                    )}
                    {concludedTs && (
                      <p className='text-muted'>
                        {this.props.t('gia.details.concludedDate')} {mvDate.getDateFromStringWithFormatting(concludedTs, 'dd/MM/yyyy HH:mm')}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </Column>
            <>
              {concludedTs && (
                <Column sm={12} md={5}>
                  <div className={'text-right'}>
                    <Button variant={'secondary-alternate'} onClick={this.printReport} icon={"print"}
                            label={this.props.t('checklist.edit.print')} isLoading={this.state.printing} />
                  </div>
                </Column>
              )}
            </>
          </Row>
        </header>
        <div className='content'>
          {fetching ? (
            <div className='d-flex justify-content-center'>
              <IconComponent icon={'chevron-right'} className='spinner' spin={true} size='4x' />
            </div>
          ) : (
            <>
              <div style={{ marginBottom: '2rem' }}>
                <strong>{this.props.t('gia.details.notes')}</strong>
                <span>{notes}</span>
              </div>
              {sections.map((section: GiaSection) => {
                const materials = section.materials.reduce(
                  (acc, material) => [...acc, ...material.cers.map(cer => ({ ...material, ...cer }))] as any,
                  [],
                )
                return (
                  <div key={section.name} className='mt-4'>
                    <h5>{section.description}</h5>
                    <ReactTable
                      className='checklist-asset-table'
                      showPagination={false}
                      pageSize={materials.length}
                      data={materials}
                      minRows={materials.length ? 1 : 4}
                      noDataText={this.props.t('gia.table.noData')}
                      columns={[
                        {
                          Header: this.props.t('gia.section.material.description'),
                          accessor: 'description',
                        },
                        {
                          Header: this.props.t('gia.section.material.cer'),
                          accessor: 'cer',
                        },
                        {
                          Cell: ({ original }: RowInfo) =>
                            original.areas.length ? (
                              <Tooltip
                                trigger='mouseenter click'
                                position='top'
                                arrow={true}
                                html={
                                  <>
                                    {original.areas.map((area: GiaSectionMaterialInArea) => {
                                      let notesString = `${area.name}: ${area.total} ${original.unit}`
                                      if (area.notes) {
                                        notesString += ` - ${area.notes}`
                                      }
                                      return <p key={area.name}>{notesString}</p>
                                    })}
                                  </>
                                }
                              >
                                {original.total} {original.unit}{' '}
                                <IconComponent icon={'info-circle'} className='text-secondary' size='sm' />
                              </Tooltip>
                            ) : (
                              `${original.total} ${original.unit}`
                            ),
                          Header: this.props.t('gia.section.material.total'),
                          accessor: 'total',
                        },
                        {
                          Header: this.props.t('gia.section.material.images'),
                          Cell: ({ original }: RowInfo) => {
                            const material: GiaSectionMaterial = original
                            const insertions: Insertion[] = material.cers.reduce<Insertion[]>((acc, cer) => [...acc, ...this.reduceAreasImages(cer.areas)], [])
                            if (insertions.length > 0) {
                              return <IconComponent icon={'images'} onClick={() => {
                                this.setState({ modalState: { visible: true, type: 'images', insertions } })
                              }
                              } />
                            }
                            return <></>
                          },
                        },
                      ]}
                      filterable={false}
                      sortable={false}
                    />
                  </div>
                )
              })}
            </>
          )}
        </div>
      </>
    )
  }

  private async printReport(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    this.setState({
      printing: true,
    })
    try {
      const { user, date } = this.state.data!
      const blob = await printReport(this.props.match.params.id)
      const objectURL = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = objectURL
      link.download = `GIA ${user.displayName} ${mvDate.getDateFromStringWithFormatting(date, 'dd-MM-yyyy')}.pdf`
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (error) {
      console.warn('Download error', error) // tslint:disable-line
    } finally {
      this.setState({
        printing: false,
      })
    }
  }
}

type ImageStates = { type: 'loading' } | { type: 'success'; src: string }

interface ImageProps {
  giaMaterialId: string
  imageId: string
  onClickImage: (imageURL: string) => void
}

export const Image = (props: ImageProps) => {
  const [imageState, setImageState] = useState<ImageStates>({ type: 'loading' })

  const { imageId, giaMaterialId } = props
  const dispatch = useDispatch()

  useEffect(() => {
    imageUrl(
      giaMaterialId,
      imageId,
    )(dispatch).then(src => {
      setImageState({ type: 'success', src })
    }).catch(e => {
      console.warn(e) //tslint:disable-line
    })

  }, [])

  switch (imageState.type) {
    case 'loading':
      return <Loader />
    case 'success':
      const imageURL = URL.createObjectURL(imageState.src)
      return (
        <div style={{ height: '200px' }} className={`img-container`}>
          <img
            onClick={() => {
              props.onClickImage(imageURL)
            }}
            style={{ objectFit: 'cover', height: '200px', display: 'block' }}
            alt={'img-checkout'}
            className={'mt-4 mw-100 w-100'}
            src={imageURL}
          />
        </div>

      )
  }
}

export default withRouter<any, any>(withTranslation()(
  GiaDetailsComponent,
))
