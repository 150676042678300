import * as React from 'react'
import * as ReactDOM from 'react-dom'
import {connect} from 'react-redux'
import {WithTranslation, withTranslation} from 'react-i18next'
import {checklistDeleteFile} from '../../../../redux/actions'
import {Modal} from "@mv-submodules/inplant-components-fe";

export interface DispatchProps {
  deleteFile: (filePath: string) => Promise<void>
}

export interface OwnProps {
  show: boolean
  onClose: (reload?: boolean) => void
  filePath: string | undefined
}

export interface OwnState {
  loading: boolean
}

export type Props = DispatchProps & OwnProps & WithTranslation

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    deleteFile: filePath => dispatch(checklistDeleteFile(filePath)),
  }
}

export class DeleteFileModal extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
    }
    this.handleDeleteFile = this.handleDeleteFile.bind(this)
  }

  private async handleDeleteFile() {
    this.setState(prevState => ({
      ...prevState,
      loading: true,
    }))
    await this.props.deleteFile(this.props.filePath!)
    this.setState(prevState => ({
      ...prevState,
      loading: false,
    }))
    this.props.onClose(true)
  }

  public render() {
    return this.props.show
      ? ReactDOM.createPortal(
        <Modal visible={true} onClose={this.props.onClose}
               title={this.props.t('checklist.components.multiFile.deleteFile')}
               closeLabel={this.props.t('checklist.components.back')} additionalFooterButtons={[{
          icon: "exclamation-triangle",
          label: this.props.t('checklist.components.multiFile.deleteFile'),
          isLoading: this.state.loading,
          disabled: this.state.loading,
          variant: "danger",
          onClick: this.handleDeleteFile
        }]}>
          <p>{this.props.t('checklist.components.multiFile.deleteFileInstructions')}</p>
        </Modal>
        ,
        document.getElementById('modal-container') as Element
      )
      : null
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(DeleteFileModal))
