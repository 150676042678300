import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  AssetDetail,
  AssetFile,
  AssetManagerAcl,
  AssetModulesStatus,
  AssetStatus,
  AssetTask,
  Module,
} from '@mv-submodules/inplant-asset-manager-fe/types/asset'
import { Alert, DetailView, Loader, PageHeader } from '@mv-submodules/inplant-components-fe'
import {
  addNoteToAsset,
  fetchAssetConfigurationTaskStatus,
  fetchAssetTask,
  fetchDetailAsset,
  updateAssetMachineCode,
} from '@mv-submodules/inplant-asset-manager-fe/redux/actions/asset'
import * as moment from 'moment'
import { downloadDocument, renderStatus } from '@mv-submodules/inplant-asset-manager-fe/functions/shared'
import FileReadOnly from '@mv-submodules/inplant-asset-manager-fe/ui/components/widgets/File/FileReadOnly'
import AssetModulesStatusComponent from '@mv-submodules/inplant-asset-manager-fe/ui/components/widgets/AssetModulesStatusComponent/AssetModulesStatusComponent'
import ModalAddNoteAsset from '@mv-submodules/inplant-asset-manager-fe/ui/components/widgets/ModalAddNoteAsset/ModalAddNoteAsset'
import { connect } from 'react-redux'
import { ButtonVariants } from '@mv-submodules/inplant-components-fe/ui/components/Button/types'
import ActionLog from '@mv-submodules/inplant-components-fe/ui/components/ActionLog/ActionLog'
import ModalUpdateMachineCodeAsset from '../../widgets/ModalUpdateMachineCodeAsset/ModalUpdateMachineCodeAsset'
import { SubtitleButtonGroupsConfig } from '../../../../../inplant-components-fe/ui/components/Header/SubtitleButtonGroups'

interface OwnState {
  assetDetail: AssetDetail | null
  isFetching: boolean
  fetchErrorDetailAsset: boolean
  isFetchingDetailAsset: boolean
  isFileDownloading: { [k: string]: boolean }
  addNoteAssetVisible: boolean
  isSubmittingNote: boolean
  isSubmittingMachineCode: boolean
  assetModulesStatus?: AssetModulesStatus
  showUpdateMachineCodeModal: boolean
}

interface StateProps {
  forbiddenActions: string[]
}

const mapStateToProps = (store: any): StateProps => {
  return {
    forbiddenActions: store.auth.user.forbiddenActions,
  }
}

type Props = RouteComponentProps & WithTranslation & StateProps

class DetailAssetPageView extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      assetDetail: null,
      isFetching: false,
      isFetchingDetailAsset: false,
      fetchErrorDetailAsset: false,
      isFileDownloading: {},
      addNoteAssetVisible: false,
      isSubmittingNote: false,
      assetModulesStatus: { 'chrono-frame': { name: '', values: [] } },
      showUpdateMachineCodeModal: false,
      isSubmittingMachineCode: false,
    }
    this.fetchAssetDetail = this.fetchAssetDetail.bind(this)
    this.fetchAssetTaskDetail = this.fetchAssetTaskDetail.bind(this)
    this.handleAssetValidate = this.handleAssetValidate.bind(this)
    this.handleAssetUpdate = this.handleAssetUpdate.bind(this)
    this.handleDownloadFile = this.handleDownloadFile.bind(this)
    this.onSubmitAssetNote = this.onSubmitAssetNote.bind(this)
    this.onSubmitMachineCodeUpdate = this.onSubmitMachineCodeUpdate.bind(this)
    this.handleUpdateMachineCode = this.handleUpdateMachineCode.bind(this)
    this.toggleModalUpdateMachineCode = this.toggleModalUpdateMachineCode.bind(this)
    this.toggleModalAssetAddNote = this.toggleModalAssetAddNote.bind(this)
    this.goToDisableAsset = this.goToDisableAsset.bind(this)
    this.goToSubstituteAsset = this.goToSubstituteAsset.bind(this)
    this.goToCopyTasks = this.goToCopyTasks.bind(this)
    this.clickAssetTask = this.clickAssetTask.bind(this)
  }

  public componentDidMount() {
    const assetId = this.props.match.params['assetId'] // tslint:disable-line
    if (!assetId) {
      this.props.history.push('/asset-manager/list-active')
    }
    this.fetchAssetDetail(assetId)
  }

  private getSubtitleButtonActionsForActiveAsset() {
    const { forbiddenActions, t } = this.props
    const { assetDetail } = this.state
    const actions: SubtitleButtonGroupsConfig[] = []

    if (!assetDetail || assetDetail.status !== 'active') {
      return actions
    }

    // Check can disable asset
    if (!forbiddenActions.includes(AssetManagerAcl.deactivated)) {
      actions.push({
        label: t('assetManager.actions.disableAsset'),
        onClick: () => this.goToDisableAsset(assetDetail.id),
        variant: 'secondary-alternate' as ButtonVariants,
      })
    }

    // check can replace
    if (!forbiddenActions.includes(AssetManagerAcl.replace)) {
      actions.push({
        label: t('assetManager.actions.changeAsset'),
        onClick: () => this.goToSubstituteAsset(),
        variant: 'secondary-alternate' as ButtonVariants,
      })
    }

    return actions
  }

  private getSubtitleButtonActionsForCreationToValidate() {
    const { forbiddenActions, t } = this.props
    const { assetDetail } = this.state
    const actions: SubtitleButtonGroupsConfig[] = []

    // Check if right status
    if (assetDetail?.status !== 'creation-to-validate') {
      return []
    }

    // Check can update machine code
    if (!forbiddenActions.includes(AssetManagerAcl.updateMachineCode)) {
      actions.push({
        label: t('assetManager.actions.updateMachineCode'),
        variant: 'secondary-alternate' as ButtonVariants,
        onClick: () => this.toggleModalUpdateMachineCode(),
      })
    }

    return actions
  }

  private getSubtitleButtonActionsForAssetValidation() {
    const { forbiddenActions, t } = this.props
    const { assetDetail } = this.state
    const actions: SubtitleButtonGroupsConfig[] = []

    if (!assetDetail) {
      return actions
    }

    const partialAssetValidationAction: Partial<SubtitleButtonGroupsConfig> = {
      variant: 'primary' as ButtonVariants,
      onClick: () => this.handleAssetValidate(assetDetail.id),
    }

    // Check if can validate creation and its in the right status for doing it
    if (assetDetail.status === 'creation-to-validate' && !forbiddenActions.includes(AssetManagerAcl.validateCreation)) {
      partialAssetValidationAction.label = t('assetManager.actions.validateAsset')
      actions.push(partialAssetValidationAction)
    }

    // Check if can validate document and its in the right status for doing it
    if (
      assetDetail.status === 'documents-to-validate' &&
      !forbiddenActions.includes(AssetManagerAcl.validateDocuments)
    ) {
      partialAssetValidationAction.label = t('assetManager.actions.validateAsset')
      actions.push(partialAssetValidationAction)
    }

    return actions
  }

  private getSubtitleButtonActionsForValidateDeactivation() {
    const { forbiddenActions, t } = this.props
    const { assetDetail } = this.state
    const actions: SubtitleButtonGroupsConfig[] = []
    const acceptedStatus: AssetStatus[] = ['deactivation-to-validate', 'freezing-to-validate', 'scrapping-to-validate']

    // Check if asset detail is present
    if (!assetDetail) {
      return actions
    }

    // Check if action is available
    if (forbiddenActions.includes(AssetManagerAcl.validateDeactivation)) {
      return actions
    }

    // Check status is inside correct status
    if (acceptedStatus.includes(assetDetail.status)) {
      actions.push({
        variant: 'primary' as ButtonVariants,
        onClick: () => this.goToDisableAsset(assetDetail.id),
        label: t('assetManager.actions.disableAsset').toUpperCase(),
      })
    }

    return actions
  }

  private getSubtitleButtonActionsForReplacing() {
    const { forbiddenActions, t } = this.props
    const { assetDetail } = this.state
    const actions: SubtitleButtonGroupsConfig[] = []
    const acceptedStatus: AssetStatus[] = [
      'replace-with-deactivation-to-validate',
      'replace-with-scrapping-to-validate',
    ]

    // Check if asset detail is present
    if (!assetDetail) {
      return actions
    }

    // Check if action is available
    if (forbiddenActions.includes(AssetManagerAcl.validateReplacing)) {
      return actions
    }

    // Check status is inside correct status
    if (!acceptedStatus.includes(assetDetail.status)) {
      return actions
    }

    const partialHandleValidation: SubtitleButtonGroupsConfig = {
      label: t('assetManager.actions.validateChange').toUpperCase(),
      variant: 'primary' as ButtonVariants,
    }

    actions.push({
      variant: 'primary' as ButtonVariants,
      onClick: () => this.goToDisableAsset(assetDetail.id),
      label: t('assetManager.actions.scrapAsset').toUpperCase(),
    })

    // Check if is replacing or replaced by other asset
    if (assetDetail.isReplacing || assetDetail.isBeingReplacedBy) {
      let idForValidation = ''
      if (assetDetail.isReplacing && assetDetail.id) {
        idForValidation = assetDetail.id
      }
      if (assetDetail.isBeingReplacedBy && assetDetail.isBeingReplacedBy.id) {
        idForValidation = assetDetail.isBeingReplacedBy.id
      }
      partialHandleValidation.onClick = () => this.handleAssetValidate(idForValidation)
    } else {
      partialHandleValidation.onClick = () => this.goToDisableAsset(assetDetail.id)
    }

    actions.push(partialHandleValidation)

    return actions
  }

  private getSubtitleButtonActionsForSellingValidation() {
    const { forbiddenActions, t } = this.props
    const { assetDetail } = this.state
    const actions: SubtitleButtonGroupsConfig[] = []

    // Check if asset detail is present
    if (!assetDetail) {
      return actions
    }

    // Check if action is available
    if (forbiddenActions.includes(AssetManagerAcl.validateReplacing)) {
      return actions
    }

    // Check status is correct status
    if (assetDetail.status === 'selling-to-validate') {
      actions.push({
        variant: 'primary' as ButtonVariants,
        onClick: () => this.goToDisableAsset(assetDetail.id),
        label: t('assetManager.actions.scrapAsset').toUpperCase(),
      })
    }

    return actions
  }

  private getSubtitleButtonActions() {
    const { assetDetail } = this.state
    const { forbiddenActions, t } = this.props
    const actions: SubtitleButtonGroupsConfig[] = []
    if (assetDetail?.status === 'deactivated') {
      return []
    }

    // Check if can copy tasks
    if (!forbiddenActions.includes(AssetManagerAcl.copyAssetTasks)) {
      actions.push({
        label: t('assetManager.actions.copyTasks'),
        variant: 'secondary-alternate',
        onClick: () => this.goToCopyTasks(),
      })
    }

    // Check if can add note
    if (!forbiddenActions.includes(AssetManagerAcl.addAssetNote)) {
      actions.push({
        label: t('assetManager.actions.addNote'),
        onClick: () => this.toggleModalAssetAddNote(),
        variant: 'secondary-alternate',
      })
    }

    // Check if can edit
    if (!forbiddenActions.includes(AssetManagerAcl.edit)) {
      actions.push({
        label: t('assetManager.actions.update'),
        onClick: () => this.handleAssetUpdate(),
        variant: 'secondary-alternate' as ButtonVariants,
      })
    }

    actions.push(...this.getSubtitleButtonActionsForActiveAsset())

    actions.push(...this.getSubtitleButtonActionsForCreationToValidate())

    actions.push(...this.getSubtitleButtonActionsForAssetValidation())

    actions.push(...this.getSubtitleButtonActionsForValidateDeactivation())

    actions.push(...this.getSubtitleButtonActionsForReplacing())

    actions.push(...this.getSubtitleButtonActionsForSellingValidation())

    return actions
  }

  public render() {
    const { t } = this.props
    const {
      assetDetail,
      isFetching,
      isFileDownloading,
      assetModulesStatus,
      fetchErrorDetailAsset,
      isFetchingDetailAsset,
      addNoteAssetVisible,
      isSubmittingNote,
      showUpdateMachineCodeModal,
      isSubmittingMachineCode,
    } = this.state

    if (!assetDetail) {
      return <Loader />
    }

    // @ts-ignore
    // const previousPath: string = this.props && this.props.location && this.props.location.state && this.props.location.state.from || '/asset-manager/list-active'

    return (
      <div className="inplant-asset-manager-fe">
        {isFetching ? (
          <Loader />
        ) : (
          <>
            {/* Header Section*/}
            <PageHeader
              title={`${assetDetail.positionCode} ${assetDetail.assetDescription}`}
              backButtonOnClick={() => this.props.history.goBack()}
              backButton={true}
              subtitleButtons={this.getSubtitleButtonActions()}
            />
            {/* End Header Section*/}
            {/* Contend Section*/}
            <div className="content asset-detail">
              <DetailView
                leftComponent={[
                  {
                    row: [
                      {
                        label: { text: t('assetManager.forms.machineCode') },
                        value: { text: assetDetail.assetCode },
                      },
                      {
                        label: { text: t('assetManager.forms.plantCode') },
                        value: { text: assetDetail.positionCode },
                      },
                      {
                        label: { text: t('assetManager.forms.assetDescription') },
                        value: { text: assetDetail.assetDescription },
                      },
                    ],
                  },
                  {
                    row: [
                      {
                        label: { text: t('assetManager.forms.brand') },
                        value: { text: assetDetail.assetData.brand },
                      },
                      {
                        label: { text: t('assetManager.forms.serialNumber') },
                        value: { text: assetDetail.assetData.serialNumber },
                      },
                      {
                        label: { text: t('assetManager.forms.model') },
                        value: { text: assetDetail.assetData.model },
                      },
                    ],
                  },
                  {
                    element: {
                      label: { text: t('assetManager.forms.owner') },
                      value: {
                        text:
                          assetDetail.assetData.ownership &&
                          t(`assetManager.forms.ownerType.${assetDetail.assetData.ownership}`),
                      },
                    },
                  },
                  {
                    element: {
                      label: { text: t('assetManager.forms.notes') },
                      value: { text: assetDetail.assetData.notes },
                    },
                  },
                  {
                    element: {
                      label: { text: t('assetManager.forms.assetsGroups') },
                      value: {
                        text:
                          (assetDetail.groups.length > 0 &&
                            `${assetDetail.groups.map(group => group.name).join(', ')}.`) ||
                          t('assetManager.forms.noGroups'),
                      },
                    },
                  },
                  {
                    element: {
                      label: { text: t('assetManager.forms.documents') },
                      value: {
                        text:
                          (assetDetail.assetData.documents.length === 0 && t('assetManager.forms.noDocuments')) ||
                          undefined,
                        component:
                          (assetDetail.assetData.documents.length > 0 &&
                            assetDetail.assetData.documents.map(document => (
                              <FileReadOnly
                                file={document}
                                key={`document_${document.id}`}
                                handleDownloadFile={this.handleDownloadFile}
                                isFileDownloading={isFileDownloading[document.id] || false}
                              />
                            ))) ||
                          undefined,
                      },
                    },
                  },
                ]}
                rightComponent={[
                  {
                    label: { text: t('assetManager.forms.status') },
                    value: { element: renderStatus(assetDetail.status) },
                    inline: true,
                  },
                  {
                    label: { text: t('assetManager.forms.activationDate') },
                    value: { text: moment(assetDetail.activatedOn).format('DD/MM/YYYY') },
                    inline: true,
                  },
                  {
                    label: { text: t('assetManager.forms.endDate') },
                    value: {
                      text:
                        (assetDetail.deactivatedOn && moment(assetDetail.deactivatedOn).format('DD/MM/YYYY')) ||
                        undefined,
                    },
                    inline: true,
                  },
                  {
                    label: { text: t('assetManager.forms.plant') },
                    value: { text: assetDetail.plant.name },
                    inline: true,
                  },
                  {
                    label: { text: t('assetManager.forms.costCenter') },
                    value: { text: assetDetail.costCenter.name },
                    inline: true,
                  },
                ]}
                fullComponent={[
                  {
                    label: { text: t('assetManager.forms.modules') },
                    value: {
                      component: (
                        <>
                          {isFetchingDetailAsset && <Loader />}
                          {!isFetchingDetailAsset && fetchErrorDetailAsset && (
                            <Alert type={'danger'}>{t('assetManager.fetchErrors')}</Alert>
                          )}
                          {!isFetchingDetailAsset && !fetchErrorDetailAsset && assetDetail && assetModulesStatus && (
                            <AssetModulesStatusComponent
                              assetModulesStatus={assetModulesStatus}
                              clickAssetTask={this.clickAssetTask}
                            />
                          )}
                        </>
                      ),
                    },
                  },
                  {
                    label: { text: t('assetManager.forms.historyActions') },
                    value: {
                      component: (
                        <ActionLog
                          values={assetDetail.actionLogs.map(actionLog => ({
                            title: t(`assetManager.actionLogs.${actionLog.type}`, { defaultValue: actionLog.type }),
                            text: actionLog.data.text,
                            date: moment(actionLog.date).format('DD/MM/YYYY HH:mm'),
                            user: actionLog.user,
                          }))}
                        />
                      ),
                    },
                  },
                ]}
              />
            </div>
            {/* End Contend Section*/}
          </>
        )}
        {showUpdateMachineCodeModal && (
          <ModalUpdateMachineCodeAsset
            onSubmit={this.onSubmitMachineCodeUpdate}
            closeModal={this.toggleModalUpdateMachineCode}
            visible={showUpdateMachineCodeModal}
            isSubmitting={isSubmittingMachineCode}
            machineCode={assetDetail.assetCode}
          />
        )}
        {addNoteAssetVisible && (
          <ModalAddNoteAsset
            visible={addNoteAssetVisible}
            onSubmit={this.onSubmitAssetNote}
            isSubmitting={isSubmittingNote}
            closeModal={this.toggleModalAssetAddNote}
          />
        )}
      </div>
    )
  }

  private async fetchAssetDetail(assetId: string) {
    if (!this.state.isFetching) {
      this.setState({ isFetching: true, fetchErrorDetailAsset: false })
      try {
        const assetDetail: AssetDetail = await fetchDetailAsset(assetId)
        const assetModulesStatus: AssetModulesStatus = assetDetail.modules.reduce(
          (acc: AssetModulesStatus, module: Module) => {
            acc[module.id] = {
              name: module.name,
              values: [],
            }
            return acc
          },
          { 'chrono-frame': { name: '', values: [] } }
        )
        this.setState({ assetDetail, assetModulesStatus })
        this.fetchAssetTaskDetail(assetId)
      } catch (error) {
        this.setState({ fetchErrorDetailAsset: true })
        console.log('error', error) //tslint:disable-line
      } finally {
        this.setState({ isFetching: false })
      }
    }
  }

  private fetchAssetTaskDetail(assetId: string) {
    this.setState({ fetchErrorDetailAsset: false, isFetchingDetailAsset: true })
    Promise.all([fetchAssetTask(assetId), fetchAssetConfigurationTaskStatus(assetId)])
      .then(([assetTasks, assetConfigurationTasksStatus]) => {
        this.setState({
          assetDetail: {
            ...this.state.assetDetail!,
            assetConfigurationTasksStatus,
          },
          assetModulesStatus: {
            ...this.state.assetModulesStatus,
            'chrono-frame': {
              ...this.state.assetModulesStatus!['chrono-frame'],
              values: assetTasks,
            },
          },
        })
      })
      .catch(e => {
        this.setState({
          fetchErrorDetailAsset: true,
        })
        console.log('error', e) //tslint:disable-line
      })
      .finally(() => {
        this.setState({ isFetchingDetailAsset: false })
      })
  }

  private handleAssetValidate(id: string) {
    this.props.history.push(`/asset-manager/asset-validation/${id}`)
  }

  private handleUpdateMachineCode(id: string) {
    this.props.history.push(`/asset-manager/update-machine-code/${id}`)
  }

  private handleAssetUpdate() {
    this.props.history.push(`/asset-manager/update-asset/${this.state.assetDetail!.id}`)
  }

  private handleDownloadFile(assetFile: AssetFile) {
    this.setState({
      isFileDownloading: {
        ...this.state.isFileDownloading,
        [assetFile.id]: true,
      },
    })
    downloadDocument(assetFile)
      .then(resp => {
        const blob = new Blob([resp], { type: assetFile.mimeType })
        const link = document.createElement('a')
        link.href = (window.URL || window.webkitURL).createObjectURL(blob)
        link.download = assetFile.name
        link.click()
      })
      .catch(error => {
        console.log(error) //tslint:disable-line
      })
      .finally(() =>
        this.setState({
          isFileDownloading: {
            ...this.state.isFileDownloading,
            [assetFile.id]: false,
          },
        })
      )
  }

  private toggleModalAssetAddNote(update?: boolean) {
    this.setState({ addNoteAssetVisible: !this.state.addNoteAssetVisible })
    if (update && this.state.assetDetail) {
      this.fetchAssetDetail(this.state.assetDetail.id)
    }
  }

  private toggleModalUpdateMachineCode(update?: boolean) {
    this.setState({ showUpdateMachineCodeModal: !this.state.showUpdateMachineCodeModal })
    if (update && this.state.assetDetail) {
      this.fetchAssetDetail(this.state.assetDetail.id)
    }
  }

  private async onSubmitAssetNote(text: string) {
    if (this.state.assetDetail) {
      this.setState({ isSubmittingNote: true })
      try {
        await addNoteToAsset(text, this.state.assetDetail.id)
        this.toggleModalAssetAddNote(true)
      } catch (e) {
        console.log(e) //tslint:disable-line
      } finally {
        this.setState({ isSubmittingNote: false })
      }
    }
  }

  private async onSubmitMachineCodeUpdate(machineCode: string) {
    if (this.state.assetDetail) {
      this.setState({ isSubmittingMachineCode: true })
      try {
        await updateAssetMachineCode(machineCode, this.state.assetDetail.id)
        this.toggleModalUpdateMachineCode(true)
      } catch (e) {
        console.log(e) //tslint:disable-line
      } finally {
        this.setState({ isSubmittingMachineCode: false })
      }
    }
  }

  private goToDisableAsset(id: string) {
    if (this.state.assetDetail) {
      this.props.history.push(`/asset-manager/disable-asset/${id}`)
    }
  }

  private goToSubstituteAsset() {
    if (this.state.assetDetail) {
      this.props.history.push(`/asset-manager/substitute-asset/${this.state.assetDetail.id}`)
    }
  }

  private goToCopyTasks() {
    if (this.state.assetDetail) {
      this.props.history.push(`/asset-manager/copy-tasks/${this.state.assetDetail.id}`)
    }
  }

  private clickAssetTask(assetTask: AssetTask) {
    if (assetTask.chronoFrameLink) {
      window.open(`//${window.location.hostname}${assetTask.chronoFrameLink}`)
      //  this.props.history.push(assetTask.chronoFrameLink)
    }
  }
}

export default withRouter<any, any>(connect(mapStateToProps, {})(withTranslation()(DetailAssetPageView)))
