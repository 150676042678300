import { combineReducers, ReducersMapObject } from 'redux'
import { checklistEdit } from './checklistEdit'
import { checklistIndex } from './checklistIndex'
import { checklistAsset } from './checklistAsset'
import { checklistArchive } from './checklistArchive'
import { checklistValidation } from './checklistValidation'
import { checklistAssetPage } from './checklistAssetPage'
import { checklistAssetResetHours } from './checklistAssetResetHours'
import { checklistSummary } from './checklistSummary'
import { checklistSetup } from './checklistSetup'
import { configuration } from './configuration'
import filters from './filters'

const checklistReducersMap: ReducersMapObject = {
  edit: checklistEdit,
  index: checklistIndex,
  asset: checklistAsset,
  archive: checklistArchive,
  summary: checklistSummary,
  validation: checklistValidation,
  modelsAssets: checklistAssetPage,
  assetResetHours: checklistAssetResetHours,
  setup: checklistSetup,
  filters,
  configuration
}

export default combineReducers(checklistReducersMap)
