import { API } from './index'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { ActionCreator, Dispatch } from 'redux'
import { Configuration, ConfigurationBE, configurationBeToConfiguration } from '@mv-submodules/inplant-checklist-fe-iblu/types/configuration'

export const CONFIGURATION_SUCCESS = 'CONFIGURATION_SUCCESS'
export const CONFIGURATION_IS_FETCHING = 'CONFIGURATION_IS_FETCHING'
export const CONFIGURATION_ERROR = 'CONFIGURATION_ERROR'

interface FetchConfigurationSuccess {
  type: typeof CONFIGURATION_SUCCESS
  payload: Configuration
}

interface IsFetchingConfiguration {
  type: typeof CONFIGURATION_IS_FETCHING
}

interface FetchConfigurationError {
  type: typeof CONFIGURATION_ERROR
  payload: Error
}

export const fetchConfiguration = (abortController?:AbortController): ((dispatch: Dispatch) => Promise<void>) => {
  return async dispatch => {
    dispatch(isFetchingConfiguration())
    try {
      const data: ConfigurationBE = await API().request('/config/manager', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Content-Language': 'it-IT',
        },
        signal: abortController?.signal
      })
      dispatch(fetchConfigurationSuccess(configurationBeToConfiguration(data)))
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      if (error.name !== 'AbortError') {
        dispatch(fetchConfigurationError(error))
        throw error
      }
    }
  }
}

export const fetchConfigurationSuccess: ActionCreator<FetchConfigurationSuccess> = (
  configData: Configuration,
): FetchConfigurationSuccess => {
  return {
    type: CONFIGURATION_SUCCESS,
    payload: configData,
  }
}

export const isFetchingConfiguration: ActionCreator<IsFetchingConfiguration> = (): IsFetchingConfiguration => {
  return {
    type: CONFIGURATION_IS_FETCHING,
  }
}

export const fetchConfigurationError: ActionCreator<FetchConfigurationError> = (
  error: Error,
): FetchConfigurationError => {
  return {
    type: CONFIGURATION_ERROR,
    payload: error,
  }
}

export type ConfigurationTypes = FetchConfigurationSuccess | IsFetchingConfiguration | FetchConfigurationError
