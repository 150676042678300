import * as dateFns from 'date-fns'
import { it, enUS, sl } from 'date-fns/locale'
import { mvDuration, MVDuration, PartialMVDuration } from './durationHelper'

export type MVDate = dateFns.Interval | dateFns.Locale
type Granularity = 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second' | 'milliseconds'

// default enUS if language is not supported
const localeSupported: { [language: string]: dateFns.Locale } = {
  it,
  en: enUS,
  sl
}

/**
 * @example
 * 2021-11-19 12:48:49+01:00
 */
export const REQUEST_FORMAT = 'yyyy-MM-dd HH:mm:ssXXX'
/**
 * @example
 * 2021-11-19
 */
export const DATE_REQUEST_FORMAT = 'yyyy-MM-dd'
/**
 * @example
 * 12:55
 */
export const TIME = 'HH:mm'
/**
 * @example
 * 12:55:40
 */
export const TIME_W_SEC = 'HH:mm:ss' // HH:mm:ss
/**
 * @example
 * 03/04/2021
 */
export const DAY_MONTH_YEAR = 'dd/MM/yyyy'
/**
 * @example
 * 31 Mar
 */
export const DAY_MONTH_SHORT = 'd MMM'
/**
 * @example
 * Mercoledì, 31 Marzo
 */
export const DAY_MONTH_LONG = 'EEEE, dd MMMM'
/**
 * dd/MM/yyyy HH:mm:ss
 * @example
 * 03/04/2021 12:55:40
 */
export const FULL_DATE_WITH_FULL_TIME = `${DAY_MONTH_YEAR} ${TIME_W_SEC}`
/**
 * dd/MM/yyyy HH:mm
 * @example
 * 03/04/2021 12:55
 */
export const FULL_DATE_WITH_TIME = `${DAY_MONTH_YEAR} ${TIME}`

interface MVDateType {
  add: (date: MVDateAggregate, duration: PartialMVDuration) => Date
  addDays: (date: MVDateAggregate, days: number) => Date
  addHours: (date: MVDateAggregate, hours: number) => Date
  addMilliseconds: (date: MVDateAggregate, milliseconds: number) => Date
  addMinutes: (date: MVDateAggregate, minutes: number) => Date
  addMonths: (date: MVDateAggregate, months: number) => Date
  addSeconds: (date: MVDateAggregate, seconds: number) => Date
  addWeeks: (date: MVDateAggregate, weeks: number) => Date
  addYears: (date: MVDateAggregate, years: number) => Date
  /**
   * Get the number of full day periods between two dates
   * @example
   * const result = differenceInDays(
   *  new Date(2012, 6, 2, 0, 0),
   *  new Date(2011, 6, 2, 23, 0)
   * )//=> 365
   */
  differenceInDays: (startDate: MVDateAggregate, endDate: MVDateAggregate) => number
  /**
   * Get the number of hours between the given dates.
   */
  differenceInHours: (startDate: MVDateAggregate, endDate: MVDateAggregate) => number
  /**
   * Get the number of milliseconds between the given dates.
   */
  differenceInMilliseconds: (startDate: MVDateAggregate, endDate: MVDateAggregate) => number
  /**
   * Get the signed number of full (rounded towards 0) minutes between the given dates.
   */
  differenceInMinutes: (startDate: MVDateAggregate, endDate: MVDateAggregate) => number
  differenceInMonths: (startDate: MVDateAggregate, endDate: MVDateAggregate) => number
  /**
   * Get the number of seconds between the given dates.
   */
  differenceInSeconds: (startDate: MVDateAggregate, endDate: MVDateAggregate) => number
  differenceInWeeks: (startDate: MVDateAggregate, endDate: MVDateAggregate) => number
  differenceInYears: (startDate: MVDateAggregate, endDate: MVDateAggregate) => number
  endOfDay: (date: MVDateAggregate) => Date
  endOfHour: (date: MVDateAggregate) => Date
  endOfMinute: (date: MVDateAggregate) => Date
  endOfMonth: (date: MVDateAggregate) => Date
  endOfSecond: (date: MVDateAggregate) => Date
  endOfWeek: (date: MVDateAggregate) => Date
  endOfYear: (date: MVDateAggregate) => Date
  format: (date: MVDateAggregate, format?: string) => string
  // formatDuration: ({ years, month, weeks, days, hours, minutes, seconds, milliseconds }: MVDateDuration, )
  getDate: (date: MVDateAggregate) => number
  getDateFromString: (date: string) => Date
  getDateFromStringWithFormatting: (date: string, format?: string) => string
  getDayOfYear: (date: MVDateAggregate) => number
  getDuration: ({ years, months, weeks, days, hours, minutes, seconds, milliseconds }: PartialMVDuration) => MVDuration
  getDurationFromDates: (startDate: MVDateAggregate, endDate: MVDateAggregate) => PartialMVDuration
  getDurationFromString: (durationString: string) => PartialMVDuration
  getHours: (date: MVDateAggregate) => number
  getMilliseconds: (date: MVDateAggregate) => number
  getMinutes: (date: MVDateAggregate) => number
  getMonth: (date: MVDateAggregate) => number
  getSeconds: (date: MVDateAggregate) => number
  getTime: (date: MVDateAggregate) => number
  getWeek: (date: MVDateAggregate) => number
  getYear: (date: MVDateAggregate) => number
  hoursToMilliseconds: (hours: number) => number
  hoursToMinutes: (hours: number) => number
  hoursToSeconds: (hours: number) => number
  isAfter: (date: MVDateAggregate, dateToCompare: Date, granularity?: Granularity) => boolean
  isAfterMilliseconds: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isAfterDay: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isAfterHour: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isAfterMinute: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isAfterMonth: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isAfterSecond: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isAfterWeek: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isAfterYear: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isBefore: (date: MVDateAggregate, dateToCompare: MVDateAggregate, granularity?: Granularity) => boolean
  isBeforeDay: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isBeforeHour: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isBeforeMinute: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isBeforeMonth: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isBeforeSecond: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isBeforeWeek: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isBeforeYear: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isBetween: (date: MVDateAggregate, startDateToCompare: MVDateAggregate, endDateToCompare: MVDateAggregate) => boolean
  isSame: (date: MVDateAggregate, dateToCompare: MVDateAggregate, granularity?: Granularity) => boolean
  isSameDay: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isSameHour: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isSameMinute: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isSameMonth: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isSameOrAfter: (date: MVDateAggregate, dateToCompare: MVDateAggregate, granularity?: Granularity) => boolean
  isSameOrAfterDay: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isSameOrAfterHour: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isSameOrAfterMinute: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isSameOrAfterMonth: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isSameOrAfterSecond: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isSameOrAfterWeek: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isSameOrAfterYear: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isSameOrBefore: (date: MVDateAggregate, dateToCompare: MVDateAggregate, granularity?: Granularity) => boolean
  isSameOrBeforeDay: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isSameOrBeforeHour: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isSameOrBeforeMinute: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isSameOrBeforeMonth: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isSameOrBeforeSecond: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isSameOrBeforeWeek: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isSameOrBeforeYear: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isSameSecond: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isSameWeek: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isSameYear: (date: MVDateAggregate, dateToCompare: MVDateAggregate) => boolean
  isValid: (date: MVDateAggregate) => boolean
  millisecondsToHours: (milliseconds: number) => number
  millisecondsToMinutes: (milliseconds: number) => number
  millisecondsToSeconds: (milliseconds: number) => number
  minutesToHours: (minutes: number) => number
  minutesToMilliseconds: (minutes: number) => number
  minutesToSeconds: (minutes: number) => number
  now: () => Date
  parseDateFromStringWithFormatting: (dateString: string, format: string) => Date
  secondsToHours: (seconds: number) => number
  secondsToMilliseconds: (seconds: number) => number
  secondsToMinutes: (seconds: number) => number
  setDay: (date: MVDateAggregate, day: number) => Date
  setHours: (date: MVDateAggregate, hours: number) => Date
  setMinutes: (date: MVDateAggregate, minutes: number) => Date
  setMonth: (date: MVDateAggregate, month: number) => Date
  setSeconds: (date: MVDateAggregate, seconds: number) => Date
  setYear: (date: MVDateAggregate, year: number) => Date
  startOfDay: (date: MVDateAggregate) => Date
  startOfHour: (date: MVDateAggregate) => Date
  startOfMinute: (date: MVDateAggregate) => Date
  startOfMonth: (date: MVDateAggregate) => Date
  startOfSecond: (date: MVDateAggregate) => Date
  startOfWeek: (date: MVDateAggregate) => Date
  startOfYear: (date: MVDateAggregate) => Date
  subtractDays: (date: MVDateAggregate, days: number) => Date
  subtractHours: (date: MVDateAggregate, hours: number) => Date
  subtractMilliseconds: (date: MVDateAggregate, milliseconds: number) => Date
  subtractMinutes: (date: MVDateAggregate, minutes: number) => Date
  subtractMonths: (date: MVDateAggregate, months: number) => Date
  subtractSeconds: (date: MVDateAggregate, seconds: number) => Date
  subtractWeeks: (date: MVDateAggregate, weeks: number) => Date
  subtractYears: (date: MVDateAggregate, years: number) => Date
  toDate: (date: MVDateAggregate) => Date
  setCurrentLanguage: (value: string) => void
}

let MVDateClass: MVDateType
MVDateClass = class {
  private static  aspNetRegex: RegExp = /^(-|\+)?(?:(\d*)[. ])?(\d+):(\d+)(?::(\d+)(\.\d*)?)?$/

   private static currentLanguage: string = ''

   public static setCurrentLanguage(value: string) {
     this.currentLanguage = value
   }

  private static getGranularityFunction(granularity: Granularity): (date: MVDateAggregate) => MVDateAggregate {
    switch (granularity) {
      case 'year':
        return date => this.startOfYear(date)
      case 'month':
        return date => this.startOfMonth(date)
      case 'day':
        return date => this.startOfDay(date)
      case 'hour':
        return date => this.startOfHour(date)
      case 'minute':
        return date => this.startOfMinute(date)
      case 'second':
        return date => this.startOfSecond(date)
      case 'milliseconds':
        return date => date
      default:
        return date => date
    }
  }

  public static differenceInMilliseconds = (startDate: MVDateAggregate, endDate: MVDateAggregate) => {
    return dateFns.differenceInMilliseconds(startDate, endDate)
  }
  public static differenceInSeconds = (startDate: MVDateAggregate, endDate: MVDateAggregate) => {
    return dateFns.differenceInSeconds(startDate, endDate)
  }
  public static differenceInMinutes = (startDate: MVDateAggregate, endDate: MVDateAggregate) => {
    return dateFns.differenceInMinutes(startDate, endDate)
  }
  public static differenceInHours = (startDate: MVDateAggregate, endDate: MVDateAggregate) => {
    return dateFns.differenceInHours(startDate, endDate)
  }
  public static differenceInDays = (startDate: MVDateAggregate, endDate: MVDateAggregate) => {
    return dateFns.differenceInDays(startDate, endDate)
  }
  public static differenceInWeeks = (startDate: MVDateAggregate, endDate: MVDateAggregate) => {
    return dateFns.differenceInWeeks(startDate, endDate)
  }
  public static differenceInMonths = (startDate: MVDateAggregate, endDate: MVDateAggregate) => {
    return dateFns.differenceInMonths(startDate, endDate)
  }
  public static differenceInYears = (startDate: MVDateAggregate, endDate: MVDateAggregate) => {
    return dateFns.differenceInYears(startDate, endDate)
  }
  public static endOfSecond = (date: MVDateAggregate) => {
    return dateFns.endOfSecond(date)
  }
  public static endOfMinute = (date: MVDateAggregate) => {
    return dateFns.endOfMinute(date)
  }
  public static endOfHour = (date: MVDateAggregate) => {
    return dateFns.endOfHour(date)
  }
  public static endOfDay = (date: MVDateAggregate) => {
    return dateFns.endOfDay(date)
  }
  public static endOfWeek = (date: MVDateAggregate) => {
    return dateFns.endOfWeek(date)
  }
  public static endOfMonth = (date: MVDateAggregate) => {
    return dateFns.endOfMonth(date)
  }
  public static endOfYear = (date: MVDateAggregate) => {
    return dateFns.endOfYear(date)
  }
  public static startOfSecond = (date: MVDateAggregate) => {
    return dateFns.startOfSecond(date)
  }
  public static startOfMinute = (date: MVDateAggregate) => {
    return dateFns.startOfMinute(date)
  }
  public static startOfHour = (date: MVDateAggregate) => {
    return dateFns.startOfHour(date)
  }
  public static startOfDay = (date: MVDateAggregate) => {
    return dateFns.startOfDay(date)
  }
  public static startOfWeek = (date: MVDateAggregate) => {
    return dateFns.startOfWeek(date)
  }
  public static startOfMonth = (date: MVDateAggregate) => {
    return dateFns.startOfMonth(date)
  }
  public static startOfYear = (date: MVDateAggregate) => {
    return dateFns.startOfYear(date)
  }
  public static getDurationFromString = (durationString: string) => {
    if (this.aspNetRegex.test(durationString)) {
      const regexResult = this.aspNetRegex.exec(durationString)
      if (regexResult) {
        return {
          days: parseInt(regexResult[2], 10),
          hours: parseInt(regexResult[3], 10),
          minutes: parseInt(regexResult[4], 10),
          seconds: parseInt(regexResult[5], 10),
        }
      }
      return {}
    }
    return {}
  }
  public static getDurationFromDates = (startDate: MVDateAggregate, endDate: MVDateAggregate): PartialMVDuration => {
    return dateFns.intervalToDuration({ start: startDate, end: endDate })
  }

  public static add = (date: MVDateAggregate, duration: PartialMVDuration) => {
    return dateFns.add(date, duration)
  }
  public static addDays = (date: MVDateAggregate, days: number) => {
    return dateFns.addDays(date, days)
  }
  public static addHours = (date: MVDateAggregate, hours: number) => {
    return dateFns.addHours(date, hours)
  }
  public static addMilliseconds = (date: MVDateAggregate, milliseconds: number) => {
    return dateFns.addMilliseconds(date, milliseconds)
  }
  public static addMinutes = (date: MVDateAggregate, minutes: number) => {
    return dateFns.addMinutes(date, minutes)
  }
  public static addMonths = (date: MVDateAggregate, months: number) => {
    return dateFns.addMonths(date, months)
  }
  public static addSeconds = (date: MVDateAggregate, seconds: number) => {
    return dateFns.addSeconds(date, seconds)
  }
  public static addWeeks = (date: MVDateAggregate, weeks: number) => {
    return dateFns.addWeeks(date, weeks)
  }
  public static addYears = (date: MVDateAggregate, years: number) => {
    return dateFns.addYears(date, years)
  }
  public static getTime = (date: MVDateAggregate) => {
    return dateFns.getTime(date)
  }
  public static getMilliseconds = (date: MVDateAggregate) => {
    return dateFns.getMilliseconds(date)
  }
  public static getSeconds = (date: MVDateAggregate) => {
    return dateFns.getSeconds(date)
  }
  public static getMinutes = (date: MVDateAggregate) => {
    return dateFns.getMinutes(date)
  }
  public static getHours = (date: MVDateAggregate) => {
    return dateFns.getHours(date)
  }
  public static getDate = (date: MVDateAggregate) => {
    return dateFns.getDate(date)
  }
  public static getDayOfYear = (date: MVDateAggregate) => {
    return dateFns.getDayOfYear(date)
  }
  public static getDuration = (duration: PartialMVDuration): MVDuration => {
    return mvDuration.getDuration(duration)
  }
  public static getWeek = (date: MVDateAggregate) => {
    return dateFns.getWeek(date)
  }
  public static getMonth = (date: MVDateAggregate) => {
    return dateFns.getMonth(date)
  }
  public static getYear = (date: MVDateAggregate) => {
    return dateFns.getYear(date)
  }
  public static isAfter = (date: MVDateAggregate, dateToCompare: MVDateAggregate, granularity: Granularity = 'day') => {
    const setGranularity = this.getGranularityFunction(granularity)
    return dateFns.isAfter(setGranularity(date), setGranularity(dateToCompare))
  }
  public static isAfterMilliseconds = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    const setGranularity = this.getGranularityFunction('milliseconds')
    return setGranularity(date) > setGranularity(dateToCompare)
  }
  public static isAfterSecond = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    const setGranularity = this.getGranularityFunction('second')
    return setGranularity(date) > setGranularity(dateToCompare)
  }
  public static isAfterMinute = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    const setGranularity = this.getGranularityFunction('minute')
    return setGranularity(date) > setGranularity(dateToCompare)
  }
  public static isAfterHour = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    const setGranularity = this.getGranularityFunction('hour')
    return setGranularity(date) > setGranularity(dateToCompare)
  }
  public static isAfterDay = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    const setGranularity = this.getGranularityFunction('day')
    return setGranularity(date) > setGranularity(dateToCompare)
  }
  public static isAfterWeek = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    const tempDate = this.getWeek(date)
    const tempDateToCompare = this.getWeek(dateToCompare)
    return tempDate > tempDateToCompare
  }
  public static isAfterMonth = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    const setGranularity = this.getGranularityFunction('month')
    return setGranularity(date) > setGranularity(dateToCompare)
  }
  public static isAfterYear = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    const setGranularity = this.getGranularityFunction('year')
    return setGranularity(date) > setGranularity(dateToCompare)
  }
  public static getDateFromStringWithFormatting = (date: string, format?: string) => {
    return this.format(this.getDateFromString(date), format)
  }
  public static getDateFromString = (date: string) => {
    if (this.isValid(dateFns.parseISO(date))) {
      return dateFns.parseISO(date)
    }
    return new Date(date)
  }
  public static isBefore = (
    date: MVDateAggregate,
    dateToCompare: MVDateAggregate,
    granularity: Granularity = 'day'
  ) => {
    const setGranularity = this.getGranularityFunction(granularity)
    return dateFns.isBefore(setGranularity(date), setGranularity(dateToCompare))
  }
  public static isBeforeSecond = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    const setGranularity = this.getGranularityFunction('second')
    return setGranularity(date) < setGranularity(dateToCompare)
  }
  public static isBeforeMinute = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    const setGranularity = this.getGranularityFunction('minute')
    return setGranularity(date) < setGranularity(dateToCompare)
  }
  public static isBeforeHour = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    const setGranularity = this.getGranularityFunction('hour')
    return setGranularity(date) < setGranularity(dateToCompare)
  }
  public static isBeforeDay = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    const setGranularity = this.getGranularityFunction('day')
    return setGranularity(date) < setGranularity(dateToCompare)
  }
  public static isBeforeWeek = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    const tempDate = this.getWeek(date)
    const tempDateToCompare = this.getWeek(dateToCompare)
    return tempDate < tempDateToCompare
  }
  public static isBeforeMonth = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    const setGranularity = this.getGranularityFunction('month')
    return setGranularity(date) < setGranularity(dateToCompare)
  }
  public static isBeforeYear = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    const setGranularity = this.getGranularityFunction('year')
    return setGranularity(date) < setGranularity(dateToCompare)
  }
  public static isBetween = (
    date: MVDateAggregate,
    startDateToCompare: MVDateAggregate,
    endDateToCompare: MVDateAggregate
  ) => {
    return !this.isBefore(date, startDateToCompare) && !this.isAfter(date, endDateToCompare)
  }
  public static isSame = (
    date: MVDateAggregate,
    dateToCompare: MVDateAggregate,
    granularity: Granularity = 'second'
  ) => {
    const setGranularity = this.getGranularityFunction(granularity)
    return dateFns.isEqual(setGranularity(date), setGranularity(dateToCompare))
  }
  public static isSameSecond = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    return dateFns.isSameSecond(date, dateToCompare)
  }
  public static isSameMinute = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    return dateFns.isSameMinute(date, dateToCompare)
  }
  public static isSameHour = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    return dateFns.isSameHour(date, dateToCompare)
  }
  public static isSameDay = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    return dateFns.isSameDay(date, dateToCompare)
  }
  public static isSameWeek = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    return dateFns.isSameWeek(date, dateToCompare)
  }
  public static isSameMonth = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    return dateFns.isSameMonth(date, dateToCompare)
  }
  public static isSameYear = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    return dateFns.isSameYear(date, dateToCompare)
  }
  public static isSameOrAfter = (
    date: MVDateAggregate,
    dateToCompare: MVDateAggregate,
    granularity: Granularity = 'second'
  ) => {
    return this.isSame(date, dateToCompare, granularity) || this.isAfter(date, dateToCompare, granularity)
  }
  public static isSameOrBefore = (
    date: MVDateAggregate,
    dateToCompare: MVDateAggregate,
    granularity: Granularity = 'second'
  ) => {
    return this.isSame(date, dateToCompare, granularity) || this.isBefore(date, dateToCompare, granularity)
  }
  public static isSameOrBeforeSecond = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    return this.isSameSecond(date, dateToCompare) || this.isBeforeSecond(date, dateToCompare)
  }
  public static isSameOrBeforeMinute = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    return this.isSameMinute(date, dateToCompare) || this.isBeforeMinute(date, dateToCompare)
  }
  public static isSameOrBeforeHour = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    return this.isSameHour(date, dateToCompare) || this.isBeforeHour(date, dateToCompare)
  }
  public static isSameOrBeforeDay = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    return this.isSameDay(date, dateToCompare) || this.isBeforeDay(date, dateToCompare)
  }
  public static isSameOrBeforeWeek = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    return this.isSameWeek(date, dateToCompare) || this.isBeforeWeek(date, dateToCompare)
  }
  public static isSameOrBeforeMonth = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    return this.isSameMonth(date, dateToCompare) || this.isBeforeMonth(date, dateToCompare)
  }
  public static isSameOrBeforeYear = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    return this.isSameYear(date, dateToCompare) || this.isBeforeYear(date, dateToCompare)
  }
  public static isSameOrAfterSecond = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    return this.isSameSecond(date, dateToCompare) || this.isAfterSecond(date, dateToCompare)
  }
  public static isSameOrAfterMinute = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    return this.isSameMinute(date, dateToCompare) || this.isAfterMinute(date, dateToCompare)
  }
  public static isSameOrAfterHour = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    return this.isSameHour(date, dateToCompare) || this.isAfterHour(date, dateToCompare)
  }
  public static isSameOrAfterDay = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    return this.isSameDay(date, dateToCompare) || this.isAfterDay(date, dateToCompare)
  }
  public static isSameOrAfterWeek = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    return this.isSameWeek(date, dateToCompare) || this.isAfterWeek(date, dateToCompare)
  }
  public static isSameOrAfterMonth = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    return this.isSameMonth(date, dateToCompare) || this.isAfterMonth(date, dateToCompare)
  }
  public static isSameOrAfterYear = (date: MVDateAggregate, dateToCompare: MVDateAggregate) => {
    return this.isSameYear(date, dateToCompare) || this.isAfterYear(date, dateToCompare)
  }
  public static isValid = (date: MVDateAggregate) => {
    return dateFns.isValid(date)
  }
  public static now = () => {
    return this.toDate(Date.now())
  }
  public static toDate = (date: MVDateAggregate) => {
    return dateFns.toDate(date)
  }
  public static subtractMilliseconds = (date: MVDateAggregate, milliseconds: number) => {
    return dateFns.subMilliseconds(date, milliseconds)
  }
  public static subtractSeconds = (date: MVDateAggregate, seconds: number) => {
    return dateFns.subSeconds(date, seconds)
  }
  public static subtractMinutes = (date: MVDateAggregate, minutes: number) => {
    return dateFns.subMinutes(date, minutes)
  }
  public static subtractHours = (date: MVDateAggregate, hours: number) => {
    return dateFns.subHours(date, hours)
  }
  public static subtractDays = (date: MVDateAggregate, days: number) => {
    return dateFns.subDays(date, days)
  }
  public static subtractWeeks = (date: MVDateAggregate, weeks: number) => {
    return dateFns.subWeeks(date, weeks)
  }
  public static subtractMonths = (date: MVDateAggregate, months: number) => {
    return dateFns.subMonths(date, months)
  }
  public static subtractYears = (date: MVDateAggregate, years: number) => {
    return dateFns.subYears(date, years)
  }
  public static format(date: MVDateAggregate, format?: string) {
    if (format) {
      return dateFns.format(date, format, {
        locale: localeSupported[this.currentLanguage] ?? localeSupported[navigator.language.substring(0, 2)] ?? enUS,
      })
    }
    return dateFns.format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
  }
  public static setSeconds = (date: MVDateAggregate, seconds: number) => {
    return dateFns.setSeconds(date, seconds)
  }
  public static setMinutes = (date: MVDateAggregate, minutes: number) => {
    return dateFns.setMinutes(date, minutes)
  }
  public static setHours = (date: MVDateAggregate, hours: number) => {
    return dateFns.setHours(date, hours)
  }
  public static setDay = (date: MVDateAggregate, day: number) => {
    return dateFns.setDate(date, day)
  }
  public static setMonth = (date: MVDateAggregate, month: number) => {
    return dateFns.setMonth(date, month)
  }
  public static setYear = (date: MVDateAggregate, year: number) => {
    return dateFns.setYear(date, year)
  }
  public static parseDateFromStringWithFormatting = (dateString: string, format: string) => {
    return dateFns.parse(dateString, format, new Date())
  }
  public static secondsToMilliseconds = (seconds: number) => {
    return dateFns.secondsToMilliseconds(seconds)
  }
  public static secondsToMinutes = (seconds: number) => {
    return dateFns.secondsToMinutes(seconds)
  }
  public static secondsToHours = (seconds: number) => {
    return dateFns.secondsToHours(seconds)
  }
  public static hoursToMilliseconds = (hours: number) => {
    return dateFns.hoursToMilliseconds(hours)
  }
  public static hoursToMinutes = (hours: number) => {
    return dateFns.hoursToMinutes(hours)
  }
  public static hoursToSeconds = (hours: number) => {
    return dateFns.hoursToSeconds(hours)
  }
  public static minutesToMilliseconds = (minutes: number) => {
    return dateFns.minutesToMilliseconds(minutes)
  }
  public static minutesToSeconds = (minutes: number) => {
    return dateFns.minutesToSeconds(minutes)
  }
  public static minutesToHours = (minutes: number) => {
    return dateFns.minutesToHours(minutes)
  }
  public static millisecondsToHours = (milliseconds: number) => {
    return dateFns.millisecondsToHours(milliseconds)
  }
  public static millisecondsToMinutes = (milliseconds: number) => {
    return dateFns.millisecondsToMinutes(milliseconds)
  }
  public static millisecondsToSeconds = (milliseconds: number) => {
    return dateFns.millisecondsToSeconds(milliseconds)
  }
}
export const mvDate = MVDateClass
export type MVDateAggregate = Date | number
