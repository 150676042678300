import { AnyAction } from 'redux'
// import { ChecklistModel, ChecklistInstance } from '../../types'
import { StateProps as ArchiveStateProps } from '../../ui/components/views/ChecklistArchive/ChecklistArchivePageView'

const indexInitialState = {
  checklists: [],
  error: null,
  fetching: false,
  page: 0,
  pages: -1,
  pageSize: 50,
  sorted: [],
  filtered: [],
  createdDate: undefined,
  concludedDate: undefined,
  assets: [],
  models: [],
}

export const checklistArchive = (state: ArchiveStateProps = indexInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      try {
        return action.payload.checklist.archive
      } catch {
        return { ...state }
      }
    case 'CHECKLIST_ARCHIVE_IS_FETCHING':
      return Object.assign(
        {},
        {
          ...state,
          pageSize: action.payload.pageSize,
          page: action.payload.page - 1,
          sorted: action.payload.sorted,
          filtered: action.payload.filtered,
          error: null,
          fetching: true,
        }
      )
    case 'CHECKLIST_ARCHIVE_FETCH_SUCCESS':
      return Object.assign(
        {},
        {
          ...state,
          checklists: action.payload.data,
          pages: action.payload.pages,
          error: null,
          fetching: false,
        }
      )
    case 'CHECKLIST_ARCHIVE_FETCH_ASSETS_SUCCESS':
      return Object.assign(
        {},
        {
          ...state,
          assets: action.payload,
        }
      )
    case 'CHECKLIST_ARCHIVE_FETCH_MODELS_SUCCESS':
      return Object.assign(
        {},
        {
          ...state,
          models: action.payload,
        }
      )
    case 'CHECKLIST_ARCHIVE_FETCH_ERROR':
      return Object.assign(
        {},
        {
          ...state,
          checklists: [],
          pages: -1,
          page: 1,
          sorted: [],
          filtered: [],
          createdDate: undefined,
          concludedDate: undefined,
          error: action.payload,
          fetching: false,
        }
      )
    case 'CHECKLIST_ARCHIVE_FETCH_ASSETS_ERROR':
      return Object.assign(
        {},
        {
          ...state,
          assets: [],
        }
      )
    case 'CHECKLIST_ARCHIVE_FETCH_MODELS_ERROR':
      return Object.assign(
        {},
        {
          ...state,
          models: [],
        }
      )
    case 'CHECKLIST_ARCHIVE_START_DATE_CHANGED':
      return Object.assign(
        {},
        {
          ...state,
          createdDate: action.payload,
        }
      )
    case 'CHECKLIST_ARCHIVE_CONCLUDED_DATE_CHANGED':
      return Object.assign(
        {},
        {
          ...state,
          concludedDate: action.payload,
        }
      )
    default:
      return state
  }
}
