import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { checklistGetFile } from '../../../../redux/actions'
import { WithTranslation, withTranslation } from 'react-i18next'
import * as EXIF from 'exif-js'
import * as moment from 'moment'
import {Modal} from "@mv-submodules/inplant-components-fe";
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

export interface DispatchProps {
  getFile: (filePath: string) => Promise<Blob>
}
export interface OwnProps {
  show: boolean
  onClose: () => void
  filePath: string | undefined
}

export interface OwnState {
  loading: boolean
  blob: Blob | undefined
  dateTimeOriginal: moment.Moment | null
}

export type Props = DispatchProps & OwnProps & WithTranslation

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    getFile: filePath => dispatch(checklistGetFile(filePath)),
  }
}

export class MultiFileModal extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: true,
      blob: undefined,
      dateTimeOriginal: null,
    }
    this.getFile = this.getFile.bind(this)
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.filePath && (!prevProps.filePath || prevProps.filePath !== this.props.filePath)) {
      this.getFile()
    }
  }

  private async getFile() {
    this.setState(prevState => ({ ...prevState, loading: true }))
    const blob = await this.props.getFile(this.props.filePath!)
    const component = this // tslint:disable-line:no-this-assignment
    EXIF.getData(blob as any, function(this: any) {
      const exifDateTimeOriginal = EXIF.getTag(this, 'DateTimeOriginal') // 2014:08:14 15:24:36
      const dateTimeOriginal = moment(exifDateTimeOriginal, 'YYYY:MM:DD HH:mm:ss')
      component.setState({ blob, dateTimeOriginal: exifDateTimeOriginal ? dateTimeOriginal : null, loading: false })
    })
  }

  public render() {
   // const { dateTimeOriginal } = this.state
    return this.props.show
      ? ReactDOM.createPortal(
        <Modal
            visible={this.props.show}
            title={this.props.t('checklist.components.multiFile.viewer')}
            onClose={() => this.props.onClose()}
            hideFooterCloseButton={true}
            width={50}

            >
          {this.state.loading ? (
            <div className="d-flex justify-content-center">
              <IconComponent icon={'circle-notch'} className="spinner" spin={true} size="2x" />
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <img className="img-fluid" src={URL.createObjectURL(this.state.blob)} />
            </div>
          )}
        </Modal>,
          document.getElementById('modal-container') as Element
        )
      : null
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(MultiFileModal))
