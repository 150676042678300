import { TYPES, useInjection, useUpdateContainerInstance } from './binding'
import { TranslationServiceError } from './error'

/**
 * @throws {TranslationServiceError}
 */
export function useComponentsTranslation() {

  try {
    const service = useInjection<TranslationService>(TYPES.TranslationService)
    const updater = useUpdateContainerInstance()


    return {
      t: service.t,
      changeLanguage: async (newLanguage: string) => {
        await service.changeLanguage(newLanguage, (instance) => {
          updater(TYPES.TranslationService, instance)
        })
      },
      language: service.language,
      defaultLanguage: service.defaultLanguage,
      availableLanguages: service.availableLanguages,
    }
  } catch (error) {
    throw new TranslationServiceError(
      'useTranslation() required a translation service, init it in the application root tree using ContainerProvider',
    )
  }
}

export interface ITranslationService {
  t: LanguageResolverType
}

export type LanguageResolverType = (s?: string, options?: Object) => string


export interface Translator {
  t: LanguageResolverType

  changeLanguage(
    newLanguage: string,
    callback: (translator: Translator) => void,
  ): Promise<any>

  language: string
  defaultLanguage: string
  availableLanguages: string[]
}


export class TranslationService {
  private _translator: Translator

  constructor(translator: Translator) {
    this._translator = translator
  }

  get language(): string {
    return this._translator.language
  }

  get defaultLanguage(): string {
    return this._translator.defaultLanguage
  }

  get availableLanguages(): string[] {
    return this._translator.availableLanguages
  }

  public changeLanguage = (lng: string, callback?: ((instance: TranslationService) => void)): Promise<any> => {
    return this._translator.changeLanguage(lng, translator => {
      this._translator = { ...this._translator, ...translator }
      callback?.(this)
    })
  }

  public t = (s?: string, options?: Object): string => {
    return this._translator.t(s, options)
  }
}
