import { ConfigurationStore } from '@mv-submodules/inplant-checklist-fe-iblu/types/configuration'
import { Reducer } from 'redux'
import {
  ConfigurationTypes,
  CONFIGURATION_ERROR,
  CONFIGURATION_IS_FETCHING,
  CONFIGURATION_SUCCESS,
} from '../actions/configuration'

export const configManagerInitialState: ConfigurationStore = {
  fetching: false,
  error: undefined,
  data: {
    units: [],
  },
}

export const configuration: Reducer<ConfigurationStore, ConfigurationTypes> = (
  state = configManagerInitialState,
  action: ConfigurationTypes
) => {
  switch (action.type) {
    /* case 'persist/REHYDRATE':
      if (action.payload && action.payload.mcs && action.payload.mcs.configManager) {
        
        
      } else {
        return state
      } */
    case CONFIGURATION_IS_FETCHING:
      return {
        ...state,
        fetching: true,
        error: undefined,
      }
    case CONFIGURATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetching: false,
        error: undefined,
      }
    case CONFIGURATION_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default configuration
